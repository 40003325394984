import { Component } from '@angular/core';
import { ApiClientAdminClient } from '../../../api/endpoints/ApiClientAdminClient';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ClientAddUsersComponent } from './client-add-users.component';

@Component({
  selector: 'mf-client-admin',
  templateUrl: './client-admin.component.html',
  styleUrls: ['./client-admin.component.scss'],
})
export class ClientAdminComponent {
  constructor(
    private _apiClientAdminClient: ApiClientAdminClient,
    private _notificationService: NotificationService,
    private _modalService: ModalService
  ) {
    this.load();
  }

  clientDto: ClientDto;

  async load() {
    this.clientDto = await this._apiClientAdminClient.getCurrentClient();
  }

  async save(client: ClientDto) {
    await this._apiClientAdminClient.editCurrent(client);
    this._notificationService.showSuccess('Cliente editado con éxito');
  }

  addAdministrator() {
    this._modalService.openRightModal(ClientAddUsersComponent, {
      openData: this.clientDto.id,
    });
  }
}
