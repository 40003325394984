import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayDto } from '../../../api/dtos/GatewayDto';
import { ApiAdminGateway } from '../../../api/endpoints/ApiAdminGateway';

@Component({
  selector: 'mf-gateway-edit',
  templateUrl: './gateway-edit.component.html',
  styleUrls: ['./gateway-edit.component.scss'],
})
export class GatewayEditComponent {
  constructor(private _apiAdminGateway: ApiAdminGateway, private _modalService: ModalService) {
    this.load();
  }

  gatewayDto: GatewayDto;

  async load() {
    this.gatewayDto = await this._apiAdminGateway.getById(this._modalService.openData);
  }

  async save(gatewayDto: GatewayDto) {
    await this._apiAdminGateway.edit(gatewayDto);
    this._modalService.closeAsSuccess('Pasarela editada correctamente');
  }
}
