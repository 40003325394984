import { Component, forwardRef, Inject, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IGridFilter } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';

export interface IGridFilterEntityComponentResolver {
  getComponent(): any;
}

@Component({
  standalone: false,
  selector: 'mf-grid-filter-entity',
  templateUrl: './grid-filter-entity.component.html',
  styleUrls: ['./grid-filter-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterEntityComponent),
      multi: true,
    },
  ],
})
export class GridFilterEntityComponent extends GridFilterBase<IGridFilter, string> {
  constructor(
    @Inject('IGridFilterEntityComponentResolver') private _gridFilterEntityComponentResolver: IGridFilterEntityComponentResolver
  ) {
    super();
  }

  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  private loadComponent() {
    this.container.clear();
    const component = this.container.createComponent(this._gridFilterEntityComponentResolver.getComponent()) as any;
    component.instance.gridFilter = this.gridFilter;
    component.instance.value = this.value;
    component.instance.onChange.subscribe((value: any) => {
      this.value = value;
    });
  }

  protected override onWriteValue(_: string): void {
    this.loadComponent();
  }
}
