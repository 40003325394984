import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminNaturalPerson } from '../../../../api/endpoints/ApiClientAdminNaturalPerson';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { NaturalPersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';
import { IdDocumentDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/IdDocumentDto';
import { AddressDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/AddressDto';

@Component({
  selector: 'mf-natural-person-create',
  templateUrl: './natural-person-create.component.html',
  styleUrls: ['./natural-person-create.component.scss'],
})
export class NaturalPersonCreateComponent {
  constructor(private _apiClientAdminNaturalPerson: ApiClientAdminNaturalPerson, private _modalService: ModalService) {
    this.naturalPersonDto = new NaturalPersonDto();
    this.naturalPersonDto.id = GUID_EMPTY;
    this.naturalPersonDto.address = new AddressDto();
    this.naturalPersonDto.addressId = GUID_EMPTY;
    this.naturalPersonDto.address.id = GUID_EMPTY;
    this.naturalPersonDto.idDocument = new IdDocumentDto();
    this.naturalPersonDto.idDocumentId = GUID_EMPTY;
    this.naturalPersonDto.idDocument.id = GUID_EMPTY;
  }

  naturalPersonDto: NaturalPersonDto;

  async save(naturalPersonDto: NaturalPersonDto) {
    await this._apiClientAdminNaturalPerson.create(naturalPersonDto);
    this._modalService.closeAsSuccess('La persona se creó con éxito');
  }
}
