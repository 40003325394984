import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { BankDto } from '../../../api/dtos/BankDto';
import { BankFiltersDto } from '../../../api/dtos/BankFiltersDto';
import { BankSimpleDto } from '../../../api/dtos/BankSimpleDto';
import { ApiClientAdminBank } from '../../../api/endpoints/ApiClientAdminBank';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankCreateComponent } from '../../pages/banks/bank/bank-create.component';

@Component({
  selector: 'mf-autocomplete-bank',
  templateUrl: './autocomplete-bank.component.html',
  styleUrls: ['./autocomplete-bank.component.scss'],
})
export class AutocompleteBankComponent extends AutoCompleteBaseEntityComponent<BankSimpleDto | null, BankFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiClientAdminBank: ApiClientAdminBank,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
    this.createNewComponent = BankCreateComponent;
  }

  showAddButton = true;

  override defaultOrderBy = nameof<BankDto>((p) => p.name);

  override endpointSuggest = (search: SuggestSearchDto<BankFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminBank.suggest(search, httpApiRequestOptions);
}
