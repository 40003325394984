import { IGridFilter } from './grid-filter';

export interface IGridColumn<T> {
  align?: 'left' | 'right' | 'center';
  cellOptions?: CellOptions;
  field: string;
  filter?: IGridFilter;
  header: string;
  orderBy?: string;
  orderByPrevent?: boolean;
  width?: number;
  getStyles?: (element: T) => string;
  render?: (item: T) => string;
}

export class CellOptions {
  type: CellValueType;
  dateIncludeTime?: boolean;
  dateIncludeMilliseconds?: boolean;
  enumName?: string;
  enumNameModule?: string;
}

export enum CellValueType {
  Date,
  Numeric,
  Enum,
  Boolean,
}
