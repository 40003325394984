// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { ConfigurationDto } from "../dtos/ConfigurationDto";

@Injectable({providedIn: 'root'})
export class ApiAdminConfiguration {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ConfigurationDto> {
    return this._httpApiClient.get("/admin/configuration/" + id, httpApiRequestOptions);
  }

  edit(
    configurationDto: ConfigurationDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ConfigurationDto> {
    return this._httpApiClient.put("/admin/configuration", configurationDto, httpApiRequestOptions);
  }

}
