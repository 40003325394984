import { Component, Inject } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { IApiTemplatePdf } from '../../api/IApiTemplatePdf';
import { TemplatePdfDto } from '../../dtos/TemplatePdfDto';
import { ModalService } from '../../../services/modal.service';
import { TemplateDto } from '../../dtos/TemplateDto';

@Component({
  standalone: false,
  selector: 'mf-template-pdf-edit',
  templateUrl: './template-pdf-edit.component.html',
  styleUrls: ['./template-pdf-edit.component.scss'],
})
export class TemplatePdfEditComponent {
  constructor(@Inject('IApiTemplatePdf') private _apiPdfTemplate: IApiTemplatePdf, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  templatePdfDto: TemplatePdfDto;

  async load(id: string) {
    this.templatePdfDto = await this._apiPdfTemplate.getById(id);
  }

  async save(templateDto: TemplateDto) {
    await this._apiPdfTemplate.edit(templateDto as TemplatePdfDto);
    this._modalService.closeAsSuccess('La plantilla de PDF se editó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
