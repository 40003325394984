<mf-page title="Cliente">
  <mf-row>
    @if (clientDto) {
    <mf-row-buttons
      ><mf-button
        text="Administradores"
        (click)="addAdministrator()" />
    </mf-row-buttons>
    <mf-client
      [clientDto]="clientDto"
      (save)="save($event)" />
    }
  </mf-row>
</mf-page>
