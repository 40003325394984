import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent, IGridActionButton } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AccreditationDto } from '../../../api/dtos/AccreditationDto';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { RunJobParameter } from '../../../api/dtos/RunJobParameter';
import { AccreditationFiltersDto } from '../../../api/dtos/AccreditationFiltersDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { ApiAdminAccreditation } from '../../../api/endpoints/ApiAdminAccreditation';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { AccreditationDetailsComponent } from './accreditation-details.component';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { NavigationService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { AppRoute } from '../../app.routes.path';
import { AccreditationStatus } from '../../../api/enums/AccreditationStatus';
import { UndoAccreditationComponent } from './undo-accreditation.component';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  selector: 'mf-accreditation-grid',
  templateUrl: './accreditation-grid.component.html',
  styleUrls: ['./accreditation-grid.component.scss'],
})
export class AccreditationGridComponent {
  constructor(
    private _apiAdminAccreditation: ApiAdminAccreditation,
    private _modalService: ModalService,
    private _navigationService: NavigationService,
    private _notificationService: NotificationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() showCreateButton = false;
  @Input() disableCreateButton = true;

  filters = new AccreditationFiltersDto();
  jobBody: RunJobParameter;

  //mustIncludeFields = [nameof<AccreditationDto>((a) => a.accreditationsByAccounts)];

  columns: IGridColumn<AccreditationDto>[] = [
    {
      field: nameof<AccreditationDto>((e) => e.accreditationNumber),
      header: 'Número',
      width: 20,
    },
    {
      field: nameof<AccreditationDto>((e) => e.date),
      header: 'Fecha',
      width: 20,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<AccreditationDto>((e) => e.type),
      header: 'Tipo',
      filter: {
        propertyName: nameof<AccreditationFiltersDto>((e) => e.type),
        type: FilterType.Enum,
        enumName: enumName_mi_pspc.AccreditationType,
      },
      cellOptions: { enumName: enumName_mi_pspc.AccreditationType, type: CellValueType.Enum },
      width: 40,
    },
    {
      field: nameof<AccreditationDto>((e) => e.status),
      header: 'Estado',
      width: 20,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.AccreditationStatus,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.details,
      onClick: (item: AccreditationDto) => {
        this._modalService.openRightModal(AccreditationDetailsComponent, { openData: item.id });
      },
    },
    {
      label: 'Ver por cuenta',
      icon: Icons.accountBalance,
      onClick: (item: AccreditationDto) => {
        this._navigationService.navigate(AppRoute.accreditationsByAccount, undefined, { accreditationId: item.id });
      },
    },
    {
      label: 'Deshacer',
      icon: Icons.undo,
      onClick: (item: AccreditationDto) => {
        this._modalService.openNormalModal(UndoAccreditationComponent, {
          openData: item.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
      hide: (item: AccreditationDto) => {
        return item.status >= AccreditationStatus.CompletedOk;
      },
    },
  ];

  gridActionButtons: IGridActionButton[] = [
    {
      text: 'Nueva acreditación de pago',
      onClick: () => {
        this.createAccreditationForPayment();
      },
    },
    {
      text: 'Nueva acreditación de compensación',
      onClick: () => {
        this.createAccreditationForCompensation();
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<AccreditationFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminAccreditation.getPaged(search, httpApiRequestOptions);

  createAccreditationForPayment() {
    this._modalService.openYesOrNoModal('¿Está seguro que desea iniciar un nuevo proceso de acreditaciones de pagos?', {
      onCloseSuccess: async () => {
        await this._apiAdminAccreditation.generateForPayment();
        this._notificationService.showSuccess('El proceso de acreditación se inició con éxito');
        this.grid.reloadPage();
      },
    });
  }

  createAccreditationForCompensation() {
    this._modalService.openYesOrNoModal('¿Está seguro que desea iniciar un nuevo proceso de acreditaciones de compensaciones?', {
      onCloseSuccess: async () => {
        await this._apiAdminAccreditation.generateForCompensation();
        this._notificationService.showSuccess('El proceso de acreditación de compensación se inició con éxito');
        this.grid.reloadPage();
      },
    });
  }
}
