// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { FileUploadResponseDto } from "../../../../../libs/utils/ng-shared-components/src/lib/fields/field-file-upload/dtos/FileUploadResponseDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminUploadFile {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  uploadTest(fileList: FileList, httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<FileUploadResponseDto> {
    return this._httpApiClient.uploadFile('/admin/upload-file/test', fileList, httpApiRequestOptions);
  }

  uploadTestPdf(fileList: FileList, httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<FileUploadResponseDto> {
    return this._httpApiClient.uploadFile('/admin/upload-file/only-pdf', fileList, httpApiRequestOptions);
  }

  uploadFile(fileList: FileList, httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<FileUploadResponseDto> {
    return this._httpApiClient.uploadFile('/admin/upload-file/upload-file', fileList, httpApiRequestOptions);
  }

}
