import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { IssueDto } from '../../../api/dtos/IssueDto';
import { ApiAdminIssue } from '../../../api/endpoints/ApiAdminIssue';

@Component({
  standalone: false,
  selector: 'mf-issue-create',
  templateUrl: './issue-create.component.html',
  styleUrls: ['./issue-create.component.scss'],
})
export class IssueCreateComponent {
  constructor(private _apiAdminIssue: ApiAdminIssue, private _modalService: ModalService) {}

  async save(issue: IssueDto) {
    await this._apiAdminIssue.create(issue);
    this._modalService.closeAsSuccess('El incidente se creo con éxito');
  }

  close() {
    this._modalService.closeAsSuccess('');
  }
}
