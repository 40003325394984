import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icons } from '../../icon/icons';
import { PagedResultDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/PagedResultDto';
import { PagedSearchDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { FiltersDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/FiltersDto';

@Component({
  standalone: false,
  selector: 'mf-grid-paginator',
  templateUrl: './grid-paginator.component.html',
  styleUrls: ['./grid-paginator.component.scss'],
})
export class GridPaginatorComponent {
  @Input()
  page: PagedResultDto<any>;
  @Input()
  searchDto: PagedSearchDto<FiltersDto>;
  @Output()
  pageChangeEvent = new EventEmitter<any>();
  icons = Icons;

  pageChange() {
    this.pageChangeEvent.emit(this.searchDto);
  }

  goToPage(page: number) {
    this.searchDto.page = page;
    this.pageChangeEvent.emit(this.searchDto);
  }

  pageSizeOptions: number[] = [10, 25, 50, 100];

  get itemRangeText(): string {
    const pageSize = this.searchDto.pageSize ?? 0;
    const start = pageSize * (this.page.currentPageIndex - 1) + 1;
    const end = pageSize * (this.page.currentPageIndex - 1) + this.page.pageItemList.length;
    return `${start} - ${end}`;
  }
}
