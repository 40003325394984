// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { TransferVoucherForCollectOrderItemDto } from './TransferVoucherForCollectOrderItemDto';
import { Currency } from '../enums/Currency';
import { GatewayBankAccountDto } from './GatewayBankAccountDto';
import { BankAccountDto } from './BankAccountDto';

export class TransferVoucherForCollectOrderDto extends BaseEntityDto {
  amount: number = 0;
  clientId: string = '';
  createsNewOrderForBalanceAmount: boolean = false;
  externalIdsForNewOrdersRequiredByClient: boolean = false;
  collectOrders: TransferVoucherForCollectOrderItemDto[] = [];
  currency: Currency;
  externalId: string = '';
  destinationBankAccountId: string = '';
  destinationBankAccount: GatewayBankAccountDto;
  reference: string = '';
  sourceBankAccountId: string = '';
  sourceBankAccount: BankAccountDto;
  uploadDate: Date = new Date();
  voucherUrl: string = '';
}

export interface TransferVoucherForCollectOrderDtoFormGroup extends BaseEntityDtoFormGroup {
  amount?: any;
  clientId?: any;
  createsNewOrderForBalanceAmount?: any;
  externalIdsForNewOrdersRequiredByClient?: any;
  collectOrders?: any;
  currency?: any;
  externalId?: any;
  destinationBankAccountId?: any;
  destinationBankAccount?: any;
  reference?: any;
  sourceBankAccountId?: any;
  sourceBankAccount?: any;
  uploadDate?: any;
  voucherUrl?: any;
}
