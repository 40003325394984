import { Component, ElementRef, Input, OnChanges, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { BankAccountFiltersDto } from '../../../api/dtos/BankAccountFiltersDto';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { Currency } from '../../../api/enums/Currency';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayBankAccountDto } from '../../../api/dtos/GatewayBankAccountDto';
import { ApiAdminGatewayBankAccount } from '../../../api/endpoints/ApiAdminGatewayBankAccount';
import { GatewayBankAccountFiltersDto } from '../../../api/dtos/GatewayBankAccountFiltersDto';
import { ApiClientAdminGatewayBankAccount } from '../../../api/endpoints/ApiClientAdminGatewayBankAccount';

@Component({
  standalone: false,
  selector: 'mf-autocomplete-gateway-bank-account',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteGatewayBankAccountComponent
  extends AutoCompleteBaseEntityComponent<GatewayBankAccountDto | null, BankAccountFiltersDto>
  implements OnChanges
{
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiClientAdminGatewayBankAccount: ApiClientAdminGatewayBankAccount,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
  }

  override defaultOrderBy = nameof<GatewayBankAccountDto>((p) => p.accountNumber);

  @Input()
  currency?: Currency;

  override endpointSuggest = (search: SuggestSearchDto<GatewayBankAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminGatewayBankAccount.suggest(search, httpApiRequestOptions);

  override setDefaultFilters() {
    if (this.currency) {
      this.filters.currencyList = [];
      this.filters.currencyList.push(this.currency);
    }
  }

  ngOnChanges() {
    this.setDefaultFilters();

    if (this.value && this.value.currency && !this.filters.currencyList.includes(this.value.currency)) {
      this.selectedItemChange(null);
    }
  }
}
