import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import { CollectOrderFiltersDto } from '../../../../api/dtos/CollectOrderFiltersDto';
import { OrderDto } from '../../../../api/dtos/OrderDto';
import { TransferVoucherForCollectOrderItemDto } from '../../../../api/dtos/TransferVoucherForCollectOrderItemDto';
import { ApiClientAdminCollectOrder } from '../../../../api/endpoints/ApiClientAdminCollectOrder';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { Currency } from '../../../../api/enums/Currency';
import { OrderStatus } from '../../../../api/enums/OrderStatus';
import { FilterEntityType } from '../../../view-models/FilterEntityType';
import { CollectOrderVoucherEditComponent } from './collect-order-voucher-edit.component';

@Component({
  standalone: false,
  selector: 'mf-collect-order-voucher-grid',
  templateUrl: './collect-order-voucher-grid.component.html',
  styleUrls: ['./collect-order-voucher-grid.component.scss'],
})
export class CollectOrderVoucherGridComponent implements OnInit, OnChanges {
  constructor(private _apiClientAdminCollectOrder: ApiClientAdminCollectOrder, private _modalService: ModalService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.filters && changes['transferVoucherForCollectOrderItemDto'] == null) {
      this.filters.personId = this.personId;
      this.filters.currency = this.currency;
      this.filters.clientId = this.clientId;
      this.grid.refresh();
    }
  }

  @ViewChild('grid')
  grid: GridComponent;

  @Input()
  disableCreateButton = false;
  @Input()
  hideFilters = false;
  @Input()
  showCreateButton = false;
  @Input()
  personId: string | null;
  @Input()
  currency: Currency;
  @Input()
  clientId: string | null;
  @Input()
  transferVoucherForCollectOrderItemDto: TransferVoucherForCollectOrderItemDto[];
  @Output()
  selectedItems = new EventEmitter<any>();

  filters: CollectOrderFiltersDto;

  ngOnInit(): void {
    this.filters = new CollectOrderFiltersDto();
    this.filters.personId = this.personId;
    this.filters.currency = this.currency;
    this.filters.clientId = this.clientId;
    this.filters.statusList.push(OrderStatus.Pending);
    this.hideFilters = true;
  }

  columns: IGridColumn<CollectOrderDto>[] = [
    {
      field: nameof<CollectOrderDto>((o) => o.operation.operationNumber),
      header: 'Operación',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.operationNumber),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.orderNumber),
      header: 'Número',
      width: 5,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.orderNumber),
        type: FilterType.Number,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.person.fullName),
      header: 'Persona',
      width: 20,
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.person),
        type: FilterType.Entity,
        entityType: FilterEntityType.Person,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.status),
      header: 'Estado',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderStatus,
      },
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.OrderStatus,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.operation.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<CollectOrderFiltersDto>((o) => o.currency),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<CollectOrderDto>((o) => o.amount),
      header: 'Monto',
      width: 10,
      align: 'right',
      cellOptions: {
        type: CellValueType.Numeric,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar monto asignado',
      icon: Icons.edit,
      disabled: (collectOrderDto: CollectOrderDto) =>
        !this.transferVoucherForCollectOrderItemDto.find((order) => order.collectOrderId === collectOrderDto.id),
      onClick: (collectOrderDto: CollectOrderDto) => {
        this._modalService.openLargeModal(CollectOrderVoucherEditComponent, {
          openData: {
            collectOrder: collectOrderDto,
          },
          onCloseSuccess: (collectOrder: CollectOrderDto) => {
            this.transferVoucherForCollectOrderItemDto.find((order) => order.collectOrderId === collectOrder.id)!.amount =
              collectOrder.amount;
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<CollectOrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminCollectOrder.getPaged(search, httpApiRequestOptions);

  selectItems(event: any) {
    this.selectedItems.emit(event);
  }
}
