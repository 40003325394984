// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { CompanyDto } from '../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';

export class ClientDto extends BaseEntityDto {
  apiKey: string = '';
  companyId: string | null = null;
  company: CompanyDto;
  endpointUrl: string = '';
  name: string = '';
  notificationEmail: string = '';
}

export interface ClientDtoFormGroup extends BaseEntityDtoFormGroup {
  apiKey?: any;
  companyId?: any;
  company?: any;
  endpointUrl?: any;
  name?: any;
  notificationEmail?: any;
}
