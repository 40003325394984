import { EventEmitter, Injectable, Output } from '@angular/core';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { ApiClientAdminOperation } from '../../../api/endpoints/ApiClientAdminOperation';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { OperationRequestResultDto } from '../../../api/dtos/OperationRequestResultDto';

@Injectable()
export class OperationService {
  constructor(private apiClientAdminOperation: ApiClientAdminOperation, private _notificationService: NotificationService) {}

  @Output()
  updatedOperationDto = new EventEmitter<OperationDto>();

  operationDto: OperationDto;

  public async edit(operationDto: OperationDto) {
    this.operationDto = await this.apiClientAdminOperation.edit(operationDto);
    this.updatedOperationDto.emit(this.operationDto);
    this._notificationService.showSuccess('Operación editada con éxito');
  }

  public async createDraft(operationDto: OperationDto) {
    operationDto.id = GUID_EMPTY;
    this.operationDto = await this.apiClientAdminOperation.createDraft(operationDto);
    this.updatedOperationDto.emit(this.operationDto);
    this._notificationService.showSuccess('Se ha creado el borrador de la operación');
  }

  public async createDraftFromFile(importFile: string): Promise<OperationRequestResultDto> {
    return await this.apiClientAdminOperation.createDraftFromFile(importFile);
  }

  public async reloadOperation(operationId: string) {
    this.operationDto = await this.apiClientAdminOperation.getById(operationId);
    this.updatedOperationDto.emit(this.operationDto);
  }

  public async updateAllowPayments(operationId: string) {
    await this.apiClientAdminOperation.closeOperationToNewOrders(operationId);
    this.updatedOperationDto.emit(this.operationDto);
    this._notificationService.showSuccess('Operación modificada con éxito');
  }
}
