<mf-form
  [formGroup]="companyForm"
  [entity]="companyDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="commercialName" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="email" />
  </mf-column>

  <mf-id-document
    [personType]="personType.Company"
    formControlName="idDocument" />

  <mf-address formControlName="address" />

  @if (isSuperAdmin) {
  <mf-column>
    <mf-field-check formControlName="isExemptFromOriginDeclaration" />
  </mf-column>
  }
</mf-form>
