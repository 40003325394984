import { Injectable } from '@angular/core';
import { IApiUrlService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/api-url.service';
import { EnvironmentService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/environment.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiUrlService implements IApiUrlService {
  constructor(environmentService: EnvironmentService) {
    this.apiUrl = (environment.apiUrlMappings as any)[environmentService.getHost()];
  }

  apiUrl: string;
}
