import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminNaturalPerson } from '../../../../api/endpoints/ApiClientAdminNaturalPerson';
import { NaturalPersonCreateComponent } from './natural-person-create.component';
import { NaturalPersonEditComponent } from './natural-person-edit.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { NaturalPersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { enumName_mi_shared } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { PersonFiltersDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/PersonFiltersDto';
import { NaturalPersonFiltersDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/NaturalPersonFiltersDto';
import { ActivatedRoute } from '@angular/router';
import { RelatedEntityIssuePopupService } from '../../issue/related-entity-issue-popup/related-entity-issue-popup.service';

@Component({
  standalone: false,
  selector: 'mf-natural-person-grid',
  templateUrl: './natural-person-grid.component.html',
  styleUrls: ['./natural-person-grid.component.scss'],
})
export class NaturalPersonGridComponent {
  constructor(
    public apiClientAdminNaturalPerson: ApiClientAdminNaturalPerson,
    private _modalService: ModalService,
    private _activatedRoute: ActivatedRoute,
    private _relatedEntityIssuePopupService: RelatedEntityIssuePopupService
  ) {
    this.initialFilters.id = this._activatedRoute.snapshot.queryParams.id;
  }

  @ViewChild('grid')
  grid: GridComponent;

  initialFilters = new NaturalPersonFiltersDto();

  columns: IGridColumn<NaturalPersonDto>[] = [
    {
      field: nameof<NaturalPersonDto>((p) => p.fullName),
      header: 'Nombre',
      width: 100,
      filter: {
        propertyName: nameof<PersonFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<PersonDto>((p) => p.idDocument.type),
      header: 'Tipo de documento',
      width: 20,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_shared.IdDocumentType,
        enumNameModule: moduleName.shared,
      },
    },
    {
      field: nameof<PersonDto>((p) => p.idDocument.documentNumber),
      header: 'Documento',
      width: 20,
      filter: {
        propertyName: nameof<PersonFiltersDto>((p) => p.documentNumber),
        type: FilterType.Text,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (naturalPersonDto: NaturalPersonDto) => {
        this._modalService.openRightModal(NaturalPersonEditComponent, {
          openData: naturalPersonDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
            this._relatedEntityIssuePopupService.checkRelatedIssuesAndOpenPopup(naturalPersonDto.id);
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<NaturalPersonFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this.apiClientAdminNaturalPerson.getPaged(search, httpApiRequestOptions);

  openNewNaturalPersonPopup() {
    this._modalService.openRightModal(NaturalPersonCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
