import { Component } from '@angular/core';
import enumTranslate_mi_pspc_EsUy from '../api/enums/enumTranslate.mi_pspc.EsUy.json';
import enumTranslate_mi_shared_EsUy from '../../../../libs/utils/ts-shared/src/lib/api/enums/enumTranslate.mi_shared.EsUy.json';
import enumTranslate_searching_EsUy from '../../../../libs/utils/ts-shared/src/lib/api/searching/enums/enumTranslate.searching.EsUy.json';
import enumTranslate_logging_EsUy from '../../../../libs/utils/ng-shared-components/src/lib/logging/enums/enumTranslate.logging.EsUy.json';
import enumTranslate_template_EsUy from '../../../../libs/utils/ng-shared-components/src/lib/templates/enums/enumTranslate.templates.EsUy.json';
import enumTranslate_emails_EsUy from '../../../../libs/utils/ng-shared-components/src/lib/emails/enums/enumTranslate.emails.EsUy.json';
import { EnumTranslateProviderService } from '../../../../libs/utils/ng-shared-components/src/lib/services/enum-translate-provider.service';
import { LoginService } from '../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { BroadcastChannelService } from './services/broadcast-channel.service';

@Component({
  standalone: false,
  selector: 'mifinanzas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private _loginService: LoginService,
    enumTranslateProviderService: EnumTranslateProviderService,
    private _broadCAstChannelService: BroadcastChannelService
  ) {
    enumTranslateProviderService.initModule('mi_pspc', enumTranslate_mi_pspc_EsUy, true);
    enumTranslateProviderService.initModule('mi_shared', enumTranslate_mi_shared_EsUy);
    enumTranslateProviderService.initModule('emails', enumTranslate_emails_EsUy);
    enumTranslateProviderService.initModule('logging', enumTranslate_logging_EsUy);
    enumTranslateProviderService.initModule('searching', enumTranslate_searching_EsUy);
    enumTranslateProviderService.initModule('templates', enumTranslate_template_EsUy);

    this._broadCAstChannelService.onMessage((event) => {
      if (event.data === 'ReloadPage') {
        window.location.reload();
      }
    });
  }

  logout() {
    this._loginService.logout();
  }
}
