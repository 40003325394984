import { Component } from '@angular/core';
import { PersonType } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';

@Component({
  selector: 'mf-person-create',
  templateUrl: './person-create.component.html',
  styleUrls: ['./person-create.component.scss'],
})
export class PersonCreateComponent {
  public viewType: PersonType;
  public personType = PersonType;
}
