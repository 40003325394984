// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { BankDto } from './BankDto';
import { AccreditationDto } from './AccreditationDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';

export class AccreditationsByAccountFiltersDto extends FiltersDto {
  sourceBankId: string | null = null;
  sourceBank: BankDto;
  accreditationId: string | null = null;
  accreditation: AccreditationDto;
  accreditationDate: FilterRangeDateDto;
  sourceAccountCurrencyList: number[] = [];
  statusList: number[] = [];
  amountTransferred: FilterRangeNumericDto;
  accountNumber: string = '';
  type: number | null = null;
}

export interface AccreditationsByAccountFiltersDtoFormGroup extends FiltersDtoFormGroup {
  sourceBankId?: any;
  sourceBank?: any;
  accreditationId?: any;
  accreditation?: any;
  accreditationDate?: any;
  sourceAccountCurrencyList?: any;
  statusList?: any;
  amountTransferred?: any;
  accountNumber?: any;
  type?: any;
}
