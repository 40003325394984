import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { CustomFormBuilder } from '../validation/form-builder/custom-form-builder';
import { TemplateDto } from './dtos/TemplateDto';
import { enumName_templates } from './enums/_enumName.templates';
import { TemplateDesignDto } from './dtos/TemplateDesignDto';
import { IApiTemplateDesign } from './api/IApiTemplateDesign';
import { TemplateViewModelFormGroup } from './view-models/TemplateViewModel';
import { TemplateGroup } from './enums/TemplateGroup';
import { nameof } from 'ts-simple-nameof';
import { TemplateGenericDto } from './dtos/TemplateGenericDto';
import { LabeledFormControl } from '../validation/form-builder/labeled-form-control';
import { GUID_EMPTY } from '../../../../ts-shared/src/lib/utils-guid';
import { TemplatePdfDto } from './dtos/TemplatePdfDto';
import { TemplateEmailDto } from './dtos/TemplateEmailDto';

@Component({
  selector: 'mf-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent {
  constructor(private _cfb: CustomFormBuilder, @Inject('IApiTemplateDesign') private _apiTemplateDesign: IApiTemplateDesign) {}

  @Input()
  templateDto: TemplateDto;

  @Output()
  save = new EventEmitter<TemplateDto>();

  @Output()
  closeButtonClick = new EventEmitter<TemplateDto>();

  templateForm = this._cfb.group<TemplateViewModelFormGroup>({
    name: [null, Validators.required, 'Nombre'],
    body: [null, Validators.required, 'Cuerpo'],
    typeId: [''],
    type: [null, null, 'Tipo'],
    group: [null, null, 'Grupo'],
    styles: [null, null, 'Estilos'],
    previewData: [null, null, 'Preview data'],
  });

  enumName = enumName_templates;
  moduleName = moduleName;
  templateDesign: TemplateDesignDto;

  ngOnInit() {
    this.templateForm.patchValue(this.templateDto);

    if (this.templateDto.typeId) {
      this.loadTemplateDesign(this.templateDto.typeId);
    }

    if (this.templateDto.group == TemplateGroup.Generic) {
      this.templateForm.addControl(
        nameof<TemplateGenericDto>((p) => p.genericType),
        new LabeledFormControl('Tipo genérico', null, Validators.required)
      );

      this.templateForm.addControl(
        nameof<TemplateGenericDto>((p) => p.code),
        new LabeledFormControl('Código', null, Validators.required)
      );

      this.templateForm.addControl(
        nameof<TemplateGenericDto>((p) => p.restrictTo),
        new LabeledFormControl('Aplica únicamente para')
      );

      if (this.templateDto.id != GUID_EMPTY) {
        this.loadTemplateDesignForGeneric();
      }
    } else if (this.templateDto.group == TemplateGroup.Pdf) {
      this.templateForm.addControl(
        nameof<TemplatePdfDto>((p) => p.paperSize),
        new LabeledFormControl('Tamaño papel', null, Validators.required)
      );
      this.templateForm.addControl(
        nameof<TemplatePdfDto>((p) => p.orientation),
        new LabeledFormControl('Orientación', null, Validators.required)
      );
      this.templateForm.addControl(
        nameof<TemplatePdfDto>((p) => p.paperCustomWidth),
        new LabeledFormControl('Ancho personalizado (px)', null)
      );
      this.templateForm.addControl(
        nameof<TemplatePdfDto>((p) => p.paperCustomHeight),
        new LabeledFormControl('Alto personalizado (px)', null)
      );
      this.templateForm.addControl(
        nameof<TemplatePdfDto>((p) => p.displayPageNumber),
        new LabeledFormControl('Mostrar número de página', null, Validators.required)
      );
    } else if (this.templateDto.group == TemplateGroup.Email) {
      this.templateForm.addControl(
        nameof<TemplateEmailDto>((p) => p.subject),
        new LabeledFormControl('Asunto')
      );
    }
  }

  async loadTemplateDesign(templateTypeId: string | null) {
    if (!templateTypeId) {
      return;
    }

    try {
      this.templateDesign = await this._apiTemplateDesign.getDesign(templateTypeId, { preventSpinner: true });
      this.templateForm.patchValue({ previewData: JSON.stringify(this.templateDesign.previewData) });
    } catch {}
  }

  async loadTemplateDesignForGeneric() {
    try {
      this.templateDesign = await this._apiTemplateDesign.getDesignForGeneric({ preventSpinner: true });
      this.templateForm.patchValue({ previewData: JSON.stringify(this.templateDesign.previewData) });
    } catch {}
  }
}
