import { Component, Inject, Input } from '@angular/core';
import { Icons } from '../../icon/icons';
import { INotification, INotificationTrayService } from '../INotificationTray';

@Component({
  selector: 'mf-notification-menu-item',
  templateUrl: './notification-menu-item.component.html',
  styleUrls: ['./notification-menu-item.component.scss'],
})
export class NotificationMenuItemComponent {
  constructor(@Inject('INotificationTrayService') public notificationTrayService: INotificationTrayService) {}

  @Input()
  notification: INotification;
  icons = Icons;

  toggleRead(isRead: boolean): void {
    if (!isRead) {
      this.notificationTrayService.setAsRead(this.notification.id);
    }
  }

  downloadFile(): void {
    this.notificationTrayService.downloadFile(this.notification.id);
    this.toggleRead(this.notification.isRead);
  }

  navigateToRelatedEntityPage(): void {
    this.notificationTrayService.navigateToRelatedEntityPage(this.notification.id);
  }
}
