import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idDocumentType',
  standalone: false,
})
export class IdDocumentTypePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'CI';
      case 2:
        return 'DNI';
      case 3:
        return 'CPF';
      case 4:
        return 'CC';
      case 999:
        return 'PASSPORT';
      case 1001:
        return 'RUT';
      case 1002:
        return 'CUIT';
      case 1003:
        return 'CNPJ';
      case 1004:
        return 'NIT';
      case 1005:
        return 'RUC';
      case 1006:
        return 'RIF';
      default:
        return '';
    }
  }
}
