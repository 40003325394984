<mf-page title="{{ title }}">
  @if (message) {
  <mf-row>
    {{ message }}
  </mf-row>
  }
  <mf-row class="actions-row">
    <mf-button-secondary
      text="Cerrar"
      (click)="closeAsCancel()" />
    <mf-button
      text="Aceptar"
      (click)="closeAsSuccess()" />
  </mf-row>
</mf-page>
