import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { TransferDto } from '../../../../api/dtos/TransferDto';
import { TransferFiltersDto } from '../../../../api/dtos/TransferFiltersDto';
import { ApiAdminTransfer } from '../../../../api/endpoints/ApiAdminTransfer';
import { ApiClientAdminTransfer } from '../../../../api/endpoints/ApiClientAdminTransfer';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { MovementType } from '../../../../api/enums/MovementType';
import { TransactionStatus } from '../../../../api/enums/TransactionStatus';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { FilterEntityType } from '../../../view-models/FilterEntityType';
import { OrderPopupComponent } from '../../orders/generic-order/order-popup.component';
import { TransferCreateComponent } from './transfer-create.component';

@Component({
  standalone: false,
  selector: 'mf-transfer-grid',
  templateUrl: './transfer-grid.component.html',
  styleUrls: ['./transfer-grid.component.scss'],
})
export class TransferGridComponent implements OnInit {
  constructor(
    public _apiClientAdminTransfer: ApiClientAdminTransfer,
    public _apiAdminTransfer: ApiAdminTransfer,
    private _modalService: ModalService,
    private _activatedRoute: ActivatedRoute
  ) {
    if (this._activatedRoute.snapshot.queryParams.accreditationByAccountId) {
      this.filters.accreditationByAccountId = this._activatedRoute.snapshot.queryParams.accreditationByAccountId;
    }
  }

  @ViewChild('grid')
  grid: GridComponent;

  @Input()
  accreditationOriginNumber: number;
  @Input()
  accreditationConsolidationNumber: number;
  @Input()
  hideFilters = false;
  @Input()
  showCreateButton = false;
  @Input()
  transferId: string;
  @Input()
  accreditationsByAccountId: string;

  filters = new TransferFiltersDto();

  ngOnInit(): void {
    if (this.accreditationOriginNumber) {
      this.filters.accreditationOriginNumber = this.accreditationOriginNumber;
    }
    if (this.accreditationConsolidationNumber) {
      this.filters.accreditationConsolidationNumber = this.accreditationConsolidationNumber;
    }
  }

  columns: IGridColumn<TransferDto>[] = [
    {
      header: 'Fecha',
      field: nameof<TransferDto>((t) => t.createdDate),
      filter: { propertyName: nameof<TransferFiltersDto>((t) => t.createdDate), type: FilterType.Date },
      width: 7,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      header: 'Cliente',
      field: nameof<TransferDto>((t) => t.client.name),
      filter: { propertyName: nameof<TransferFiltersDto>((t) => t.client), type: FilterType.Entity, entityType: FilterEntityType.Client },
      width: 6,
    },
    {
      header: 'Estado',
      field: nameof<TransferDto>((t) => t.status),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.TransactionStatus,
      },
      width: 6,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.TransactionStatus,
      },
    },
    {
      header: 'Tipo',
      field: nameof<TransferDto>((t) => t.movementType),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.movementTypeList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.MovementType,
      },
      width: 4,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
    {
      header: 'Pagador',
      field: nameof<TransferDto>((t) => t.sourceAccount.holderName),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.sourceAccountHolderName),
        type: FilterType.Text,
      },
      width: 10,
    },
    {
      header: 'Origen',
      field: nameof<TransferDto>((t) => t.sourceAccount.bank.name),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.bankSource),
        type: FilterType.Entity,
        entityType: FilterEntityType.Bank,
      },
      width: 5,
    },
    {
      header: 'Cuenta origen',
      field: nameof<TransferDto>((t) => t.sourceAccount.accountNumber),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.sourceAccountNumber),
        type: FilterType.Text,
      },
      width: 8,
      align: 'right',
    },
    {
      header: 'Beneficiario',
      field: nameof<TransferDto>((t) => t.destinationAccount.holderName),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.destinationAccountHolderName),
        type: FilterType.Text,
      },
      width: 10,
    },
    {
      header: 'Destino',
      field: nameof<TransferDto>((t) => t.destinationAccount.bank.name),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.bankDestination),
        type: FilterType.Entity,
        entityType: FilterEntityType.Bank,
      },
      width: 5,
    },
    {
      header: 'Cuenta destino',
      field: nameof<TransferDto>((t) => t.destinationAccount.accountNumber),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.destinationAccountNumber),
        type: FilterType.Text,
      },
      width: 8,
      align: 'right',
    },
    {
      header: 'Referencia',
      field: nameof<TransferDto>((t) => t.reference),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.reference),
        type: FilterType.Text,
      },
      width: 8,
      align: 'right',
    },
    {
      header: 'Moneda',
      field: nameof<TransferDto>((t) => t.destinationAccount.currency),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.currencyList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
      width: 4,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      align: 'right',
    },
    {
      header: 'Monto',
      field: nameof<TransferDto>((t) => t.amount),
      filter: {
        propertyName: nameof<TransferFiltersDto>((t) => t.amount),
        type: FilterType.NumericRange,
      },
      cellOptions: {
        type: CellValueType.Numeric,
      },
      width: 10,
      align: 'right',
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.list,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: transferDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Ver órdenes',
      icon: Icons.list,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openRightModal(OrderPopupComponent, {
          openData: transferDto.id,
          onCloseSuccess: async () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Cancelar',
      icon: Icons.delete,
      hide: (transferDto: TransferDto) =>
        transferDto.movementType == MovementType.Debit || transferDto.status > TransactionStatus.PendingConciliation,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openYesOrNoModal({
          openData: {
            title: '¿Está seguro de cancelar la transferencia?',
          },
          onCloseSuccess: async () => {
            await this._apiClientAdminTransfer.cancel(transferDto.id);
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'TEST - Set as completed',
      icon: Icons.check,
      onClick: (transferDto: TransferDto) => {
        this._apiClientAdminTransfer.testSetCompleted(transferDto.id);
      },
    },
    {
      label: 'Enviar notificación por WebHook',
      icon: Icons.web,
      onClick: async (transferDto: TransferDto) => {
        await this._apiClientAdminTransfer.notifyClientWebHook(transferDto.id);
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<TransferFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminTransfer.getPaged(search, httpApiRequestOptions);

  openNewTransferPopup() {
    this._modalService.openRightModal(TransferCreateComponent, {
      onCloseSuccess: async () => {
        this.grid.reloadPage();
      },
    });
  }
}
