import { Component, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { IssueDto } from '../../../api/dtos/IssueDto';
import { IssueFiltersDto } from '../../../api/dtos/IssueFiltersDto';
import { ApiAdminIssue } from '../../../api/endpoints/ApiAdminIssue';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { IssueStatus } from '../../../api/enums/IssueStatus';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { IssueCreateComponent } from './issue-create.component';
import { IssueDetailsComponent } from './issue-details.component';
import { IssueEditComponent } from './issue-edit.component';
import { EntityType } from '../../../api/enums/EntityType';
import { CompanyEditComponent } from '../person/company/company-edit.component';
import { BankAccountEditComponent } from '../banks/bank-account/bank-account-edit.component';
import { NaturalPersonEditComponent } from '../person/natural-person/natural-person-edit.component';
import { GatewayBankAccountEditComponent } from '../banks/gateway-bank-account/gateway-bank-account-edit.component';
import { TransferDetailsComponent } from '../payment-methods/transfer/transfer-details.component';
import { BankMovementDetailsComponent } from '../payment-methods/bank-movement/bank-movement-details.component';
import { ActivatedRoute } from '@angular/router';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { FilterEntityType } from '../../view-models/FilterEntityType';
import { PagedResultItemDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultItemDto';
import { IGridFilter } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-filter';
import { ApiOperation } from '../../../api/endpoints/ApiOperation';

@Component({
  standalone: false,
  selector: 'mf-issue-grid',
  templateUrl: './issue-grid.component.html',
  styleUrls: ['./issue-grid.component.scss'],
})
export class IssueGridComponent implements OnInit {
  constructor(
    public _apiAdminIssue: ApiAdminIssue,
    private _modalService: ModalService,
    private _notificationService: NotificationService,
    private _activatedRoute: ActivatedRoute,
    private _apiOperation: ApiOperation
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input()
  showCreateButton = false;
  @Input()
  items: IssueDto[];
  @Input()
  rowSelection = false;
  @Input()
  hideFilters = false;

  @Output() selectedItems = new EventEmitter<any>();

  loaded = signal(false);
  filters: IssueFiltersDto;

  ngOnInit(): void {
    this.filters = new IssueFiltersDto();
    this.filters.statusList.push(IssueStatus.New);
    this.filters.statusList.push(IssueStatus.Ongoing);
    let operationBlockedId = this._activatedRoute.snapshot.queryParams['operationBlockedId'];
    if (operationBlockedId) {
      this.filters.operationBlockedId = operationBlockedId;
      const request = async () => {
        this.filters.operationBlocked = await this._apiOperation.getById(operationBlockedId);
        this.loaded.set(true);
      };
      request();
    } else {
      this.loaded.set(true);
    }
  }

  mustIncludeFields = [nameof<IssueDto>((i) => i.description), nameof<IssueDto>((i) => i.entityType), nameof<IssueDto>((i) => i.entityId)];

  globalFilters: IGridFilter[] = [
    {
      header: 'Operación bloqueada',
      propertyName: nameof<IssueFiltersDto>((p) => p.operationBlocked),
      type: FilterType.Entity,
      entityType: FilterEntityType.Operation,
    },
  ];

  columns: IGridColumn<IssueDto>[] = [
    {
      header: 'Número',
      field: nameof<IssueDto>((i) => i.issueNumber),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.issueNumber),
        type: FilterType.Text,
      },
      width: 3,
    },

    {
      header: 'Fecha',
      field: nameof<IssueDto>((i) => i.createdDate),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.createdDate),
        type: FilterType.Text,
      },
      width: 14,
      cellOptions: {
        type: CellValueType.Date,
        dateIncludeTime: true,
      },
    },

    {
      header: 'Nivel',
      field: nameof<IssueDto>((i) => i.level),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.levelList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.IssueLevel,
      },
      width: 5,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.IssueLevel,
      },
    },
    {
      header: 'Tipo',
      field: nameof<IssueDto>((i) => i.type),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.typeList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.IssueType,
      },
      width: 15,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.IssueType,
      },
    },
    {
      header: 'Estado',
      field: nameof<IssueDto>((i) => i.status),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.IssueStatus,
      },
      width: 6,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.IssueStatus,
      },
    },
    {
      header: 'Descripción',
      field: nameof<IssueDto>((i) => i.displayText),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.description),
        type: FilterType.Text,
      },
      width: 40,
    },
    {
      header: 'Asignado',
      field: nameof<IssueDto>((i) => i.assignedTo.naturalPerson.displayText),
      filter: {
        propertyName: nameof<IssueFiltersDto>((p) => p.assignedTo),
        type: FilterType.Entity,
        enumName: FilterEntityType.User,
      },
      width: 7,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.search,
      onClick: (issueDto: IssueDto) => {
        this._modalService.openRightModal(IssueDetailsComponent, {
          openData: issueDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Marcar como resuelto',
      icon: Icons.check,
      onClick: async (issueDto: IssueDto) => {
        await this._apiAdminIssue.resolve(issueDto.id);
        this._notificationService.showSuccess('El incidente se marco como resuelto');
        this.grid.reloadPage();
      },
    },
    {
      label: 'Reabrir incidente',
      icon: Icons.undo,
      hide: (issueDto: IssueDto) => issueDto.status !== IssueStatus.Resolved,
      onClick: async (issueDto: IssueDto) => {
        await this._apiAdminIssue.reopen(issueDto.id);
        this._notificationService.showSuccess('El incidente se reabrió con éxito');
        this.grid.reloadPage();
      },
    },
    {
      label: 'Asignar a usuario',
      icon: Icons.user,
      onClick: async (issueDto: IssueDto) => {
        await this._apiAdminIssue.assignToCurrentUser(issueDto.id);
        this._notificationService.showSuccess('El incidente se asignó al usuario con éxito');
        this.grid.reloadPage();
      },
    },
    {
      label: 'Editar incidente',
      icon: Icons.edit,
      onClick: async (issueDto: IssueDto) => {
        this._modalService.openRightModal(IssueEditComponent, {
          openData: issueDto,
          onCloseSuccess: () => this.grid.reloadPage(),
        });
      },
    },
    {
      label: 'Ver entidad',
      icon: Icons.chevronRight,
      disabled: (issueDto: IssueDto) => !issueDto.entityId,
      onClick: async (issueDto: IssueDto) => this.handleRelatedEntity(issueDto),
    },
  ];

  apiEndpoint = (search: PagedSearchDto<IssueFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminIssue.getPaged(search, httpApiRequestOptions);

  handleRelatedEntity(issueDto: IssueDto) {
    const modalConfig = {
      openData: issueDto.entityId,
      onCloseSuccess: () => this.grid.reloadPage(),
    };
    switch (issueDto.entityType) {
      case EntityType.BankAccount:
        this._modalService.openRightModal(BankAccountEditComponent, modalConfig);
        break;
      case EntityType.Company:
        this._modalService.openRightModal(CompanyEditComponent, modalConfig);
        break;
      case EntityType.NaturalPerson:
        this._modalService.openRightModal(NaturalPersonEditComponent, modalConfig);
        break;
      case EntityType.GatewayBankAccount:
        this._modalService.openRightModal(GatewayBankAccountEditComponent, modalConfig);
        break;
      case EntityType.BankMovement:
        this._modalService.openRightModal(BankMovementDetailsComponent, modalConfig);
        break;
      case EntityType.Transfer:
        this._modalService.openRightModal(TransferDetailsComponent, modalConfig);
        break;
    }
  }

  openNewIssuePopup() {
    this._modalService.openRightModal(IssueCreateComponent, {
      openData: {},
      onCloseSuccess: () => this.grid.reloadPage(),
    });
  }

  selectItems(event: any) {
    this.selectedItems.emit(event);
  }
}
