<h2>Vista preliminar</h2>
<mf-row class="editor-container">
  @if (showPdf) { @if (safePreviewPdfUrl) {
  <embed
    class="editor-preview"
    [src]="safePreviewPdfUrl"
    type="application/pdf"
    (load)="onPreviewPdfLoad($event)" />
  } @else {
  <div class="editor-preview">Cargando...</div>
  } } @else {
  <iframe
    class="editor-preview"
    [srcdoc]="safePreviewHtml"></iframe>
  }
</mf-row>
<mf-row>
  <mf-button-icon
    [disabled]="loadingPreview"
    [icon]="icons.refresh"
    text="Actualizar"
    (click)="refreshPreview()" />

  @if (template.group == templateGroup.Pdf) {
  <mf-field-check
    [(ngModel)]="showPdf"
    (ngModelChange)="refreshPreview()"
    label="Mostrar PDF" />
  }
</mf-row>
