<div [formGroup]="idDocumentForm">
  <mf-column [md]="4">
    <mf-field-select
      formControlName="country"
      [enumName]="enumName.Country"
      [enumNameModule]="enumNameModule"
      (ngModelChange)="loadIdDocumentByCountry()" />
  </mf-column>

  <mf-column [md]="4">
    <mf-field-select
      formControlName="type"
      [availableValues]="idDocumentTypesList ?? []"
      [enumName]="enumName.IdDocumentType"
      [enumNameModule]="enumNameModule" />
  </mf-column>

  <mf-column [md]="4">
    <mf-field-text
      [readOnly]="!idDocumentForm.value.type"
      [loading]="loadingValidation"
      formControlName="documentNumber" />
  </mf-column>
</div>
