import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { BankBranchDto, BankBranchDtoFormGroup } from '../../../../api/dtos/BankBranchDto';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  standalone: false,
  selector: 'mf-bank-branch',
  templateUrl: './bank-branch.component.html',
  styleUrls: ['./bank-branch.component.scss'],
})
export class BankBranchComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  bankBranchDto: BankBranchDto;

  @Output()
  save = new EventEmitter<BankBranchDto>();

  @Output()
  close = new EventEmitter();

  bankBranchForm = this._cfb.group<BankBranchDtoFormGroup>({
    id: [GUID_EMPTY],
    name: ['', Validators.required, 'Nombre de la sucursal'],
    bankId: ['', Validators.required],
    bank: [null, Validators.required, 'Banco'],
    branchCode: [null, Validators.required, 'Código de la sucursal'],
  });
}
