// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { CompanyDto } from '../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';

export class ClientFiltersDto extends FiltersDto {
  company: CompanyDto;
  name: string = '';
}

export interface ClientFiltersDtoFormGroup extends FiltersDtoFormGroup {
  company?: any;
  name?: any;
}
