import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { UtilsAuthSharedModule } from '@mifinanzas/utils/auth-shared';
import { UtilsNgSharedComponentsModule } from '@mifinanzas/utils/ng-shared-components';
import { LayoutService } from '../../../../libs/utils/ng-shared-components/src/lib/services/layout.service';
import { ModalService } from '../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { AutocompleteBankAccountComponent } from './components/autocompletes/autocomplete-bank-account.component';
import { AutocompleteBankBranchComponent } from './components/autocompletes/autocomplete-bank-branch.component';
import { AutocompleteBankComponent } from './components/autocompletes/autocomplete-bank.component';
import { AutocompleteClientComponent } from './components/autocompletes/autocomplete-client.component';
import { AutocompleteCollectOrderComponent } from './components/autocompletes/autocomplete-collect-order.component';
import { AutocompleteOperationComponent } from './components/autocompletes/autocomplete-operation.component';
import { AutocompletePaymentOrderComponent } from './components/autocompletes/autocomplete-payment-order.component';
import { AutocompleteUserComponent } from './components/autocompletes/autocomplete-user.component';
import { AutocompleteValueListComponent } from './components/autocompletes/autocomplete-value-list.component';
import { TrackGridComponent } from './components/track-grid/track-grid.component';
import { TrackingPopupComponent } from './components/track-grid/tracking-popup.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginGoogleCallbackComponent } from './login/login-google-callback.component';
import { LoginLayoutComponent } from './login/login-layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register/register.component';
import { AccreditationDetailsComponent } from './pages/accreditations/accreditation-details.component';
import { AccreditationGridComponent } from './pages/accreditations/accreditation-grid.component';
import { AccreditationsByAccountDetailsComponent } from './pages/accreditations/accreditations-by-account-details.component';
import { AccreditationsByAccountGridComponent } from './pages/accreditations/accreditations-by-account-grid.component';
import { AccreditationsByAccountCancelComponent } from './pages/accreditations/accreditations-by-account-voucher/accreditations-by-account-cancel.component';
import { AccreditationsByAccountVoucherCreateComponent } from './pages/accreditations/accreditations-by-account-voucher/accreditations-by-account-voucher-create.component';
import { AccreditationsByAccountVoucherComponent } from './pages/accreditations/accreditations-by-account-voucher/accreditations-by-account-voucher.component';
import { UndoAccreditationComponent } from './pages/accreditations/undo-accreditation.component';
import { BankAccountCreateComponent } from './pages/banks/bank-account/bank-account-create.component';
import { BankAccountEditComponent } from './pages/banks/bank-account/bank-account-edit.component';
import { BankAccountGridComponent } from './pages/banks/bank-account/bank-account-grid.component';
import { BankAccountComponent } from './pages/banks/bank-account/bank-account.component';
import { HolderVerificationComponent } from './pages/banks/bank-account/holder-verification/holder-verification.component';
import { BankBranchCreateComponent } from './pages/banks/bank-branch/bank-branch-create.component';
import { BankBranchEditComponent } from './pages/banks/bank-branch/bank-branch-edit.component';
import { BankBranchGridComponent } from './pages/banks/bank-branch/bank-branch-grid.component';
import { BankBranchComponent } from './pages/banks/bank-branch/bank-branch.component';
import { BankCreateComponent } from './pages/banks/bank/bank-create.component';
import { BankEditComponent } from './pages/banks/bank/bank-edit.component';
import { BankGridComponent } from './pages/banks/bank/bank-grid.component';
import { BankComponent } from './pages/banks/bank/bank.component';
import { BcuReportGridComponent } from './pages/banks/bcu-report/bcu-report-grid.component';
import { CreateBcuReportPopupComponent } from './pages/banks/bcu-report/create/create-bcu-report-popup.component';
import { GatewayBankAccountCreateComponent } from './pages/banks/gateway-bank-account/gateway-bank-account-create.component';
import { GatewayBankAccountEditComponent } from './pages/banks/gateway-bank-account/gateway-bank-account-edit.component';
import { GatewayBankAccountGridComponent } from './pages/banks/gateway-bank-account/gateway-bank-account-grid.component';
import { GatewayBankAccountComponent } from './pages/banks/gateway-bank-account/gateway-bank-account.component';
import { ClientAddUsersComponent } from './pages/client/client-add-users.component';
import { ClientAdminComponent } from './pages/client/client-admin.component';
import { ClientCreateComponent } from './pages/client/client-create.component';
import { ClientDetailsComponent } from './pages/client/client-details.component';
import { ClientEditComponent } from './pages/client/client-edit.component';
import { ClientGridComponent } from './pages/client/client-grid.component';
import { ClientComponent } from './pages/client/client.component';
import { ConciliationGridComponent } from './pages/conciliations/conciliation-grid.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { GatewayCreateComponent } from './pages/gateways/gateway-create.component';
import { GatewayEditComponent } from './pages/gateways/gateway-edit.component';
import { GatewayGridComponent } from './pages/gateways/gateway-grid.component';
import { GatewayComponent } from './pages/gateways/gateway.component';
import { HomeComponent } from './pages/home/home.component';
import { IssueCreateComponent } from './pages/issue/issue-create.component';
import { IssueDetailsComponent } from './pages/issue/issue-details.component';
import { IssueEditComponent } from './pages/issue/issue-edit.component';
import { IssueGridComponent } from './pages/issue/issue-grid.component';
import { IssueComponent } from './pages/issue/issue.component';
import { NotificationCreateComponent } from './pages/notification/notification-create.component';
import { NotificationGridComponent } from './pages/notification/notification-grid.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { OperationCreateComponent } from './pages/operation/operation-create.component';
import { OperationDetailsComponent } from './pages/operation/operation-details.component';
import { OperationEditComponent } from './pages/operation/operation-edit.component';
import { OperationGridComponent } from './pages/operation/operation-grid.component';
import { OperationComponent } from './pages/operation/operation.component';
import { OperationService } from './pages/operation/operation.service';
import { CollectOrderVoucherCreateComponent } from './pages/orders/collect-order-voucher/collect-order-voucher-create.component';
import { CollectOrderVoucherGridComponent } from './pages/orders/collect-order-voucher/collect-order-voucher-grid.component';
import { CollectOrderVoucherComponent } from './pages/orders/collect-order-voucher/collect-order-voucher.component';
import { CollectOrderCreateComponent } from './pages/orders/collect-order/collect-order-create.component';
import { CollectOrderEditComponent } from './pages/orders/collect-order/collect-order-edit.component';
import { CollectOrderGridPageComponent } from './pages/orders/collect-order/collect-order-grid-page.component';
import { CollectOrderGridComponent } from './pages/orders/collect-order/collect-order-grid.component';
import { CollectOrderComponent } from './pages/orders/collect-order/collect-order.component';
import { OrderGridPageComponent } from './pages/orders/generic-order/order-grid-page.component';
import { OrderGridComponent } from './pages/orders/generic-order/order-grid.component';
import { PaymentOrderCreateComponent } from './pages/orders/payment-order/payment-order-create.component';
import { PaymentOrderEditComponent } from './pages/orders/payment-order/payment-order-edit.component';
import { PaymentOrderGridPageComponent } from './pages/orders/payment-order/payment-order-grid-page.component';
import { PaymentOrderGridComponent } from './pages/orders/payment-order/payment-order-grid.component';
import { PaymentOrderComponent } from './pages/orders/payment-order/payment-order.component';
import { BankMovementGridPageComponent } from './pages/payment-methods/bank-movement/bank-movement-grid-page.component';
import { BankMovementGridComponent } from './pages/payment-methods/bank-movement/bank-movement-grid.component';
import { TransferDetailsComponent } from './pages/payment-methods/transfer/transfer-details.component';
import { TransferGridPageComponent } from './pages/payment-methods/transfer/transfer-grid-page.component';
import { TransferGridComponent } from './pages/payment-methods/transfer/transfer-grid.component';
import { CompanyCreateComponent } from './pages/person/company/company-create.component';
import { CompanyEditComponent } from './pages/person/company/company-edit.component';
import { CompanyGridComponent } from './pages/person/company/company-grid.component';
import { CompanyComponent } from './pages/person/company/company.component';
import { NaturalPersonCreateComponent } from './pages/person/natural-person/natural-person-create.component';
import { NaturalPersonEditComponent } from './pages/person/natural-person/natural-person-edit.component';
import { NaturalPersonGridComponent } from './pages/person/natural-person/natural-person-grid.component';
import { NaturalPersonComponent } from './pages/person/natural-person/natural-person.component';
import { AppUserCreateComponent } from './pages/user/app-user-create.component';
import { AppUserEditComponent } from './pages/user/app-user-edit.component';
import { AppUserGridComponent } from './pages/user/app-user-grid.component';
import { AppUserRolesComponent } from './pages/user/app-user-roles.component';
import { AppUserComponent } from './pages/user/app-user.component';
import { ValueListCreateComponent } from './pages/value-list/value-list-create.component';
import { ValueListEditComponent } from './pages/value-list/value-list-edit.component';
import { ValueListGridComponent } from './pages/value-list/value-list-grid.component';
import { ValueListComponent } from './pages/value-list/value-list.component';
import { NotificationTrayService } from './services/notification-tray.service';
import { SideMenuComponent } from './side-menu/side-menu-component/side-menu.component';
import { BroadcastChannelService } from './services/broadcast-channel.service';
import { ApiUrlService } from './services/api-url.service';
import { ConfigurationEditComponent } from './pages/configuration/configuration-edit.component';
import { LoginService } from '../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { ApiIdDocument } from '../api/endpoints/ApiIdDocument';
import { AutocompleteService } from './services/autocomplete.service';
import { ApiTemplatePdf } from '../api/endpoints/ApiTemplatePdf';
import { ApiTemplateEmail } from '../api/endpoints/ApiTemplateEmail';
import { ApiTemplate } from '../api/endpoints/ApiTemplate';
import { ApiTemplateType } from '../api/endpoints/ApiTemplateType';
import { ApiTemplateDesign } from '../api/endpoints/ApiTemplateDesign';
import { ApiAdminLoggingConfig } from '../api/endpoints/ApiAdminLoggingConfig';
import { ApiAdminLoggingViewer } from '../api/endpoints/ApiAdminLoggingViewer';
import { ApiTemplateGeneric } from '../api/endpoints/ApiTemplateGeneric';
import { ApiEmailSent } from '../api/endpoints/ApiEmailSent';

@NgModule({
  declarations: [
    AccreditationGridComponent,
    AccreditationDetailsComponent,
    UndoAccreditationComponent,
    AccreditationsByAccountGridComponent,
    AccreditationsByAccountDetailsComponent,
    AccreditationsByAccountVoucherComponent,
    AccreditationsByAccountVoucherCreateComponent,
    AccreditationsByAccountCancelComponent,
    AppComponent,
    AppUserGridComponent,
    AppUserComponent,
    AppUserCreateComponent,
    AppUserEditComponent,
    AppUserGridComponent,
    AppUserRolesComponent,
    AutocompleteBankAccountComponent,
    AutocompleteBankComponent,
    AutocompleteBankBranchComponent,
    AutocompleteClientComponent,
    AutocompleteOperationComponent,
    AutocompleteUserComponent,
    AutocompleteValueListComponent,
    AutocompleteCollectOrderComponent,
    AutocompletePaymentOrderComponent,
    BankComponent,
    BankCreateComponent,
    BankEditComponent,
    BankGridComponent,
    BankAccountComponent,
    BankAccountGridComponent,
    BankAccountCreateComponent,
    BankAccountEditComponent,
    BankBranchComponent,
    BankBranchGridComponent,
    BankBranchCreateComponent,
    BankBranchEditComponent,
    BankMovementGridComponent,
    BankMovementGridPageComponent,
    BcuReportGridComponent,
    ClientAddUsersComponent,
    ClientAdminComponent,
    ClientComponent,
    ClientCreateComponent,
    ClientDetailsComponent,
    ClientEditComponent,
    ClientGridComponent,
    CollectOrderComponent,
    CollectOrderCreateComponent,
    CollectOrderEditComponent,
    CollectOrderGridComponent,
    CollectOrderGridPageComponent,
    CollectOrderVoucherCreateComponent,
    CollectOrderVoucherComponent,
    CollectOrderVoucherGridComponent,
    ConfigurationComponent,
    ConfigurationEditComponent,
    CompanyComponent,
    CompanyCreateComponent,
    CompanyEditComponent,
    CompanyGridComponent,
    ConciliationGridComponent,
    CreateBcuReportPopupComponent,
    GatewayComponent,
    GatewayGridComponent,
    GatewayEditComponent,
    GatewayCreateComponent,
    GatewayBankAccountComponent,
    GatewayBankAccountGridComponent,
    GatewayBankAccountEditComponent,
    GatewayBankAccountCreateComponent,
    OrderGridComponent,
    OrderGridPageComponent,
    HomeComponent,
    HeaderComponent,
    HolderVerificationComponent,
    IssueComponent,
    IssueCreateComponent,
    IssueEditComponent,
    IssueDetailsComponent,
    IssueGridComponent,
    LoginComponent,
    LoginGoogleCallbackComponent,
    LayoutComponent,
    LoginLayoutComponent,
    NaturalPersonComponent,
    NaturalPersonCreateComponent,
    NaturalPersonEditComponent,
    NaturalPersonGridComponent,
    NotificationComponent,
    NotificationCreateComponent,
    NotificationGridComponent,
    OperationComponent,
    OperationCreateComponent,
    OperationDetailsComponent,
    OperationEditComponent,
    OperationGridComponent,
    PaymentOrderComponent,
    PaymentOrderCreateComponent,
    PaymentOrderEditComponent,
    PaymentOrderGridComponent,
    PaymentOrderGridPageComponent,
    RegisterComponent,
    SideMenuComponent,
    TrackGridComponent,
    TrackingPopupComponent,
    TransferDetailsComponent,
    TransferGridComponent,
    TransferGridPageComponent,
    ValueListComponent,
    ValueListCreateComponent,
    ValueListGridComponent,
    ValueListEditComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    BrowserAnimationsModule,
    UtilsNgSharedComponentsModule,
    UtilsAuthSharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDialogModule,
  ],
  providers: [
    LoginService,
    LayoutService,
    ModalService,
    OperationService,
    BroadcastChannelService,
    provideHttpClient(),
    { provide: 'INotificationTrayService', useClass: NotificationTrayService },
    { provide: 'IAutocompleteService', useClass: AutocompleteService },
    { provide: 'IApiEmailSent', useClass: ApiEmailSent },
    { provide: 'IApiIdDocument', useClass: ApiIdDocument },
    { provide: 'IApiTemplateType', useClass: ApiTemplateType },
    { provide: 'IApiTemplateDesign', useClass: ApiTemplateDesign },
    { provide: 'IApiTemplate', useClass: ApiTemplate },
    { provide: 'IApiTemplateDesign', useClass: ApiTemplateDesign },
    { provide: 'IApiTemplateType', useClass: ApiTemplateType },
    { provide: 'IApiTemplatePdf', useClass: ApiTemplatePdf },
    { provide: 'IApiTemplateEmail', useClass: ApiTemplateEmail },
    { provide: 'IApiTemplateGeneric', useClass: ApiTemplateGeneric },
    { provide: 'IApiUrlService', useClass: ApiUrlService },
    { provide: 'IApiAdminLoggingConfig', useClass: ApiAdminLoggingConfig },
    { provide: 'IApiAdminLoggingViewer', useClass: ApiAdminLoggingViewer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
