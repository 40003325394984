import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { BankBranchDto } from '../../../../api/dtos/BankBranchDto';
import { BankBranchFiltersDto } from '../../../../api/dtos/BankBranchFiltersDto';
import { ApiClientAdminBankBranch } from '../../../../api/endpoints/ApiClientAdminBankBranch';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { BankBranchCreateComponent } from './bank-branch-create.component';
import { BankBranchEditComponent } from './bank-branch-edit.component';

@Component({
  selector: 'mf-bank-branch-grid',
  templateUrl: './bank-branch-grid.component.html',
  styleUrls: ['./bank-branch-grid.component.scss'],
})
export class BankBranchGridComponent {
  constructor(private _apiClientAdminBankBranch: ApiClientAdminBankBranch, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  bankBranch: BankBranchDto;

  columns: IGridColumn<BankBranchDto>[] = [
    {
      field: nameof<BankBranchDto>((bb) => bb.bank.name),
      header: 'Banco',
      width: 20,
      filter: {
        propertyName: nameof<BankBranchFiltersDto>((bb) => bb.bankName),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<BankBranchDto>((bb) => bb.name),
      header: 'Sucursal',
      width: 70,
      filter: {
        propertyName: nameof<BankBranchFiltersDto>((bb) => bb.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    { field: nameof<BankBranchDto>((bb) => bb.branchCode), header: 'Código', width: 10 },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (bankBranchDto: BankBranchDto) => {
        this._modalService.openRightModal(BankBranchEditComponent, {
          openData: bankBranchDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<BankBranchFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminBankBranch.getPaged(search, httpApiRequestOptions);

  openNewBankBranchPopup() {
    this._modalService.openRightModal(BankBranchCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
