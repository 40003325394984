@switch (gridFilter.type) { @case (filterType.Text) {
<mf-grid-filter-text
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.NumericRange) {
<mf-grid-filter-numeric-range
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.Number) {
<mf-grid-filter-numeric
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.Date) {
<mf-grid-filter-date
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.Enum) {
<mf-grid-filter-select
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.EnumList) {
<mf-grid-filter-select-multiple
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.Boolean) {
<mf-grid-filter-checkbox
  [gridFilter]="gridFilter"
  [(ngModel)]="value" />
} @case (filterType.Entity) {
<mf-grid-filter-entity
  [gridFilter]="gridFilter"
  [(ngModel)]="value"
  (ngModelChange)="relatedIdChange.emit($event?.id)" />
} }
