import { Directive, Input } from '@angular/core';
import { ModelValueBaseComponent } from '../../model-value-base.component';

@Directive()
export class GridFilterBase<TFilter, TValue> extends ModelValueBaseComponent<TValue> {
  @Input()
  gridFilter: TFilter;

  @Input()
  focus = false;
}
