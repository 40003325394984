// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum EntityType {
	BankAccount = 1,
	GatewayBankAccount = 2,
	Transfer = 3,
	Company = 4,
	NaturalPerson = 5,
	BankMovement = 6,
	Accreditation = 7,
	Order = 8,
}
