import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  getHost(): string {
    return window.location.host;
  }
}
