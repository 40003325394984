<mf-page title="Detalles de la transferencia">
  @if (transferDto) {
  <mf-column-display label="Id">{{ transferDto.id }}</mf-column-display>
  <mf-column-display label="Cliente">{{ transferDto.client || '-' }}</mf-column-display>
  <mf-column-display label="Tipo de movimiento">{{ transferDto.movementType | translate : enumName.MovementType }}</mf-column-display>
  <mf-column-display label="Tipo de transacción">{{ transferDto.type | translate : enumName.TransactionType }}</mf-column-display>
  <mf-column-display label="Moneda">{{ transferDto.currency | translate : enumName.Currency }}</mf-column-display>
  <mf-column-display label="Monto">{{ transferDto.amount }}</mf-column-display>
  <mf-column-display label="Estado">{{ transferDto.status | translate : enumName.TransactionStatus }}</mf-column-display>
  <mf-column-display label="Referencia">{{ transferDto.reference || '-' }}</mf-column-display>
  <mf-column-display label="Id de conciliación">{{ transferDto.conciliationRowId || '-' }}</mf-column-display>
  <mf-column-display label="Voucher">{{ transferDto.voucherUrl || '-' }}</mf-column-display>
  }
</mf-page>
