import { Component, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { es } from 'date-fns/locale';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  standalone: false,
  selector: 'mf-field-date-range',
  templateUrl: './field-date-range.component.html',
  styleUrls: ['./field-date-range.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: es },
    DateFnsAdapter,
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_DATE_FNS_FORMATS,
    },
  ],
})
export class FieldDateRangeComponent extends FieldMatBaseComponent<DateTimeRange> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }

  override get controlType(): string {
    return 'field-date-range';
  }

  @Input() dateOnly = true;
}

export class DateTimeRange {
  constructor(public min: Date | null, public max: Date | null) {}
}
