@if (operationDto.id) {
<mf-column-display label="Cliente">
  {{ operationDto.client.name }}
</mf-column-display>
<mf-column-display label="Motivo de la operación">
  {{ operationDto.reason }}
</mf-column-display>
<mf-column-display label="Monto">
  {{ operationDto.currency | customCurrency }} {{ operationDto.amount | number : '1.2-2' }}
</mf-column-display>
<mf-row align="right">
  <mf-button
    [disabled]="operationDto.status >= operationStatus.Cashing"
    text="Empezar a cobrar"
    (click)="executeOperation()" />
  <mf-button
    [disabled]="!operationDto.isOpenForNewOrders"
    text="Habilitar pagos"
    (click)="updateAllowPayments()" />
  <mf-button
    [disabled]="!operationDto.isOpenForNewOrders"
    text="Actualizar"
    (click)="updateOperation()" />
</mf-row>
}

<mf-row-separator />

@if ((!operationDto.id && !isEditing) || isEditing || (operationDto.id && operationDto.status == operationStatus.Draft)) {
<mf-form
  [textButton]="textButton"
  [disableButton]="!!operationDto.id && !isEditing"
  [formGroup]="operationForm"
  [entity]="operationDto"
  (save)="save.emit($event)"
  (closeButtonClick)="closeModal()"
  [closeButtonShow]="true">
  @if(operationDto.status == operationStatus.Draft) { @if(isSuperAdmin){
  <mf-column>
    <mf-autocomplete-client
      formControlName="client"
      (itemIdChanged)="operationForm.patchValue({ clientId: $event })" />
  </mf-column>
  } @else if (!isSuperAdmin) {
  <mf-column-display label="Cliente">
    {{ clientName }}
  </mf-column-display>
  }
  <mf-column>
    <mf-field-text formControlName="reason" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="currency"
      [enumName]="enumName.CurrencyName"
      [availableValues]="currencyList" />
  </mf-column>
  } @if (operationDto.id) {
  <mf-row>
    <h2>Órdenes de cobro</h2>
  </mf-row>
  <mf-row>
    <mf-collect-order-grid
      [showCreateButton]="true"
      [disableCreateButton]="
        !(
          operationDto.status == operationStatus.Draft ||
          (operationDto.status == operationStatus.Cashing && operationDto.isOpenForNewOrders)
        )
      "
      [hideFilters]="true"
      [operationId]="operationDto.id" />
  </mf-row>
  <div class="pull-right">
    <mf-column-display
      align="right"
      label="Monto total de órdenes de cobro">
      {{ operationDto.currency | customCurrency }}
      {{ getCollectOrderAmount() | number : '1.2-2' }}
    </mf-column-display>
  </div>
  <mf-row>
    <h2>Órdenes de pago</h2>
  </mf-row>
  <mf-row>
    <mf-payment-order-grid
      [showCreateButton]="true"
      [disableCreateButton]="
        !(
          operationDto.status == operationStatus.Draft ||
          (operationDto.status == operationStatus.Cashing && operationDto.isOpenForNewOrders)
        )
      "
      [hideFilters]="true"
      [operationId]="operationDto.id" />
  </mf-row>
  <div class="pull-right">
    <mf-column-display
      align="right"
      label="Monto total de órdenes de pago">
      {{ operationDto.currency | customCurrency }}
      {{ getPaymentOrderAmount() | number : '1.2-2' }}
    </mf-column-display>
  </div>
  <mf-row-separator />
  }
</mf-form>
}
