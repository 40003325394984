import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ValueListDto } from '../../../api/dtos/ValueListDto';
import { ValueListFiltersDto } from '../../../api/dtos/ValueListFiltersDto';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { ValueListCreateComponent } from './value-list-create.component';
import { ValueListEditComponent } from './value-list-edit.component';
import { ApiAdminValueList } from '../../../api/endpoints/ApiAdminValueList';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  standalone: false,
  selector: 'mf-value-list-grid',
  templateUrl: './value-list-grid.component.html',
  styleUrls: ['./value-list-grid.component.scss'],
})
export class ValueListGridComponent {
  constructor(private _modalService: ModalService, private _apiValueList: ApiAdminValueList) {}

  @ViewChild('grid')
  grid: GridComponent;

  columns: IGridColumn<ValueListDto>[] = [
    {
      field: nameof<ValueListDto>((p) => p.valueListType),
      header: 'Tipo',
      width: 10,
      cellOptions: { type: CellValueType.Enum, enumName: enumName_mi_pspc.ValueListType },
    },
    {
      field: nameof<ValueListDto>((p) => p.displayText),
      header: 'Texto',
      width: 10,
    },
    {
      field: nameof<ValueListDto>((p) => p.description),
      header: 'Descripción',
      width: 10,
    },
    {
      field: nameof<ValueListDto>((p) => p.color),
      header: 'Color',
      width: 10,
      render: (item: ValueListDto) =>
        '<span style="background: ' + item.color + ';padding: 5px 15px;border-radius: 20px; color: white">' + item.color + '</span>',
    },
    {
      field: nameof<ValueListDto>((p) => p.country),
      header: 'País',
      width: 10,
      cellOptions: { type: CellValueType.Enum, enumName: enumName_mi_shared.Country, enumNameModule: moduleName.shared },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (valueListDto: ValueListDto) => {
        this._modalService.openRightModal(ValueListEditComponent, {
          openData: valueListDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<ValueListFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiValueList.getPaged(search, httpApiRequestOptions);

  openNewValueList() {
    this._modalService.openRightModal(ValueListCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
