import { Component } from '@angular/core';
import { LoginService } from '../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { HeaderMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/menu/header-menu-item';
import { LayoutService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/layout.service';
import { NavigationService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { ToolBarQuickAccess } from '../../../../../../libs/utils/ng-shared-components/src/lib/tool-bar/tool-bar.component';
import { AppRoute } from '../../app.routes.path';
import { Roles } from '../../../api/enums/Roles';
import { ApiClientAdminUsers } from '../../../api/endpoints/ApiClientAdminUsers';
import { ClientUsersAdminDto } from '../../../api/dtos/ClientUsersAdminDto';
import { ApiAuth } from '../../../api/endpoints/ApiAuth';
import { SwitchClientRequestDto } from '../../../api/dtos/SwitchClientRequestDto';
import { BroadcastChannelService } from '../../services/broadcast-channel.service';

@Component({
  selector: 'mf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private _loginService: LoginService,
    private _navigationService: NavigationService,
    private _layoutService: LayoutService,
    private _apiClientAdminUsers: ApiClientAdminUsers,
    private _apiAuth: ApiAuth,
    private _broadCastChannelService: BroadcastChannelService
  ) {
    this.username = _loginService.loggedUsername;
    this.clientName = _loginService.loggedClientName;

    this.setQuickAccessList();
    this.loadClientAdminUsersList();
  }

  logoUrl = '/assets/img/Mi_Finanzas.png';
  username: string;
  clientName: string;
  quickAccessList: ToolBarQuickAccess[] = [];
  clientAdminUsersList: ClientUsersAdminDto[] = [];

  menuItems: HeaderMenuItem[] = [
    {
      icon: Icons.accountCircle,
      label: 'Mi perfil',
      onClick: () => {
        this._navigationService.navigate(AppRoute.clients);
      },
    },
    {
      icon: Icons.star,
      label: 'Cliente actual',
      onClick: () => {
        this._navigationService.navigate(AppRoute.client);
      },
    },
    {
      icon: Icons.accountCircle,
      isToggle: true,
      toggleValue: this._layoutService.isDarkMode,
      label: 'Modo oscuro',
      onClick: () => {
        this._layoutService.toggleDarkMode();
      },
    },
    {
      icon: Icons.logout,
      label: 'Salir',
      onClick: () => {
        this._loginService.logout();
        window.location.reload();
      },
    },
  ];

  setQuickAccessList() {
    this.quickAccessList = [
      {
        label: 'Operaciones',
        url: AppRoute.operations,
      },
      {
        label: 'Cobros',
        url: AppRoute.orders.collect.list,
      },
      {
        label: 'Pagos',
        url: AppRoute.orders.payment.list,
      },
      {
        label: 'Transferencias',
        url: AppRoute.paymentTransactions.transfers.list,
      },
      ...(this._loginService.userIsInRole([Roles[Roles.SuperAdmin]])
        ? [
            {
              label: 'Conciliaciones',
              url: AppRoute.conciliations,
            },
            {
              label: 'Acreditaciones',
              url: AppRoute.accreditations,
            },
            {
              label: 'Movimientos bancarios',
              url: AppRoute.paymentTransactions.bankMovements.list,
            },
          ]
        : []),
    ];
  }

  async loadClientAdminUsersList() {
    this.clientAdminUsersList = await this._apiClientAdminUsers.getAllForUser(this._loginService.loggedUserId);
    if (this.clientAdminUsersList.length > 1) {
    }
    this.clientAdminUsersList.map((cl) => {
      if (cl.clientId != this._loginService.loggedClientId) {
        const clientOption = new HeaderMenuItem();
        {
          clientOption.icon = Icons.accountCircle;
          clientOption.label = cl.client.name;
          clientOption.onClick = () => {
            this.switchClient(cl.clientId);
          };
        }
        this.menuItems.unshift(clientOption);
      }
    });
  }

  async switchClient(clientId: string) {
    const switchClientRequestDto = new SwitchClientRequestDto();
    switchClientRequestDto.clientId = clientId;
    switchClientRequestDto.username = this._loginService.loggedUsername;

    const loginResponse = await this._apiAuth.switchClient(switchClientRequestDto);
    this._loginService.loginWithToken(loginResponse.token);
    this._broadCastChannelService.postMessage('ReloadPage');
  }
}
