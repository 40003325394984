<mf-form
  [formGroup]="clientForm"
  [entity]="clientDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  @if (clientDto) {
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-company
      label="Empresa"
      formControlName="company"
      (itemIdChanged)="clientForm.patchValue({ companyId: $event })" />
  </mf-column>
  <mf-column>
    <mf-field-text
      label="Mail para notificaciones"
      formControlName="notificationEmail" />
  </mf-column>
  <mf-column>
    <mf-field-text
      label="Endpoint Url"
      formControlName="endpointUrl" />
  </mf-column>
  @if (isEditing) {
  <mf-column>
    <mf-field-text
      formControlName="apiKey"
      hint="ClientID: {{ clientDto.id }}" />
  </mf-column>
  } }
  <mf-row align="right">
    <mf-button-secondary
      text="Test WebHook"
      (click)="testWebHook()" />
  </mf-row>
</mf-form>
