import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { Icons } from '../icon/icons';
import { HeaderMenuItem } from '../menu/header-menu-item';
import { LayoutService } from '../services/layout.service';
import { NavigationService } from '../services/navigation.service';

export class ToolBarQuickAccess {
  constructor(public label: string, public url: string, public selected?: boolean) {}
}

@Component({
  standalone: false,
  selector: 'mf-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
})
export class ToolBarComponent {
  constructor(private _layoutService: LayoutService, private _navigationService: NavigationService, private _router: Router) {}

  @Input()
  logoUrl: string = '';
  @Input()
  logoAlt: string = '';
  @Input()
  menuItems: HeaderMenuItem[] = [];
  @Input()
  quickAccessList: ToolBarQuickAccess[];
  @Input()
  quickAccessSelected: ToolBarQuickAccess;
  @Input()
  userName: string = '';
  @Input()
  clientName: string = '';
  @Input()
  showToggleSidenav: boolean = true;
  @Input()
  showNotifications: boolean = true;

  icons = Icons;

  ngOnInit() {
    this._router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)).subscribe((e) => {
      this.quickAccessList.forEach((qa) => (qa.selected = '/' + qa.url == e.url));
    });
  }

  toggleSidenav() {
    return this._layoutService.executeToggleSidenav();
  }

  quickAccessClick(quickAccess: ToolBarQuickAccess) {
    this._navigationService.navigate(quickAccess.url);
  }
}
