import { Component, Input } from '@angular/core';

@Component({
  selector: 'mf-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  standalone: false,
})
export class AlertComponent {
  @Input() showCloseButton: boolean;
  @Input() type: string = 'danger';

  closed: boolean;

  constructor() {}

  closeAlert() {
    this.closed = true;
  }
}
