// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { CollectOrderDto } from "../dtos/CollectOrderDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { CollectOrderFiltersDto } from "../dtos/CollectOrderFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminCollectOrder {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    collectOrderDto: CollectOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CollectOrderDto> {
    return this._httpApiClient.post("/client-admin/collect-order", collectOrderDto, httpApiRequestOptions);
  }

  delete(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/collect-order/delete/" + id, null, httpApiRequestOptions);
  }

  editDraft(
    collectOrderDto: CollectOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CollectOrderDto> {
    return this._httpApiClient.put("/client-admin/collect-order", collectOrderDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CollectOrderDto> {
    return this._httpApiClient.get("/client-admin/collect-order/" + id, httpApiRequestOptions);
  }

  getByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CollectOrderDto> {
    return this._httpApiClient.get("/client-admin/collect-order/get-by-external-id/" + externalId, httpApiRequestOptions);
  }

  getAllByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<CollectOrderDto[]> {
    return this._httpApiClient.get("/client-admin/collect-order/get-all-by-external-id/" + externalId, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<CollectOrderFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<CollectOrderDto>> {
    return this._httpApiClient.post("/client-admin/collect-order/paged", filters, httpApiRequestOptions);
  }

  suggest(
    collectOrderFilterDto: SuggestSearchDto<CollectOrderFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<CollectOrderDto>> {
    return this._httpApiClient.post("/client-admin/collect-order/suggest", collectOrderFilterDto, httpApiRequestOptions);
  }

}
