<mf-form
  [formGroup]="bankForm"
  [entity]="bankDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="country"
      [enumName]="enumName.Country"
      [enumNameModule]="enumNameModule" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="bankCode" />
  </mf-column>
  <mf-column>
    <mf-field-toggle formControlName="isDigitalWallet" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="swiftCode" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="swiftCountryCode" />
  </mf-column>
  <mf-column>
    <mf-field-numeric
      [allowNulls]="true"
      [intOnly]="true"
      formControlName="accountNumberMinLength" />
  </mf-column>
  <mf-column>
    <mf-field-numeric
      [allowNulls]="true"
      [intOnly]="true"
      formControlName="accountNumberMaxLength" />
  </mf-column>
  <mf-column>
    <mf-field-numeric
      [allowNulls]="true"
      [intOnly]="true"
      formControlName="accountPrefixBranchCodeLength" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="daMapId" />
  </mf-column>
</mf-form>
