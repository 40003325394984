import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { ApiClientAdminOperation } from '../../../api/endpoints/ApiClientAdminOperation';
import { OperationService } from './operation.service';

@Component({
  standalone: false,
  selector: 'mf-operation-edit',
  templateUrl: './operation-edit.component.html',
  styleUrls: ['./operation-edit.component.scss'],
})
export class OperationEditComponent {
  constructor(
    private apiClientAdminOperation: ApiClientAdminOperation,
    private _modalService: ModalService,
    private _operationService: OperationService
  ) {
    this.load();
  }

  isLoaded = false;

  async load() {
    this._operationService.operationDto = await this.apiClientAdminOperation.getById(this._modalService.openData.operationId);
    this.isLoaded = true;
  }

  async save(operationDto: OperationDto) {
    this._operationService.edit(operationDto);
    this._modalService.closeAsSuccess();
  }
}
