import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, signal } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FiltersDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { SearchOrderDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SearchOrderDto';
import { SuggestResultDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SuggestResultDto';
import { SuggestResultItemDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SuggestResultItemDto';
import { SuggestSearchDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { Icons } from '../../icon/icons';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  selector: 'mf-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent extends FieldMatBaseComponent<string> implements OnInit {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }
  override get controlType(): string {
    return 'field-complete';
  }

  private _echoId = 1;

  @Input()
  selectedId?: string = undefined;
  @Input()
  autoCompleteMaxItems = 15;
  @Input()
  filters: FiltersDto;
  @Input()
  orderBy: SearchOrderDto[];
  @Input()
  showAddButton: boolean = false;
  @Input()
  endpointSuggest: (
    suggestSearchDto: SuggestSearchDto<any>,
    httpApiRequestOptions: IHttpApiRequestOptions
  ) => Promise<SuggestResultDto<any>>;

  @Output()
  selectedItemChange = new EventEmitter<SuggestResultItemDto<any> | null>();

  @Output()
  createNewClick = new EventEmitter();

  delayTimeout: any;
  icons = Icons;
  isLoading = false;
  selectedItemMainText: string | null = null;
  result = signal<SuggestResultDto<any> | null>(null);

  ngOnInit(): void {
    this.selectedItemMainText = this.value;
    if (!this.filters) {
      this.filters = new FiltersDto();
    }
  }

  private suggest() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }

    this.isLoading = true;
    this.result.set(null);
    this.delayTimeout = setTimeout(async () => {
      this.filters.defaultTextSearch = this.selectedItemMainText != this.value ? this.value : '';
      this._echoId++;
      const result = await this.endpointSuggest(
        {
          echoId: this._echoId,
          maxItems: this.autoCompleteMaxItems,
          filters: this.filters,
          orderBy: this.orderBy,
          includedFields: [],
        },
        {
          preventSpinner: true,
          preventErrorHandler: false,
        }
      );

      if (this._echoId === result.echoId) {
        this.result.set(result);
        this.isLoading = false;
      }
    }, 500);
  }

  onChange() {
    if (this.selectedItemMainText === this.value) {
      return;
    }
    this.suggest();
  }

  onSelectedItemChange(item: SuggestResultItemDto<any>) {
    this.selectedItemMainText = item?.mainText;
    this.selectedItemChange.emit(item);
  }

  onFocus() {
    this.result.set(null);
  }

  onBlur() {
    if (this.selectedItemMainText && this.selectedItemMainText !== this.value) {
      this.selectedItemMainText = null;
      this.selectedItemChange.emit(null);
    }
    this.onTouched();
  }

  expandOptions() {
    this.suggest();
  }
}
