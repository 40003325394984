import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TransferDto } from '../../../../api/dtos/TransferDto';
import { ApiClientAdminTransfer } from '../../../../api/endpoints/ApiClientAdminTransfer';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';

@Component({
  standalone: false,
  selector: 'mf-transfer-details',
  templateUrl: './transfer-details.component.html',
  styleUrls: ['./transfer-details.component.scss'],
})
export class TransferDetailsComponent {
  constructor(private _apiClientAdminTransfer: ApiClientAdminTransfer, modalService: ModalService) {
    this.load(modalService.openData);
  }

  enumName = enumName_mi_pspc;
  transferDto: TransferDto;

  async load(id: string) {
    this.transferDto = await this._apiClientAdminTransfer.getById(id);
  }
}
