// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { ClientDto } from './ClientDto';
import { BankAccountDto } from './BankAccountDto';
import { OperationDto } from './OperationDto';
import { PaymentTransactionDto } from './PaymentTransactionDto';
import { PersonDto } from '../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { OrderStatus } from '../enums/OrderStatus';
import { OrderType } from '../enums/OrderType';

export class OrderDto extends BaseEntityDto {
  amount: number = 0;
  clientId: string | null = null;
  client: ClientDto;
  destinationBankAccountId: string | null = null;
  destinationBankAccount: BankAccountDto;
  externalId: string = '';
  isRefund: boolean = false;
  notifyUser: boolean = false;
  operationId: string = '';
  operation: OperationDto;
  orderNumber: number = 0;
  paymentDate: Date = new Date();
  paymentTransactionId: string | null = null;
  paymentTransaction: PaymentTransactionDto;
  personId: string | null = null;
  person: PersonDto;
  reference: string = '';
  sourceBankAccountId: string | null = null;
  sourceBankAccount: BankAccountDto;
  status: OrderStatus;
  type: OrderType;
}

export interface OrderDtoFormGroup extends BaseEntityDtoFormGroup {
  amount?: any;
  clientId?: any;
  client?: any;
  destinationBankAccountId?: any;
  destinationBankAccount?: any;
  externalId?: any;
  isRefund?: any;
  notifyUser?: any;
  operationId?: any;
  operation?: any;
  orderNumber?: any;
  paymentDate?: any;
  paymentTransactionId?: any;
  paymentTransaction?: any;
  personId?: any;
  person?: any;
  reference?: any;
  sourceBankAccountId?: any;
  sourceBankAccount?: any;
  status?: any;
  type?: any;
}
