// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../FiltersDto';

export class PersonFiltersDto extends FiltersDto {
  id: string | null = null;
  fullName: string = '';
  type: number | null = null;
  documentNumber: string = '';
  email: string = '';
}

export interface PersonFiltersDtoFormGroup extends FiltersDtoFormGroup {
  id?: any;
  fullName?: any;
  type?: any;
  documentNumber?: any;
  email?: any;
}
