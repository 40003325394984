import { Component } from '@angular/core';
import { LayoutService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/layout.service';

@Component({
  standalone: false,
  selector: 'mf-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(public layoutService: LayoutService) {}
}
