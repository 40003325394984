import { Component, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { Validators } from '@angular/forms';
import { ApiClientAdminOperation } from '../../../../api/endpoints/ApiClientAdminOperation';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { ApiClientAdminUploadFile } from '../../../../api/endpoints/ApiClientAdminUploadFile';
import { OperationImportRequestResultDto } from '../../../../api/dtos/OperationImportRequestResultDto';
import { OperationService } from '../operation.service';
import { NotificationService } from '../../../../../../../libs/utils/ng-shared-components/src';
import { OperationRequestResultDto } from '../../../../api/dtos/OperationRequestResultDto';
import { OrderImportRequestValidationDto } from '../../../../api/dtos/OrderImportRequestValidationDto';
import { OrderRequestValidationDto } from '../../../../api/dtos/OrderRequestValidationDto';
import { OrderRequestValidationStatus } from '../../../../api/enums/OrderRequestValidationStatus';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { nameof } from 'ts-simple-nameof';
import { IdDocumentType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/IdDocumentType';
import { RowClass } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { HttpApiClient } from '../../../../api/endpoints/HttpApiClient';

@Component({
  standalone: false,
  selector: 'mf-operation-import',
  templateUrl: './operation-import.component.html',
  styleUrl: './operation-import.component.scss',
})
export class OperationImportComponent {
  constructor(
    private _cfb: CustomFormBuilder,
    private _modalService: ModalService,
    private _apiClientAdminOperation: ApiClientAdminOperation,
    private _apiClientAdminUploadFile: ApiClientAdminUploadFile,
    private _operationService: OperationService,
    private _notificationService: NotificationService,
    private _httpApiClient: HttpApiClient
  ) {}

  operationImportRequestResult?: OperationImportRequestResultDto;
  operationRequestResult?: OperationRequestResultDto;
  paymentOrdersWithError: OrderImportRequestValidationDto[] = [];
  collectOrdersWithError: OrderImportRequestValidationDto[] = [];

  @Output() save = new EventEmitter<string>();

  filename: string;
  omitWarnings: boolean;
  validationStatus: OrderRequestValidationStatus;

  OrderRequestValidationStatus = OrderRequestValidationStatus;

  operationForm = this._cfb.group({
    importUrl: [null, Validators.required, 'Archivo de Importación'],
  });

  enumName = enumName_mi_pspc;

  IdDocumentType = IdDocumentType;

  columns: IGridColumn<OrderImportRequestValidationDto>[] = [
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.excelRow),
      header: 'Fila',
      width: 7,
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.person.idDocumentType),
      header: 'Documento',
      width: 20,
      render: (c) => {
        let person = c.person?.idDocumentType ? IdDocumentType[c.person?.idDocumentType] : '';
        return `${person} ${c.person?.idDocumentNumber}`;
      },
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.person.firstName),
      header: 'Persona',
      width: 20,
      render: (c) => {
        return c.person?.commercialName ?? `${c.person?.firstName ?? ''} ${c.person?.lastName ?? ''}`;
      },
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.bankAccount.bankName),
      header: 'Banco',
      width: 14,
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.bankAccount.bankBranchCode),
      header: 'Sucursal',
      width: 14,
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.bankAccount.accountNumber),
      header: 'Nro. Cta',
      width: 20,
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.amount),
      header: 'Monto',
      cellOptions: {
        type: CellValueType.Numeric,
      },
      width: 17,
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.details),
      header: 'Detalle',
      width: 50,
    },
    {
      field: nameof<OrderImportRequestValidationDto>((c) => c.validationStatus),
      header: '',
      width: 5,
      render: (c) => {
        let iconName = '';
        let cellClass = '';

        switch (c.validationStatus) {
          case OrderRequestValidationStatus.OK:
            iconName = 'done';
            cellClass = 'row-success';
            break;
          case OrderRequestValidationStatus.Error:
            iconName = 'cancel';
            break;
          case OrderRequestValidationStatus.Warning:
            iconName = 'warning_amber';
            break;
        }

        return `<i class="material-icons ${cellClass}">${iconName}</i>`;
      },
    },
  ];

  downloadTemplate(event: any) {
    event.preventDefault();
    this._httpApiClient.downloadFile('/client-admin/operation/import-template', 'application/vnd.ms-excel', 'import-template.xlsx');
  }

  getRowClass(row: any) {
    switch (row.item.validationStatus as OrderRequestValidationStatus) {
      case OrderRequestValidationStatus.OK:
        return null;
      case OrderRequestValidationStatus.Error:
        return RowClass.Error;
      case OrderRequestValidationStatus.Warning:
        return RowClass.Warning;
    }
  }

  closeModal() {
    this._modalService.closeAsSuccess('');
  }

  async upload(filename: string) {
    this.filename = filename;
    this.resetFields();
    this.paymentOrdersWithError = [];
    this.collectOrdersWithError = [];
    this.operationRequestResult = undefined;
    this.operationImportRequestResult = await this._apiClientAdminOperation.validateUploadedFile(filename);
    this.validationStatus = this.operationImportRequestResult.validationStatus;
  }

  async uploadOperation() {
    this.operationRequestResult = await this._operationService.createDraftFromFile(this.filename);
    if (this.operationRequestResult.isSuccess) {
      this._notificationService.showSuccess('Se creó la operación en proceso de revisión');
      this.closeModal();
    } else {
      this.paymentOrdersWithError = this.extractErrorDetails(this.operationRequestResult.paymentOrderValidation, 'payment');
      this.collectOrdersWithError = this.extractErrorDetails(this.operationRequestResult.collectOrderValidation, 'collect');
      this.resetFields();
      this._notificationService.showError('Ha ocurrido un error al crear la operación desde el Archivo de Importación');
    }
  }

  private extractErrorDetails(validationResults: OrderRequestValidationDto[], validationType: string): OrderImportRequestValidationDto[] {
    return validationResults
      .map((req, index) => {
        if (!req.isSuccess) {
          const validation =
            validationType === 'payment'
              ? this.operationImportRequestResult?.paymentOrderValidation[index]
              : this.operationImportRequestResult?.collectOrderValidation[index];
          return validation ? { ...validation, validationStatus: req.status, details: req.details } : null;
        }
        return null;
      })
      .filter((item) => item !== null);
  }

  resetFields() {
    this.operationImportRequestResult = undefined;
    this.omitWarnings = false;
  }

  isUploadDisabled(): boolean {
    return this.validationStatus === OrderRequestValidationStatus.Error
      ? true
      : this.validationStatus === OrderRequestValidationStatus.Warning
      ? !this.omitWarnings
      : this.omitWarnings;
  }

  endpoint = (fileList: FileList, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminUploadFile.uploadOperationImportFile(fileList, httpApiRequestOptions);
}
