@if (loaded()) {
<mf-grid
  #grid
  actionButtonText="{{ showCreateButton ? 'Nuevo incidente' : '' }}"
  [hideFilters]="hideFilters"
  [initialFilters]="filters"
  [globalFilters]="globalFilters"
  [apiEndpoint]="apiEndpoint"
  [columns]="columns"
  [menuItems]="menuItems"
  [mustIncludeFields]="mustIncludeFields"
  (actionButtonClick)="openNewIssuePopup()"
  [rowSelection]="rowSelection"
  (selectedItems)="selectItems($event)"
  [clientSideItems]="items" />
}
