import { Component, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { EnumTranslateProviderService } from '../../services/enum-translate-provider.service';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

export class SelectOption {
  constructor(public value: number | null, public description: string) {}
}

@Component({
  standalone: false,
  selector: 'mf-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
})
export class FieldSelectComponent extends FieldMatBaseComponent<number | null> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _enumTranslateProviderService: EnumTranslateProviderService
  ) {
    super(ngControl, elRef);
  }
  @Input()
  optionsList: any[] = [];
  override get controlType(): string {
    return 'field-select';
  }

  @Input() options: SelectOption[] = [];
  @Input() availableValues: number[];

  @Input() enumName: string = '';
  @Input() enumNameModule?: string = '';

  @Input() hideEmptyOption: boolean = false;
  @Input() showEmptySelectionAsHint: boolean = false;
  @Input() emptyOptionLabel: string = 'Seleccione...';

  ngOnInit(): void {
    this.getEnumOptions();
  }

  ngOnChanges() {
    if (this.availableValues && this.options.length != this.availableValues.length) {
      this.getEnumOptions();
    }
  }

  getEnumOptions() {
    if (this.enumName) {
      this.options = this._enumTranslateProviderService.getEnumOptions(this.enumName, this.enumNameModule);
      if (this.availableValues) {
        this.options = this.options.filter((option) => this.availableValues.find((c) => c == option.value) != null);
      }
    }
  }
}
