import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminNaturalPerson } from '../../../../api/endpoints/ApiClientAdminNaturalPerson';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { NaturalPersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';
import { AddressDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/AddressDto';

@Component({
  standalone: false,
  selector: 'mf-natural-person-edit',
  templateUrl: './natural-person-edit.component.html',
  styleUrls: ['./natural-person-edit.component.scss'],
})
export class NaturalPersonEditComponent {
  constructor(private _apiClientAdminNaturalPerson: ApiClientAdminNaturalPerson, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  naturalPersonDto: NaturalPersonDto;

  async load(id: string) {
    this.naturalPersonDto = await this._apiClientAdminNaturalPerson.getById(id);
    if (this.naturalPersonDto.address == null) {
      this.naturalPersonDto.address = new AddressDto();
      this.naturalPersonDto.addressId = GUID_EMPTY;
      this.naturalPersonDto.address.id = GUID_EMPTY;
    }
  }

  async save(naturalPerson: NaturalPersonDto) {
    if (naturalPerson.address.addressLine == '') {
      naturalPerson.addressId = null;
    }
    await this._apiClientAdminNaturalPerson.edit(naturalPerson);
    this._modalService.closeAsSuccess('La persona se editó con éxito');
  }
}
