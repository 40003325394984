import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankMovementDto } from '../../../../api/dtos/BankMovementDto';
import { ApiAdminBankMovement } from '../../../../api/endpoints/ApiAdminBankMovement';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';

@Component({
  standalone: false,
  selector: 'mf-bank-movement-details',
  templateUrl: './bank-movement-details.component.html',
  styleUrls: ['./bank-movement-details.component.scss'],
})
export class BankMovementDetailsComponent {
  constructor(private _apiAdminBankMovement: ApiAdminBankMovement, modalService: ModalService) {
    this.load(modalService.openData);
  }

  enumName = enumName_mi_pspc;
  bankMovementDto: BankMovementDto;

  async load(id: string) {
    this.bankMovementDto = await this._apiAdminBankMovement.getById(id);
  }
}
