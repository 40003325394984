import { Component, Inject, Input } from '@angular/core';
import { Icons } from '../../icon/icons';
import { INotification, INotificationTrayService } from '../INotificationTray';
import { EntityType } from '../../../../../../../apps/mi-pspc/src/api/enums/EntityType';

@Component({
  standalone: false,
  selector: 'mf-notification-menu-item',
  templateUrl: './notification-menu-item.component.html',
  styleUrls: ['./notification-menu-item.component.scss'],
})
export class NotificationMenuItemComponent {
  constructor(@Inject('INotificationTrayService') public notificationTrayService: INotificationTrayService) {}

  @Input()
  notification: INotification;
  icons = Icons;

  toggleRead(isRead: boolean): void {
    if (!isRead) {
      this.notificationTrayService.setAsRead(this.notification.id);
    }
  }

  downloadFile(): void {
    this.notificationTrayService.downloadFile(this.notification.id);
    this.toggleRead(this.notification.isRead);
  }

  navigateToRelatedEntityPage(): void {
    this.notificationTrayService.navigateToRelatedEntityPage(this.notification.id);
  }

  async handleNotificationClick(notification: INotification): Promise<void> {
    const navigationHandler = this.getNavigationHandler(notification);
    if (navigationHandler) {
      try {
        await navigationHandler();
      } catch (error) {
        console.error('Error al manejar la navegación de la notificación:', error);
      }
    }
  }

  shouldHaveButton(notification: INotification): boolean {
    return !!this.getNavigationHandler(notification);
  }

  private getNavigationHandler(notification: INotification): (() => Promise<void>) | null {
    if (notification.relatedEntityType == EntityType.Accreditation && notification.relatedEntityId) {
      return async () => {
        await this.notificationTrayService.navigateToAccreditations(notification.relatedEntityId!);
      };
    }

    return null;
  }
}
