export class HeaderMenuItem {
  icon: string;
  label: string;

  isToggle?: boolean;
  toggleValue?: boolean;

  hide?: (item: any) => boolean;
  disabled?: (item: any) => string | null;
  tooltip?: (item: any) => string;
  onClick: () => void;
}
