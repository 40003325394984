import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TransferDto } from '../../../../api/dtos/TransferDto';
import { ApiClientAdminTransfer } from '../../../../api/endpoints/ApiClientAdminTransfer';

@Component({
  selector: 'mf-transfer-details',
  templateUrl: './transfer-details.component.html',
  styleUrls: ['./transfer-details.component.scss'],
})
export class TransferDetailsComponent {
  constructor(private _apiClientAdminTransfer: ApiClientAdminTransfer, private _modalService: ModalService) {
    this.accreditationsByAccountId = this._modalService.openData.accreditationsByAccountId;
  }
  accreditationsByAccountId: string;

  transferDto: TransferDto;
}
