import { BehaviorSubject } from 'rxjs';
import { FilterType } from '../../interface/filter-type';
import { IGridFilter } from '../../interface/grid-filter';
import { FiltersDto } from '../../../../../../ts-shared/src/lib/api/searching/dtos/FiltersDto';

export class GridFilterService {
  constructor() {}

  gridFilters: IGridFilter[];
  filters: BehaviorSubject<FiltersDto>;
  initialFilters: FiltersDto;
  editingFilters: any;

  getFilterAppliedValue(gridFilter: IGridFilter) {
    return (this.filters.value as any)[gridFilter.propertyName];
  }

  filterHasValue(gridFilter: IGridFilter): boolean {
    let filterValue = (this.filters.value as any)[gridFilter.propertyName];
    if (!filterValue) {
      return false;
    }

    if (gridFilter.type === FilterType.Date) {
      return filterValue && filterValue.type;
    }

    if (gridFilter.type === FilterType.Number) {
      return !!filterValue;
    }

    if (gridFilter.type === FilterType.NumericRange) {
      return filterValue.min !== null || filterValue.max !== null;
    }

    if (gridFilter.type === FilterType.EnumList) {
      return !!filterValue && filterValue.length > 0;
    }

    return !!filterValue;
  }

  get filtersWithAppliedValues(): IGridFilter[] {
    return this.gridFilters.filter((gridFilter) => {
      return this.filterHasValue(gridFilter);
    });
  }

  applyFilters() {
    const filters = JSON.parse(JSON.stringify(this.editingFilters));
    this.filters.next(filters);
  }

  clearFilter(gridFilter: IGridFilter, arrayItem?: number) {
    if (arrayItem !== undefined) {
      let listValue = (this.editingFilters as any)[gridFilter.propertyName] as any[];
      listValue.splice(listValue.indexOf(arrayItem), 1);
      (this.editingFilters as any)[gridFilter.propertyName] = listValue;
      if (listValue.length > 0) {
        this.applyFilters();
        return;
      }
    }

    (this.editingFilters as any)[gridFilter.propertyName] = null;
    if (gridFilter.type === FilterType.Entity) {
      (this.editingFilters as any)[gridFilter.propertyName + 'Id'] = null;
    }
    this.applyFilters();
  }

  clearFiltersAll() {
    this.editingFilters = { ...this.initialFilters };
    this.applyFilters();
  }

  init(gridFilters: IGridFilter[], initialFilters: FiltersDto) {
    this.gridFilters = gridFilters;
    this.initialFilters = initialFilters;
    this.editingFilters = { ...initialFilters };
    this.filters = new BehaviorSubject<FiltersDto>(initialFilters);
  }
}
