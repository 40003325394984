<mf-page title="Acreditaciones">
  <mf-row>
    <mf-grid
      #grid
      [initialFilters]="filters"
      [apiEndpoint]="apiEndpoint"
      [actionButtons]="gridActionButtons"
      [columns]="columns"
      [menuItems]="menuItems" />
  </mf-row>
</mf-page>
