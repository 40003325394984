<mf-form
  [formGroup]="paymentOrderForm"
  [entity]="paymentOrderDto"
  (save)="save.emit($event)">
  <mf-column-display label="Cliente">
    {{ paymentOrderDto.operation.client.name }}
  </mf-column-display>

  <mf-column-display label="Motivo de la Operación">
    {{ paymentOrderDto.operation.reason }}
  </mf-column-display>

  <mf-column-display label="Monto">
    {{ paymentOrderDto.operation.currency | customCurrency }} {{ paymentOrderDto.operation.amount }}
  </mf-column-display>

  <mf-column [md]="4">
    <mf-field-text formControlName="externalId" />
  </mf-column>

  <mf-column [md]="4">
    <mf-autocomplete-person
      formControlName="person"
      (itemIdChanged)="onPersonSelected($event)" />
  </mf-column>

  <mf-column [md]="4">
    <mf-field-numeric formControlName="amount" />
  </mf-column>

  <mf-row>
    <mf-autocomplete-bank-account
      formControlName="destinationBankAccount"
      [personId]="personId"
      [currency]="paymentOrderDto.operation.currency"
      (itemIdChanged)="paymentOrderForm.patchValue({ destinationBankAccountId: $event })" />
  </mf-row>
</mf-form>
