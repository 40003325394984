// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { OperationDto } from "../dtos/OperationDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { OperationFiltersDto } from "../dtos/OperationFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiOperation {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OperationDto> {
    return this._httpApiClient.get("/operation/" + id, httpApiRequestOptions);
  }

  createDraft(
    operationDto: OperationDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OperationDto> {
    return this._httpApiClient.post("/operation", operationDto, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<OperationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<OperationDto>> {
    return this._httpApiClient.post("/operation/paged", filters, httpApiRequestOptions);
  }

}
