// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { EmailSentDto } from "../../../../../libs/utils/ng-shared-components/src/lib/emails/dtos/EmailSentDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { EmailSentFiltersDto } from "../../../../../libs/utils/ng-shared-components/src/lib/emails/dtos/EmailSentFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiEmailSent {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getPaged(
    filters: PagedSearchDto<EmailSentFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<EmailSentDto>> {
    return this._httpApiClient.post("/shared/emails/sent-email/paged", filters, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<EmailSentDto> {
    return this._httpApiClient.get("/shared/emails/sent-email/" + id, httpApiRequestOptions);
  }

}
