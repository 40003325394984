import { Component, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getValue } from '../../../../ts-shared/src';
import { FiltersDto } from '../../../../ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { PagedResultDto } from '../../../../ts-shared/src/lib/api/searching/dtos/PagedResultDto';
import { PagedResultItemDto } from '../../../../ts-shared/src/lib/api/searching/dtos/PagedResultItemDto';
import { PagedSearchDto } from '../../../../ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { IHttpApiRequestOptions } from '../IHttpApiRequestOptions';
import { Icons } from '../icon/icons';
import { DateUtcPipe } from '../pipes/date-utc.pipe';
import { EnumTranslateProviderService } from '../services/enum-translate-provider.service';
import { ModalService } from '../services/modal.service';
import { GridFilterService } from './grid-filter/service/grid-filter.service';
import { GridFiltersPopupComponent } from './grid-filters-popup/grid-filters-popup.component';
import { GridMenuItem } from './grid-menu/grid-menu-item';
import { CellValueType, IGridColumn } from './interface/gird-column';
import { IGridFilter } from './interface/grid-filter';

interface IGridInternalColumn {
  column: IGridColumn<any>;
  calculatedWith: number;
}

export interface IGridActionButton {
  text: string;
  icon?: string;
  isSecondary?: boolean;
  onClick: () => void;
}

@Component({
  selector: 'mf-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [GridFilterService],
})
export class GridComponent implements OnInit {
  constructor(
    public gridFilterService: GridFilterService,
    private _enumTranslateProviderService: EnumTranslateProviderService,
    private _modalService: ModalService,
    private _sanitizer: DomSanitizer,
    private _dateUctPipe: DateUtcPipe
  ) {
    this.searchDto = new PagedSearchDto<any>();
    this.searchDto.pageSize = 25;
    this.searchDto.filters = {};
  }

  @Input()
  actionButtonText: string;
  @Input()
  actionButtons: IGridActionButton[];
  @Input()
  disableActionButton = false;
  @Input()
  hideFilters = false;
  @Input()
  rowSelection = false;
  @Output()
  actionButtonClick = new EventEmitter();
  @Output()
  selectedItems = new EventEmitter();

  @Input()
  apiEndpoint: (filters: PagedSearchDto<any>, httpParamsOptions: IHttpApiRequestOptions) => Promise<PagedResultDto<any>>;
  @Input()
  columns: IGridColumn<any>[];
  @Input()
  mustIncludeFields!: string[];
  @Input()
  initialFilters: FiltersDto;
  @Input()
  menuItems: GridMenuItem[];

  @ViewChild('gridRef', { static: false })
  gridReference: any;

  page = signal<PagedResultDto<any> | null>(null);
  searchDto: PagedSearchDto<any>;
  isLoading = signal(false);
  errorMessage!: string;
  cellValueType = CellValueType;
  internalColumns = signal<IGridInternalColumn[]>([]);
  icons = Icons;

  private calculateColumnsWith() {
    if (!this.gridReference || !this.gridReference.nativeElement) {
      return;
    }

    let gridWith = this.gridReference.nativeElement.getBoundingClientRect().width;

    if (this.menuItems) {
      gridWith -= 48;
    }

    const totalColumnsWith = this.columns.reduce((a, b) => a + (b.width ?? 100), 0);
    this.internalColumns.set(
      this.columns.map((c) => {
        return {
          column: c,
          calculatedWith: ((c.width ?? 100) / totalColumnsWith) * gridWith,
        };
      })
    );
  }

  ngOnInit() {
    const gridFilters = this.columns
      .filter((c) => !!c.filter)
      .map((c) => {
        if (c.filter && !c.filter.header) {
          c.filter.header = c.header;
        }
        return c.filter;
      }) as IGridFilter[];
    this.gridFilterService.init(gridFilters, this.initialFilters ? this.initialFilters : new FiltersDto());

    this.searchDto.includedFields = this.columns.map((c) => c.field);

    if (this.mustIncludeFields) {
      this.searchDto.includedFields = this.searchDto.includedFields.concat(this.mustIncludeFields);
    }
    this.searchDto.includedFields = Array.from(new Set(this.searchDto.includedFields));

    this.gridFilterService.filters.subscribe((filters) => {
      this.searchDto.filters = filters;
      this.reloadPage();
    });

    this.calculateColumnsWith();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.calculateColumnsWith();
    }, 100);
  }

  async reloadPage() {
    this.isLoading.set(true);
    this.errorMessage = '';

    try {
      this.page.set(await this.apiEndpoint(this.searchDto, { preventSpinner: true, preventErrorHandler: true }));
    } catch (error: any) {
      this.errorMessage = error.message;
    }
    this.isLoading.set(false);
  }

  sortBy(column: IGridColumn<any>) {
    const ascendent = this.isSortingByAsc(column) ? false : true;
    this.searchDto.orderBy = [{ columnName: column.orderBy ?? column.field, ascendent: ascendent }];
    this.reloadPage();
  }

  isSortingBy(column: IGridColumn<any>) {
    const orderBy = this.searchDto.orderBy.find((c) => c.columnName == (column.orderBy ?? column.field));
    return orderBy != null;
  }

  isSortingByAsc(column: IGridColumn<any>) {
    const orderBy = this.searchDto.orderBy.find((c) => c.columnName == (column.orderBy ?? column.field));
    return orderBy != null ? orderBy.ascendent : false;
  }

  renderColumnValue(row: PagedResultItemDto<any>, column: IGridColumn<any>) {
    var value = getValue(row.item, column.field);

    if (column.render) {
      return this._sanitizer.bypassSecurityTrustHtml(column.render(row.item));
    }

    if (column.cellOptions?.type == CellValueType.Numeric) {
      const formatter = new Intl.NumberFormat('es-UY', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    }

    if (column.cellOptions?.type == CellValueType.Date) {
      return this._dateUctPipe.transform(
        value,
        column.cellOptions?.dateIncludeMilliseconds || column.cellOptions?.dateIncludeTime,
        column.cellOptions?.dateIncludeMilliseconds
      );
    }

    if (column.cellOptions?.type == CellValueType.Enum) {
      if (!column.cellOptions.enumName) {
        throw Error('enumName is required for enum type column');
      }
      return this._enumTranslateProviderService.translateEnum(column.cellOptions.enumName, value, column.cellOptions.enumNameModule);
    }

    if (column.cellOptions?.type == CellValueType.Boolean) {
      return value ? 'Sí' : 'No';
    }

    return value;
  }

  toggleFiltersPopup() {
    this._modalService.openRightModalSmall(GridFiltersPopupComponent, { openData: { gridFiltersService: this.gridFilterService } });
  }

  refresh() {
    this.reloadPage();
  }

  selectItem(item: PagedResultItemDto<any>) {
    this.page()?.pageItemList.forEach((pageItem) => {
      if (pageItem.item.id == item.item.id) {
        pageItem.selected = !pageItem.selected;
      }
    });
    var items = this.page()
      ?.pageItemList.filter((el) => el.selected)
      .map((el) => el.item);
    this.selectedItems.emit(items);
  }

  onActionButtonClick(eventCallback: any) {
    eventCallback();
  }
}
