import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminCompany } from '../../../../api/endpoints/ApiClientAdminCompany';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { AddressDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/AddressDto';
import { CompanyDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';
import { IdDocumentDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/IdDocumentDto';

@Component({
  selector: 'mf-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss'],
})
export class CompanyCreateComponent {
  constructor(private _apiClientAdminCompany: ApiClientAdminCompany, private _modalService: ModalService) {
    this.companyDto = new CompanyDto();
    this.companyDto.id = GUID_EMPTY;
    this.companyDto.address = new AddressDto();
    this.companyDto.addressId = GUID_EMPTY;
    this.companyDto.address.id = GUID_EMPTY;
    this.companyDto.idDocument = new IdDocumentDto();
    this.companyDto.idDocumentId = GUID_EMPTY;
    this.companyDto.idDocument.id = GUID_EMPTY;
  }

  companyDto: CompanyDto;

  async save(companyDto: CompanyDto) {
    await this._apiClientAdminCompany.create(companyDto);
    this._modalService.closeAsSuccess('La empresa se creó con éxito');
  }
}
