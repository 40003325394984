import { Component, ElementRef, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PersonDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { PersonType } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { PersonFiltersDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/PersonFiltersDto';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { ApiClientAdminPerson } from '../../../api/endpoints/ApiClientAdminPerson';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { CompanyCreateComponent } from '../../pages/person/company/company-create.component';
import { NaturalPersonCreateComponent } from '../../pages/person/natural-person/natural-person-create.component';
import { PersonCreateComponent } from '../../pages/person/person-create.component';

@Component({
  standalone: false,
  selector: 'mf-autocomplete-person',
  templateUrl: './autocomplete-person.component.html',
  styleUrls: ['./autocomplete-person.component.scss'],
})
export class AutocompletePersonComponent extends AutoCompleteBaseEntityComponent<PersonDto | null, PersonFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    modalService: ModalService,
    private _apiClientAdminPerson: ApiClientAdminPerson
  ) {
    super(ngControl, elRef, modalService);
    this.createNewComponent = PersonCreateComponent;
  }

  override defaultOrderBy = nameof<PersonDto>((p) => p.fullName);

  @Input()
  personType?: PersonType;

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.personType == PersonType.Company) {
      this.createNewComponent = CompanyCreateComponent;
    } else if (this.personType == PersonType.NaturalPerson) {
      this.createNewComponent = NaturalPersonCreateComponent;
    }
  }

  override setDefaultFilters() {
    this.filters.type = this.personType ?? null;
  }

  override endpointSuggest = (search: SuggestSearchDto<PersonFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) => {
    return this._apiClientAdminPerson.suggest(search, httpApiRequestOptions);
  };
}
