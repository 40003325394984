import { AutoCompleteComponent } from './fields/auto-complete/auto-complete.component';
import { AutocompleteTemplateTypeComponent } from './templates/autocomplete-template-type.component';
import { ButtonGoogleComponent } from './buttons/button-google.component';
import { ButtonIconComponent } from './buttons/button-icon.component';
import { ButtonSecondaryComponent } from './buttons/button-secondary.component';
import { ButtonComponent } from './buttons/button.component';
import { CodeEditorComponent } from './code-editor/code-editor.component';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomCurrencyPipe } from './pipes/currency/currency.pipe';
import { CustomFormBuilder } from './validation/form-builder/custom-form-builder';
import { DateProviderService } from './services/date-provider-service';
import { DateUtcPipe } from './pipes/date-utc.pipe';
import { EnumTranslateProviderService } from './services/enum-translate-provider.service';
import { FieldCheckComponent } from './fields/field-check/field-check.component';
import { FieldChipComponent } from './fields/field-chip/field-chip.component';
import { FieldDateRangeComponent } from './fields/field-date-range/field-date-range.component';
import { FieldDateComponent } from './fields/field-date/field-date.component';
import { FieldFileUploadComponent } from './fields/field-file-upload/field-file-upload.component';
import { FieldNumericComponent } from './fields/field-numeric/field-numeric.component';
import { FieldPasswordComponent } from './fields/field-password/field-password.component';
import { FieldSelectMultipleComponent } from './fields/field-select-multiple/field-select-multiple.component';
import { FieldSelectComponent } from './fields/field-select/field-select.component';
import { FieldTextComponent } from './fields/field-text/field-text.component';
import { FieldToggleComponent } from './fields/field-toggle/field-toggle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalSpinnerComponent } from './global-spinner/global-spinner.component';
import { GridFilterDateComponent } from './grid/grid-filter/grid-filter-date/grid-filter-date.component';
import { GridFilterNumericRangeComponent } from './grid/grid-filter/grid-filter-numeric-range/grid-filter-numeric-range.component';
import { GridFilterNumericComponent } from './grid/grid-filter/grid-filter-numeric/grid-filter-numeric.component';
import { GridFilterSelectMultipleComponent } from './grid/grid-filter/grid-filter-select-multiple/grid-filter-select-multiple.component';
import { GridFilterSelectComponent } from './grid/grid-filter/grid-filter-select/grid-filter-select.component';
import { GridFilterTagComponent } from './grid/grid-filter/grid-filter-tags/grid-filter-tag/grid-filter-tag.component';
import { GridFilterTagsComponent } from './grid/grid-filter/grid-filter-tags/grid-filter-tags.component';
import { GridFilterTextComponent } from './grid/grid-filter/grid-filter-text/grid-filter-text.component';
import { GridFilterComponent } from './grid/grid-filter/grid-filter.component';
import { GridFiltersPopupComponent } from './grid/grid-filters-popup/grid-filters-popup.component';
import { GridMenuComponent } from './grid/grid-menu/grid-menu.component';
import { GridPaginatorComponent } from './grid/grid-paginator/grid-paginator.component';
import { GridComponent } from './grid/grid.component';
import { IconComponent } from './icon/icon.component';
import { IdDocumentTypePipe } from './pipes/id-document-type/id-document-type.pipe';
import { LayoutColumnDisplayComponent } from './layout/column-display.component';
import { LayoutColumnComponent } from './layout/column.component';
import { LayoutContainerComponent } from './layout/container.component';
import { LayoutFormComponent } from './layout/form.component';
import { LayoutPageComponent } from './layout/page.component';
import { LayoutRowButtonsComponent } from './layout/row-buttons.component';
import { LayoutRowSeparatorComponent } from './layout/row-separator.component';
import { LayoutRowComponent } from './layout/row.component';
import { LayoutSectionComponent } from './layout/section.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LinkComponent } from './link/link.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MenuGroupComponent } from './left-menu/menu-group/menu-group.component';
import { MenuItemComponent } from './left-menu/menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { ModalService } from './services/modal.service';
import { NavigationService } from './services/navigation.service';
import { NgModule } from '@angular/core';
import { NotificationAreaComponent } from './notification/notification-area/notification-area.component';
import { NotificationItemComponent } from './notification/notification-item/notification-item.component';
import { NotificationMenuItemComponent } from './notification-tray/notification-menu-item/notification-menu-item.component';
import { NotificationTrayComponent } from './notification-tray/notification-tray/notification-tray.component';
import { NotificationService } from './notification/notification.service';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SpinnerComponent } from './spinner/spinner.component';
import { TemplateComponent } from './templates/template.component';
import { TemplateCreateComponent } from './templates/template-create.component';
import { TemplateEditorComponent } from './templates/template-editor.component';
import { TemplateEditorFieldsComponent } from './templates/fields/template-editor-fields.component';
import { TemplateEditorPreviewComponent } from './templates/template-editor-preview.component';
import { TemplateHeaderInfoComponent } from './templates/template-header-info.component';
import { TemplateHeaderInfoPdfComponent } from './templates/template-header-info-pdf.component';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { ValidationSummaryComponent } from './validation/validation-summary.component';
import { YesOrNoModalComponent } from './modals/yes-or-no-modal.component';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-scss';
import { TemplateEditorFieldComponent } from './templates/fields/template-editor-field.component';
import { AccreditationsByAccountTypePipe } from './pipes/accreditationsByAccountType/accreditationsByAccountType.pipe';
import { IdDocumentComponent } from './id-document/id-document.component';
import { AddressComponent } from './address/address.component';
import { AutocompleteCompanyComponent } from './fields/auto-complete/autocomplete-company.component';
import { AutocompletePersonComponent } from './fields/auto-complete/autocomplete-person.component';
import { TemplateGridComponent } from './templates/pages/template-grid.component';
import { TemplatePdfCreateComponent } from './templates/pages/pdf/template-pdf-create.component';
import { TemplatePdfEditComponent } from './templates/pages/pdf/template-pdf-edit.component';
import { TemplateGenericCreateComponent } from './templates/pages/generic/template-generic-create.component';
import { TemplateGenericEditComponent } from './templates/pages/generic/template-generic-edit.component';
import { TemplateEmailCreateComponent } from './templates/pages/email/template-email-create.component';
import { TemplateEmailEditComponent } from './templates/pages/email/template-email-edit.component';
import { LoggingGridComponent } from './logging/pages/logging-grid.component';
import { LoggingDetailsComponent } from './logging/pages/logging-details.component';
import { LoggingConfigComponent } from './logging/pages/logging-config.component';
import { TemplateHeaderInfoEmailComponent } from './templates/template-header-info-email.component';
import { EmailSentGridComponent } from './emails/pages/email-sent-grid.component';
import { EmailSentDetailComponent } from './emails/pages/email-sent-detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AccreditationsByAccountTypePipe,
    AddressComponent,
    AutoCompleteComponent,
    AutocompleteCompanyComponent,
    AutocompletePersonComponent,
    AutocompleteTemplateTypeComponent,
    ButtonComponent,
    ButtonGoogleComponent,
    ButtonIconComponent,
    ButtonSecondaryComponent,
    CodeEditorComponent,
    CustomCurrencyPipe,
    DateUtcPipe,
    EmailSentGridComponent,
    EmailSentDetailComponent,
    FieldCheckComponent,
    FieldChipComponent,
    FieldDateComponent,
    FieldDateRangeComponent,
    FieldFileUploadComponent,
    FieldNumericComponent,
    FieldPasswordComponent,
    FieldSelectComponent,
    FieldSelectMultipleComponent,
    FieldTextComponent,
    FieldToggleComponent,
    GlobalSpinnerComponent,
    GridComponent,
    GridPaginatorComponent,
    GridMenuComponent,
    GridFilterComponent,
    GridFilterNumericComponent,
    GridFilterNumericRangeComponent,
    GridFilterDateComponent,
    GridFilterTextComponent,
    GridFilterSelectComponent,
    GridFilterSelectMultipleComponent,
    GridFiltersPopupComponent,
    GridFilterTagComponent,
    GridFilterTagsComponent,
    IconComponent,
    IdDocumentComponent,
    IdDocumentTypePipe,
    LayoutColumnComponent,
    LayoutColumnDisplayComponent,
    LayoutContainerComponent,
    LayoutFormComponent,
    LayoutPageComponent,
    LayoutRowComponent,
    LayoutRowButtonsComponent,
    LayoutRowSeparatorComponent,
    LayoutSectionComponent,
    LeftMenuComponent,
    LinkComponent,
    LoggingGridComponent,
    LoggingDetailsComponent,
    LoggingConfigComponent,
    MenuComponent,
    MenuGroupComponent,
    MenuItemComponent,
    NotificationAreaComponent,
    NotificationItemComponent,
    NotificationMenuItemComponent,
    NotificationTrayComponent,
    RightSidebarComponent,
    SafeResourceUrlPipe,
    SafeHtmlPipe,
    SpinnerComponent,
    TemplateComponent,
    TemplateHeaderInfoComponent,
    TemplateHeaderInfoPdfComponent,
    TemplateHeaderInfoEmailComponent,
    TemplateCreateComponent,
    TemplateEditorComponent,
    TemplateEditorFieldComponent,
    TemplateEditorFieldsComponent,
    TemplateEditorPreviewComponent,
    TemplateEmailCreateComponent,
    TemplateEmailEditComponent,
    TemplateGenericCreateComponent,
    TemplateGenericEditComponent,
    TemplatePdfCreateComponent,
    TemplatePdfEditComponent,
    TemplateGridComponent,
    TranslatePipe,
    ToolBarComponent,
    ValidationSummaryComponent,
    YesOrNoModalComponent,
  ],
  exports: [
    AccreditationsByAccountTypePipe,
    AddressComponent,
    AutoCompleteComponent,
    AutocompleteCompanyComponent,
    AutocompletePersonComponent,
    AutocompleteTemplateTypeComponent,
    ButtonComponent,
    ButtonGoogleComponent,
    ButtonIconComponent,
    ButtonSecondaryComponent,
    CodeEditorComponent,
    CustomCurrencyPipe,
    DateUtcPipe,
    FieldCheckComponent,
    FieldChipComponent,
    FieldDateComponent,
    FieldDateRangeComponent,
    FieldFileUploadComponent,
    FieldNumericComponent,
    FieldPasswordComponent,
    FieldSelectComponent,
    FieldSelectMultipleComponent,
    FieldToggleComponent,
    FieldTextComponent,
    GlobalSpinnerComponent,
    GridComponent,
    GridPaginatorComponent,
    GridMenuComponent,
    IconComponent,
    IdDocumentTypePipe,
    IdDocumentComponent,
    LayoutColumnComponent,
    LayoutColumnDisplayComponent,
    LayoutContainerComponent,
    LayoutFormComponent,
    LayoutPageComponent,
    LayoutRowComponent,
    LayoutRowButtonsComponent,
    LayoutRowSeparatorComponent,
    LayoutSectionComponent,
    LeftMenuComponent,
    LinkComponent,
    LoggingGridComponent,
    LoggingConfigComponent,
    NotificationAreaComponent,
    NotificationItemComponent,
    NotificationTrayComponent,
    SafeResourceUrlPipe,
    SafeHtmlPipe,
    SpinnerComponent,
    TemplateGridComponent,
    ToolBarComponent,
    TranslatePipe,
    ValidationSummaryComponent,
  ],
  providers: [
    CustomFormBuilder,
    DatePipe,
    DateProviderService,
    EnumTranslateProviderService,
    ModalService,
    NavigationService,
    NotificationService,
    DateUtcPipe,
  ],
})
export class UtilsNgSharedComponentsModule {}
