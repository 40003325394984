import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'mf-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class LayoutPageComponent {
  @Input() title: string = 'Título de página';
}
