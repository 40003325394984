import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TransferDto } from '../../../../api/dtos/TransferDto';
import { TransferFiltersDto } from '../../../../api/dtos/TransferFiltersDto';
import { ApiClientAdminTransfer } from '../../../../api/endpoints/ApiClientAdminTransfer';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import tr from 'date-fns/locale/tr';
import { MovementType } from '../../../../api/enums/MovementType';
import { TransactionStatus } from '../../../../api/enums/TransactionStatus';
import { LoginService } from '../../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { Roles } from '../../../../api/enums/Roles';
import { ApiAdminTransfer } from '../../../../api/endpoints/ApiAdminTransfer';

@Component({
  selector: 'mf-transfer-grid',
  templateUrl: './transfer-grid.component.html',
  styleUrls: ['./transfer-grid.component.scss'],
})
export class TransferGridComponent implements OnInit {
  constructor(
    public _apiClientAdminTransfer: ApiClientAdminTransfer,
    public _apiAdminTransfer: ApiAdminTransfer,
    private _modalService: ModalService,
    private _route: ActivatedRoute,
    private _loginService: LoginService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() hideFilters = false;
  @Input() showCreateButton = false;
  @Input() transferId: string;
  @Input() accreditationsByAccountId: string;

  isSuperAdmin: boolean;

  filters: TransferFiltersDto;

  ngOnInit(): void {
    this.filters = new TransferFiltersDto();
    if (this.accreditationsByAccountId) {
      this.filters.accreditationByAccountId = this.accreditationsByAccountId;
    }
    this.isSuperAdmin = this._loginService.userIsInRole([Roles[Roles.SuperAdmin]]);
  }

  columns: IGridColumn<TransferDto>[] = [
    {
      field: nameof<TransferDto>((t) => t.createdDate),
      header: 'Fecha',
      width: 10,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<TransferDto>((t) => t.client.name),
      header: 'Cliente',
      width: 10,
    },
    {
      field: nameof<TransferDto>((t) => t.status),
      header: 'Estado',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.TransactionStatus,
      },
    },
    {
      field: nameof<TransferDto>((t) => t.movementType),
      header: 'Movimiento',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
    { field: nameof<TransferDto>((t) => t.sourceAccount.bank.name), header: 'Origen', width: 10 },
    { field: nameof<TransferDto>((t) => t.destinationAccount.bank.name), header: 'Destino', width: 10 },
    {
      field: nameof<TransferDto>((t) => t.destinationAccount.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<TransferDto>((t) => t.amount),
      header: 'Monto',
      cellOptions: {
        type: CellValueType.Numeric,
      },
      width: 10,
      align: 'right',
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.list,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: transferDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Ver órdenes',
      icon: Icons.list,
      onClick: (transferDto: TransferDto) => {
        throw new Error('Not implemented');
      },
    },
    {
      label: 'Cancelar',
      icon: Icons.delete,
      hide: (transferDto: TransferDto) =>
        transferDto.movementType == MovementType.Debit || transferDto.status > TransactionStatus.PendingConciliation,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openYesOrNoModal('¿Está seguro de cancelar la transferencia?', {
          onCloseSuccess: async () => {
            await this._apiClientAdminTransfer.cancel(transferDto.id);
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Rechazar',
      icon: Icons.cross,
      hide: (transferDto: TransferDto) =>
        transferDto.movementType == MovementType.Debit || transferDto.status > TransactionStatus.PendingConciliation || !this.isSuperAdmin,
      onClick: (transferDto: TransferDto) => {
        this._modalService.openYesOrNoModal('¿Está seguro de rechazar la transferencia?', {
          onCloseSuccess: async () => {
            await this._apiAdminTransfer.reject(transferDto.id);
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'TEST - Set as completed',
      icon: Icons.check,
      onClick: (transferDto: TransferDto) => {
        this._apiClientAdminTransfer.testSetCompleted(transferDto.id);
      },
    },
    {
      label: 'Enviar notificación por WebHook',
      icon: Icons.web,
      onClick: async (transferDto: TransferDto) => {
        await this._apiClientAdminTransfer.notifyClientWebHook(transferDto.id);
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<TransferFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminTransfer.getPaged(search, httpApiRequestOptions);
}
