@if (gridFilter.entityType == filterEntityType.Bank) {
<mf-autocomplete-bank
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
} @else if (gridFilter.entityType == filterEntityType.Accreditation) {
<mf-autocomplete-accreditation
  [label]="gridFilter.header"
  [hideAddButton]="true"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange($event)" />
}
