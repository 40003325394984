import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { enumName_mi_shared } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { BankDto } from '../../../../api/dtos/BankDto';
import { BankFiltersDto } from '../../../../api/dtos/BankFiltersDto';
import { ApiClientAdminBank } from '../../../../api/endpoints/ApiClientAdminBank';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { BankCreateComponent } from './bank-create.component';
import { BankEditComponent } from './bank-edit.component';
import { BankBranchCreateComponent } from '../bank-branch/bank-branch-create.component';

@Component({
  selector: 'mf-bank-grid',
  templateUrl: './bank-grid.component.html',
  styleUrls: ['./bank-grid.component.scss'],
})
export class BankGridComponent {
  constructor(private _apiClientAdminBank: ApiClientAdminBank, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  bank: BankDto;

  columns: IGridColumn<BankDto>[] = [
    {
      field: nameof<BankDto>((p) => p.name),
      header: 'Nombre',
      width: 20,
      filter: {
        propertyName: nameof<BankFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<BankDto>((p) => p.country),
      header: 'País',
      width: 20,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_shared.Country,
        enumNameModule: moduleName.shared,
      },
      filter: {
        propertyName: nameof<BankFiltersDto>((p) => p.countryList),
        type: FilterType.EnumList,
        enumName: enumName_mi_shared.Country,
        enumNameModule: moduleName.shared,
      },
    },
    {
      field: nameof<BankDto>((p) => p.bankCode),
      header: 'Código',
      width: 10,
    },
    {
      field: nameof<BankDto>((p) => p.swiftCode),
      header: 'Swift',
      width: 10,
    },
    {
      field: nameof<BankDto>((p) => p.swiftCountryCode),
      header: 'Swift país',
      width: 10,
    },
    {
      field: nameof<BankDto>((p) => p.accountNumberMinLength),
      header: 'Largo máximo',
      width: 10,
    },
    {
      field: nameof<BankDto>((p) => p.accountNumberMaxLength),
      header: 'Largo mínimo',
      width: 10,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (bankDto: BankDto) => {
        this._modalService.openRightModal(BankEditComponent, {
          openData: bankDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Nueva sucursal',
      icon: Icons.bankBranch,
      onClick: (bankDto: BankDto) => {
        this._modalService.openRightModal(BankBranchCreateComponent, {
          openData: { bankId: bankDto.id },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<BankFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminBank.getPaged(search, httpApiRequestOptions);

  openNewBankPopup() {
    this._modalService.openRightModal(BankCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
