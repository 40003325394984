import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { NaturalPersonDto, NaturalPersonDtoFormGroup } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';
import { PersonType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { LoginService } from '../../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { Roles } from '../../../../api/enums/Roles';

@Component({
  standalone: false,
  selector: 'mf-natural-person',
  templateUrl: './natural-person.component.html',
  styleUrls: ['./natural-person.component.scss'],
})
export class NaturalPersonComponent {
  constructor(private _cfb: CustomFormBuilder, private _loginService: LoginService) {}

  @Input()
  naturalPersonDto: NaturalPersonDto;

  @Output()
  save = new EventEmitter<NaturalPersonDto>();

  @Output()
  close = new EventEmitter();

  personType = PersonType;
  isSuperAdmin = this._loginService.userIsInRole([Roles[Roles.SuperAdmin]]);

  naturalPersonForm = this._cfb.group<NaturalPersonDtoFormGroup>({
    firstName: ['', Validators.required, 'Nombre'],
    lastName: ['', Validators.required, 'Apellido'],
    idDocument: [null, Validators.required],
    email: ['', null, 'Email'],
    address: [null, null],
    isExemptFromOriginDeclaration: [false, Validators.required, 'No declara cuenta de origen'],
  });

  ngOnInit(): void {
    this.naturalPersonForm.patchValue(this.naturalPersonDto);
  }
}
