import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminIssue } from '../../../api/endpoints/ApiAdminIssue';
import { IssueDto } from '../../../api/dtos/IssueDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';

@Component({
  selector: 'mf-issue-details',
  templateUrl: './issue-details.component.html',
  styleUrls: ['./issue-details.component.scss'],
})
export class IssueDetailsComponent {
  constructor(private _apiAdminIssue: ApiAdminIssue, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  enumName = enumName_mi_pspc;
  issueDto: IssueDto;

  async load(id: string) {
    this.issueDto = await this._apiAdminIssue.getById(id);
  }
}
