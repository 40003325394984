// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { BankSimpleDto } from './BankSimpleDto';

export class BcuReportFiltersDto extends FiltersDto {
  startDate: FilterRangeDateDto;
  endDate: FilterRangeDateDto;
  bankId: string | null = null;
  bank: BankSimpleDto;
}

export interface BcuReportFiltersDtoFormGroup extends FiltersDtoFormGroup {
  startDate?: any;
  endDate?: any;
  bankId?: any;
  bank?: any;
}
