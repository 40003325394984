import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { MenuItem } from './MenuItem';
import { MenuGroup } from './MenuGroup';
import { IRoleProvider } from './IRoleProvider';

@Component({
  standalone: false,
  selector: 'mf-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  constructor(private _layoutService: LayoutService) {}

  ngOnInit(): void {
    this._layoutService.getToggleSidenav().subscribe(() => {
      this.collapsed.update((value) => !value);
    });
    this.displayMenuItems = this.menuItems;
  }

  @Input()
  menuItems: MenuGroup[];
  @Input()
  roleProvider: IRoleProvider;
  @Output()
  navigateTo: EventEmitter<string> = new EventEmitter();
  searchText: string;
  displayMenuItems: MenuGroup[] = [];
  collapsed = signal(true);
  delaySearchTimeout: any;
  delaySearch = false;

  navigate(link: string) {
    this.searchText = '';
    this.search();
    this.collapsed.set(true);
    this.navigateTo.emit(link);
  }

  closeSidenav() {
    this.collapsed.set(true);
  }

  search() {
    this.delaySearch = true;

    if (this.delaySearchTimeout) {
      clearTimeout(this.delaySearchTimeout);
    }

    this.delaySearchTimeout = setTimeout(() => {
      this.delaySearchTimeout = null;
      this.delaySearch = false;
    }, 300);

    if (!this.searchText) {
      this.displayMenuItems = this.menuItems;
      return;
    }

    this.displayMenuItems = [];

    this.menuItems.forEach((group: MenuGroup) => {
      const newGroup = new MenuGroup(group.title, []);

      group.items.forEach((item: MenuItem) => {
        if (item.label.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
          newGroup.items.push(item);
        }
      });

      if (newGroup.items.length > 0) {
        this.displayMenuItems.push(newGroup);
      }
    });
  }
}
