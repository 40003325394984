import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PaymentOrderDto, PaymentOrderDtoFormGroup } from '../../../../api/dtos/PaymentOrderDto';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { Validators } from '@angular/forms';
import { ApiClientAdminPaymentOrder } from '../../../../api/endpoints/ApiClientAdminPaymentOrder';

@Component({
  standalone: false,
  selector: 'mf-payment-order-edit-external-id',
  templateUrl: './payment-order-edit-external-id.component.html',
  styleUrls: ['./payment-order-edit-external-id.component.scss'],
})
export class PaymentOrderEditExternalIdComponent {
  constructor(
    private _apiClientAdminPaymentOrder: ApiClientAdminPaymentOrder,
    private _modalService: ModalService,
    private _cfb: CustomFormBuilder
  ) {
    this.load(this._modalService.openData);
  }

  paymentOrderDto: PaymentOrderDto;

  paymentOrderForm = this._cfb.group<PaymentOrderDtoFormGroup>({
    externalId: ['', Validators.required, 'Nuevo Id externo'],
  });

  async load(id: string) {
    this.paymentOrderDto = await this._apiClientAdminPaymentOrder.getById(id);
  }

  async save(paymentOrderDto: PaymentOrderDto) {
    paymentOrderDto.id = this.paymentOrderDto.id;
    await this._apiClientAdminPaymentOrder.setExternalId(paymentOrderDto);
    this._modalService.closeAsSuccess('El Id externo de la orden se actualizó con éxito');
  }

  closeModal() {
    this._modalService.closeAsSuccess('');
  }
}
