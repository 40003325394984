// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { OrderDto } from "../dtos/OrderDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { OrderFiltersDto } from "../dtos/OrderFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminOrderGeneric {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OrderDto> {
    return this._httpApiClient.get("/client-admin/order/get-by-external-id/" + externalId, httpApiRequestOptions);
  }

  getAllByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<OrderDto[]> {
    return this._httpApiClient.get("/client-admin/order/get-all-by-external-id/" + externalId, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<OrderFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<OrderDto>> {
    return this._httpApiClient.post("/client-admin/order/paged", filters, httpApiRequestOptions);
  }

}
