import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import { ApiClientAdminCollectOrder } from '../../../../api/endpoints/ApiClientAdminCollectOrder';
import { ApiClientAdminOperation } from '../../../../api/endpoints/ApiClientAdminOperation';
import { OrderStatus } from '../../../../api/enums/OrderStatus';
import { OrderType } from '../../../../api/enums/OrderType';
import { OperationService } from '../../operation/operation.service';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  selector: 'mf-collect-order-create',
  templateUrl: './collect-order-create.component.html',
  styleUrls: ['./collect-order-create.component.scss'],
})
export class CollectOrderCreateComponent {
  constructor(
    private _apiClientAdminCollectOrder: ApiClientAdminCollectOrder,
    private _apiClientAdminOperation: ApiClientAdminOperation,
    private _modalService: ModalService,
    private _operationService: OperationService
  ) {
    this.loadOperation();
  }

  collectOrderDto: CollectOrderDto;

  async loadOperation() {
    const operationDto = await this._apiClientAdminOperation.getById(this._modalService.openData.operationId);
    this.collectOrderDto = new CollectOrderDto();
    this.collectOrderDto.id = GUID_EMPTY;
    this.collectOrderDto.operationId = operationDto.id;
    this.collectOrderDto.operation = operationDto;
    this.collectOrderDto.clientId = operationDto.clientId;
    this.collectOrderDto.type = OrderType.Collect;
    this.collectOrderDto.status = OrderStatus.Draft;
  }

  async save(collectOrderDto: CollectOrderDto) {
    await this._apiClientAdminCollectOrder.create(collectOrderDto);
    this._modalService.closeAsSuccess('La orden de cobro se creó con éxito');
    this._operationService.reloadOperation(collectOrderDto.operation.id);
  }
}
