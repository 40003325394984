<mf-page title="Reportes para BCU">
  <mf-row-buttons>
    <mf-button
      text="Nuevo reporte"
      (click)="openBcuReportPopup()" />
  </mf-row-buttons>

  <mf-row>
    <mf-grid
      #grid
      [initialFilters]="filters"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems"
      [mustIncludeFields]="mustIncludeFields" />
  </mf-row>
</mf-page>
