// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum OperationStatus {
	Draft = 1,
	UnderReviewBeforeCashing = 2,
	Cashing = 3,
	UnderReviewBeforeCashed = 4,
	Cashed = 5,
	Paying = 6,
	Completed = 7,
	Refunding = 8,
	Cancelled = 9,
	Refunded = 10,
	UnderReviewBeforeRefunding = 11,
}
