import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayBankAccountDto } from '../../../../api/dtos/GatewayBankAccountDto';
import { GatewayBankAccountFiltersDto } from '../../../../api/dtos/GatewayBankAccountFiltersDto';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { GatewayBankAccountCreateComponent } from './gateway-bank-account-create.component';
import { GatewayBankAccountEditComponent } from './gateway-bank-account-edit.component';
import { ApiClientAdminGatewayBankAccount } from '../../../../api/endpoints/ApiClientAdminGatewayBankAccount';
import { ApiAdminGatewayBankAccount } from '../../../../api/endpoints/ApiAdminGatewayBankAccount';
import { NotificationService } from '../../../../../../../libs/utils/ng-shared-components/src';
import { ApiAdminConfiguration } from '../../../../api/endpoints/ApiAdminConfiguration';
import { ConfigurationDto } from '../../../../api/dtos/ConfigurationDto';
import { ApiClientAdminConfiguration } from '../../../../api/endpoints/ApiClientAdminConfiguration';

@Component({
  standalone: false,
  selector: 'mf-gateway-bank-account-grid',
  templateUrl: './gateway-bank-account-grid.component.html',
  styleUrls: ['./gateway-bank-account-grid.component.scss'],
})
export class GatewayBankAccountGridComponent {
  constructor(
    private _apiAdminGatewayBankAccount: ApiAdminGatewayBankAccount,
    private _apiClientAdminGatewayBankAccount: ApiClientAdminGatewayBankAccount,
    private _modalService: ModalService,
    private _notificationService: NotificationService,
    private _apiClientAdminConfiguration: ApiClientAdminConfiguration
  ) {
    this.addUpdateBalancesActionButton();
  }

  @ViewChild('grid')
  grid: GridComponent;
  gatewayBankAccount: GatewayBankAccountDto;
  refreshIcon = Icons.refresh;

  actionButtons = [
    {
      text: 'Nueva cuenta de pasarela',
      onClick: () => this.openNewGatewayBankAccountPopup(),
      isSecondary: false,
      icon: '',
      disabled: false,
      title: '',
    },
  ];

  columns: IGridColumn<GatewayBankAccountDto>[] = [
    {
      field: nameof<GatewayBankAccountDto>((p) => p.bank.name),
      header: 'Banco',
      width: 10,
      filter: { propertyName: nameof<GatewayBankAccountDto>((p) => p.bank), type: FilterType.Entity, entityType: 'Bank' },
    },
    {
      field: nameof<GatewayBankAccountDto>((p) => p.accountNumber),
      header: 'Nro cuenta',
      width: 10,
      filter: { propertyName: nameof<GatewayBankAccountDto>((p) => p.accountNumber), type: FilterType.Text },
    },
    {
      field: nameof<GatewayBankAccountDto>((p) => p.currency),
      header: 'Moneda',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<GatewayBankAccountFiltersDto>((p) => p.currencyList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<GatewayBankAccountDto>((p) => p.isDefault),
      header: 'Es por defecto',
      width: 10,
      cellOptions: { type: CellValueType.Boolean },
    },
    {
      field: nameof<GatewayBankAccountDto>((p) => p.balance),
      header: 'Saldo',
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (gatewayBankAccountDto: GatewayBankAccountDto) => {
        this._modalService.openRightModal(GatewayBankAccountEditComponent, {
          openData: gatewayBankAccountDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Marcar como cuenta por defecto',
      icon: Icons.star,
      hide: (gatewayBankAccountDto: GatewayBankAccountDto) => gatewayBankAccountDto.isDefault,
      onClick: (gatewayBankAccountDto: GatewayBankAccountDto) => {
        this._modalService.openYesOrNoModal({
          openData: {
            title: '¿Está seguro que desea marcar como cuenta por defecto de pasarela?',
          },
          onCloseSuccess: async () => {
            await this._apiAdminGatewayBankAccount.setAsDefault(gatewayBankAccountDto.id);
            this._notificationService.showSuccess('La cuenta se marcó como cuenta por defecto con éxito');
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<GatewayBankAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminGatewayBankAccount.getPaged(search, httpApiRequestOptions);

  openNewGatewayBankAccountPopup() {
    this._modalService.openRightModal(GatewayBankAccountCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }

  async updateGatewayBalance() {
    await this._apiAdminGatewayBankAccount.updateGatewayBalance();
    this.grid.reloadPage();
  }

  async addUpdateBalancesActionButton() {
    const configurationDto = await this._apiClientAdminConfiguration.get();

    this.actionButtons.push({
      text: 'Actualizar saldos',
      onClick: () => this.updateGatewayBalance(),
      isSecondary: true,
      icon: Icons.refresh,
      disabled: configurationDto.skipAccountBalanceValidation,
      title: configurationDto.skipAccountBalanceValidation
        ? 'Esta pasarela no realiza control de saldos bancarios. Ver Configuración.'
        : '',
    });
  }
}
