import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { FilterRangeNumericDto } from '../../../../../../ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';
import { IGridFilterNumeric } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'mf-grid-filter-numeric-range',
  templateUrl: './grid-filter-numeric-range.component.html',
  styleUrls: ['./grid-filter-numeric-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterNumericRangeComponent),
      multi: true,
    },
  ],
})
export class GridFilterNumericRangeComponent extends GridFilterBase<IGridFilterNumeric, FilterRangeNumericDto | null> {
  min: number | null = null;
  max: number | null = null;

  updateRange(min: number | null, max: number | null): void {
    if (!min && !max) {
      this.value = null;
      return;
    }
    this.value = { min, max };
  }

  onNgModelChangeMax(value: number): void {
    this.updateRange(this.value?.min ?? null, value);
  }

  onNgModelChangeMin(value: number): void {
    this.updateRange(value, this.value?.max ?? null);
  }

  protected override onWriteValue(val: FilterRangeNumericDto | null): void {
    this.min = val?.min ?? null;
    this.max = val?.max ?? null;
  }
}
