// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';

export class AccreditationFiltersDto extends FiltersDto {
  type: number | null = null;
  date: FilterRangeDateDto;
  accreditationNumber: FilterRangeNumericDto;
  statusList: number[] = [];
}

export interface AccreditationFiltersDtoFormGroup extends FiltersDtoFormGroup {
  type?: any;
  date?: any;
  accreditationNumber?: any;
  statusList?: any;
}
