import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IGridFilter } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';

@Component({
  standalone: false,
  selector: 'mf-grid-filter-text',
  templateUrl: './grid-filter-text.component.html',
  styleUrls: ['./grid-filter-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterTextComponent),
      multi: true,
    },
  ],
})
export class GridFilterTextComponent extends GridFilterBase<IGridFilter, string> {}
