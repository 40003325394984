import { Component } from '@angular/core';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  standalone: false,
  selector: 'mf-field-toggle',
  templateUrl: './field-toggle.component.html',
  styleUrls: ['./field-toggle.component.scss'],
})
export class FieldToggleComponent extends FieldMatBaseComponent<boolean> {
  override get controlType(): string {
    return 'field-toggle';
  }
}
