// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class ApiAdminTransfer {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  reject(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.post("/admin/transfer/reject/" + id, null, httpApiRequestOptions);
  }

}
