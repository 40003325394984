import { Injectable } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Injectable()
export class DateProviderService {
  toString(date?: Date | null) {
    if (!date) {
      return '-';
    }

    if (typeof date === 'string') {
      date = parseISO(date);
    }

    return format(date, 'dd/MM/yyyy');
  }
}
