import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { ApiAdminClient } from '../../../api/endpoints/ApiAdminClient';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  standalone: false,
  selector: 'mf-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss'],
})
export class ClientCreateComponent {
  constructor(private _apiAdminClient: ApiAdminClient, private _modalService: ModalService) {
    this.clientDto = new ClientDto();
    this.clientDto.id = GUID_EMPTY;
  }

  clientDto: ClientDto;

  async save(client: ClientDto) {
    await this._apiAdminClient.create(client);
    this._modalService.closeAsSuccess('El cliente se creó con éxito');
  }

  closeModal() {
    this._modalService.closeAsSuccess('');
  }
}
