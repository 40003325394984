import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { NotificationDto, NotificationDtoFormGroup } from '../../../api/dtos/NotificationDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';

@Component({
  selector: 'mf-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  notificationDto: NotificationDto;
  @Output()
  save = new EventEmitter<NotificationDto>();

  enumName = enumName_mi_pspc;

  notificationForm = this._cfb.group<NotificationDtoFormGroup>({
    date: [new Date(), Validators.required, 'Fecha'],
    text: ['', Validators.required, 'Texto'],
    appUser: [null, Validators.required, 'Usuario'],
    appUserId: [null],
    title: ['', Validators.required, 'Título'],
    type: [null, Validators.required, 'Tipo'],
    client: [null, Validators.required, 'Cliente'],
    clientId: [null, Validators.required],
    isRead: [false],
  });
}
