import { Component } from '@angular/core';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminClient } from '../../../api/endpoints/ApiAdminClient';

@Component({
  standalone: false,
  selector: 'mf-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent {
  constructor(private _apiAdminClient: ApiAdminClient, modalService: ModalService) {
    this.load(modalService.openData);
  }

  clientDto: ClientDto;

  async load(id: string) {
    this.clientDto = await this._apiAdminClient.getById(id);
  }
}
