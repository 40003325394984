// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { TransferDto } from "../dtos/TransferDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { TransferFiltersDto } from "../dtos/TransferFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { TransferVoucherForCollectOrderDto } from "../dtos/TransferVoucherForCollectOrderDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminTransfer {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    transferDto: TransferDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TransferDto> {
    return this._httpApiClient.post("/client-admin/transfer", transferDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TransferDto> {
    return this._httpApiClient.get("/client-admin/transfer/" + id, httpApiRequestOptions);
  }

  getByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TransferDto> {
    return this._httpApiClient.put("/client-admin/transfer/get-by-external-id", externalId, httpApiRequestOptions);
  }

  edit(
    transferDto: TransferDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TransferDto> {
    return this._httpApiClient.put("/client-admin/transfer", transferDto, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<TransferFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<TransferDto>> {
    return this._httpApiClient.post("/client-admin/transfer/paged", filters, httpApiRequestOptions);
  }

  uploadVoucherForCollectOrder(
    transferVoucherForCollectOrderDto: TransferVoucherForCollectOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TransferDto> {
    return this._httpApiClient.post("/client-admin/transfer/upload-voucher-for-collect-order", transferVoucherForCollectOrderDto, httpApiRequestOptions);
  }

  cancel(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<TransferDto> {
    return this._httpApiClient.put("/client-admin/transfer/cancel/" + id, null, httpApiRequestOptions);
  }

}
