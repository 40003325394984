import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  standalone: false,
  selector: 'mf-issue-grid-page',
  templateUrl: './issue-grid-page.component.html',
  styleUrls: ['./issue-grid-page.component.scss'],
})
export class IssueGridPageComponent {
  constructor() {}
}
