// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { BankSimpleDto } from './BankSimpleDto';

export class BankAccountFiltersDto extends FiltersDto {
  id: string | null = null;
  personId: string | null = null;
  bankId: string | null = null;
  bank: BankSimpleDto;
  accountTypeList: number[] = [];
  accountNumber: string = '';
  bankBranchId: string | null = null;
  currencyList: number[] = [];
}

export interface BankAccountFiltersDtoFormGroup extends FiltersDtoFormGroup {
  id?: any;
  personId?: any;
  bankId?: any;
  bank?: any;
  accountTypeList?: any;
  accountNumber?: any;
  bankBranchId?: any;
  currencyList?: any;
}
