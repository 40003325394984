<mf-form
  [formGroup]="notificationForm"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-autocomplete-client
      formControlName="client"
      (itemIdChanged)="notificationForm.patchValue({ clientId: $event })" />
  </mf-column>

  <mf-column>
    <mf-autocomplete-user
      formControlName="appUser"
      (itemIdChanged)="notificationForm.patchValue({ appUserId: $event })" />
  </mf-column>

  <mf-column [md]="2">
    <mf-field-date formControlName="date" />
  </mf-column>

  <mf-column [md]="2">
    <mf-field-select
      formControlName="type"
      [enumName]="enumName.NotificationType" />
  </mf-column>

  <mf-row>
    <mf-field-text formControlName="title" />
  </mf-row>

  <mf-row>
    <mf-field-text
      [multiline]="true"
      formControlName="text" />
  </mf-row>
</mf-form>
