import { Component, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { OperationFiltersDto } from '../../../api/dtos/OperationFiltersDto';
import { ApiClientAdminOperation } from '../../../api/endpoints/ApiClientAdminOperation';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { OperationSimpleDto } from '../../../api/dtos/OperationSimpleDto';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-autocomplete-operation',
  templateUrl: './autocomplete-operation.component.html',
  styleUrls: ['./autocomplete-operation.component.scss'],
})
export class AutocompleteOperationComponent
  extends AutoCompleteBaseEntityComponent<OperationSimpleDto | null, OperationFiltersDto>
  implements OnInit
{
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiClientAdminOperation: ApiClientAdminOperation,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
  }

  override defaultOrderBy = nameof<OperationDto>((op) => op.createdDate);

  override endpointSuggest = (search: SuggestSearchDto<OperationFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminOperation.suggest(search, httpApiRequestOptions);
}
