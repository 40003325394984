// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum enumName_mi_pspc {
  AccountType = "AccountType",
  AccreditationsByAccountStatus = "AccreditationsByAccountStatus",
  AccreditationsByAccountType = "AccreditationsByAccountType",
  AccreditationStatus = "AccreditationStatus",
  AccreditationType = "AccreditationType",
  Currency = "Currency",
  CurrencyName = "CurrencyName",
  EntityType = "EntityType",
  EnumTest = "EnumTest",
  EnventNotificationType = "EnventNotificationType",
  GatewayName = "GatewayName",
  IssueLevel = "IssueLevel",
  IssueStatus = "IssueStatus",
  IssueType = "IssueType",
  MovementType = "MovementType",
  NotificationType = "NotificationType",
  OperationRequestValidationCode = "OperationRequestValidationCode",
  OperationStatus = "OperationStatus",
  OrderRequestValidationCode = "OrderRequestValidationCode",
  OrderStatus = "OrderStatus",
  OrderType = "OrderType",
  PublicOrderStatus = "PublicOrderStatus",
  PublicTransactionStatus = "PublicTransactionStatus",
  Roles = "Roles",
  TransactionStatus = "TransactionStatus",
  TransactionType = "TransactionType",
  TransferOriginType = "TransferOriginType",
  TransferRequestValidationCode = "TransferRequestValidationCode",
  ValueListType = "ValueListType",
}
