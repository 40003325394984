import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
  standalone: false,
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'UYU';
      case 2:
        return 'USD';
      default:
        return '';
    }
  }
}
