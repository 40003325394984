import { Component } from '@angular/core';
import { LayoutColumnComponent } from './column.component';

@Component({
  standalone: false,
  selector: 'mf-row-buttons',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class LayoutRowButtonsComponent extends LayoutColumnComponent {
  constructor() {
    super();
    this.md = 12;
    this.align = 'right';
    this.isRowButtons = true;
  }
}
