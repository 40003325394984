<mf-form
  [formGroup]="gatewayForm"
  [entity]="gatewayDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-select
      formControlName="country"
      [enumName]="enumName.Country"
      [enumNameModule]="enumNameModule" />
  </mf-column>
</mf-form>
