import { Component } from '@angular/core';
import { OperationDto } from '../../../api/dtos/OperationDto';
import { OperationService } from './operation.service';
import { OperationStatus } from '../../../api/enums/OperationStatus';

@Component({
  standalone: false,
  selector: 'mf-operation-create',
  templateUrl: './operation-create.component.html',
  styleUrls: ['./operation-create.component.scss'],
})
export class OperationCreateComponent {
  constructor(private _operationService: OperationService) {
    this._operationService.operationDto = new OperationDto();
    this._operationService.operationDto.status = OperationStatus.Draft;
  }

  async save(operationDto: OperationDto) {
    await this._operationService.createDraft(operationDto);
  }
}
