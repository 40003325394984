import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Icons } from '../icon/icons';
import { IApiTemplateDesign } from './api/IApiTemplateDesign';
import { TemplateDesignTestEmailDto } from './dtos/TemplateDesignTestEmailDto';
import { TemplateDesignTestGenericDto } from './dtos/TemplateDesignTestGenericDto';
import { TemplateDesignTestPdfDto } from './dtos/TemplateDesignTestPdfDto';
import { TemplateDesignTestPreviewHtmlDto } from './dtos/TemplateDesignTestPreviewHtmlDto';
import { TemplateDto } from './dtos/TemplateDto';
import { TemplateEmailDto } from './dtos/TemplateEmailDto';
import { TemplateGenericDto } from './dtos/TemplateGenericDto';
import { TemplatePdfDto } from './dtos/TemplatePdfDto';
import { TemplateGroup } from './enums/TemplateGroup';
import { TemplateViewModelFormGroup } from './view-models/TemplateViewModel';

@Component({
  standalone: false,
  selector: 'mf-template-editor-preview',
  templateUrl: './template-editor-preview.component.html',
  styleUrls: ['./template-editor-preview.component.scss'],
})
export class TemplateEditorPreviewComponent implements OnInit {
  constructor(
    private _formGroup: FormGroupDirective,
    @Inject('IApiTemplateDesign') private _apiTemplateDesign: IApiTemplateDesign,
    private _domSanitizer: DomSanitizer
  ) {}

  form: FormGroup<TemplateViewModelFormGroup>;
  icons = Icons;
  loadingPreview = false;
  showPdf = false;
  private _previewHtml: string;
  private _previewPdfUrl: string;
  safePreviewPdfUrl?: SafeResourceUrl;
  safePreviewHtml?: SafeHtml;
  templateGroup = TemplateGroup;

  get template(): TemplateDto {
    return this.form.value as TemplateDto;
  }

  private async getPreviewHtml(): Promise<TemplateDesignTestPreviewHtmlDto> {
    switch (this.form.value.group) {
      case TemplateGroup.Pdf:
        const templateDesignTestPdf = new TemplateDesignTestPdfDto();
        templateDesignTestPdf.template = this.form.value as TemplatePdfDto;
        templateDesignTestPdf.previewJsonData = this.form.value.previewData;
        return await this._apiTemplateDesign.previewHtmlPdf(templateDesignTestPdf, { preventSpinner: true });
      case TemplateGroup.Email:
        const templateDesignTestEmail = new TemplateDesignTestEmailDto();
        templateDesignTestEmail.previewJsonData = this.form.value.previewData;
        templateDesignTestEmail.template = this.form.value as TemplateEmailDto;
        return await this._apiTemplateDesign.previewHtmlEmail(templateDesignTestEmail, { preventSpinner: true });
      default:
        const templateDesignTestGeneric = new TemplateDesignTestGenericDto();
        templateDesignTestGeneric.previewJsonData = this.form.value.previewData;
        templateDesignTestGeneric.template = this.form.value as TemplateGenericDto;
        return await this._apiTemplateDesign.previewHtmlGeneric(templateDesignTestGeneric, { preventSpinner: true });
    }
  }

  private async getPreviewPdfUrl() {
    const templateDesignTestPdf = new TemplateDesignTestPdfDto();
    templateDesignTestPdf.template = this.form.value as TemplatePdfDto;
    templateDesignTestPdf.previewJsonData = this.form.value.previewData;
    const response = await this._apiTemplateDesign.previewPdfFile(templateDesignTestPdf, { preventSpinner: true });
    return response.url;
  }

  ngOnInit(): void {
    this.form = this._formGroup.control as FormGroup<TemplateViewModelFormGroup>;

    if (this.form.value.group === TemplateGroup.Pdf) {
      this.showPdf = true;
    }
    if (this.form.value.previewData) {
      this.refreshPreview();
    }
  }

  async refreshPreview() {
    this.safePreviewPdfUrl = undefined;
    if (this.showPdf) {
      this._previewPdfUrl = await this.getPreviewPdfUrl();
      this.safePreviewPdfUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(this._previewPdfUrl);
      return;
    }

    const result = await this.getPreviewHtml();
    this._previewHtml = result.html;
    this.safePreviewHtml = this._domSanitizer.bypassSecurityTrustHtml(this._previewHtml);
  }

  onPreviewPdfLoad(event: any) {
    if (!event.target.src) {
      return;
    }

    const request = async () => {
      const urlParts = this._previewPdfUrl.split('/');
      await this._apiTemplateDesign.previewPdfFileAck(urlParts[urlParts.length - 1], {
        preventSpinner: true,
      });
    };
    request();
  }
}
