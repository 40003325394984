import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayBankAccountDto } from '../../../../api/dtos/GatewayBankAccountDto';
import { ApiAdminGatewayBankAccount } from '../../../../api/endpoints/ApiAdminGatewayBankAccount';
import { ClientDto } from '../../../../api/dtos/ClientDto';
import { ApiAdminClient } from '../../../../api/endpoints/ApiAdminClient';

@Component({
  standalone: false,
  selector: 'mf-gateway-bank-account-create',
  templateUrl: './gateway-bank-account-create.component.html',
  styleUrls: ['./gateway-bank-account-create.component.scss'],
})
export class GatewayBankAccountCreateComponent {
  constructor(
    private _apiAdminGatewayBankAccount: ApiAdminGatewayBankAccount,
    private _modalService: ModalService,
    private _apiAdminClient: ApiAdminClient
  ) {
    this.loadMiPspcId();
  }

  miPspcId: string | null;

  async loadMiPspcId() {
    const miPspcClientDto: ClientDto = await this._apiAdminClient.getDefault();
    this.miPspcId = miPspcClientDto.companyId;
  }

  async save(gatewayGatewayBankAccountDto: GatewayBankAccountDto) {
    await this._apiAdminGatewayBankAccount.create(gatewayGatewayBankAccountDto);
    this._modalService.closeAsSuccess('La cuenta se creó con éxito');
  }

  close() {
    this._modalService.closeAsSuccess('');
  }
}
