<mf-auto-complete
  [disabled]="!bankId"
  [label]="label"
  [endpointSuggest]="endpointSuggest"
  [ngModel]="value?.displayText ?? ''"
  [selectedId]="value?.id"
  [filters]="filters"
  [orderBy]="orderBy"
  [wrappedNgControl]="ngControl"
  [autoCompleteMaxItems]="autoCompleteMaxItems"
  [showAddButton]="!hideAddButton"
  (selectedItemChange)="selectedItemChange($event)"
  (createNewClick)="createNewClick()" />
