import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PaymentOrderDto, PaymentOrderDtoFormGroup } from '../../../../api/dtos/PaymentOrderDto';
import { ApiClientAdminPaymentOrder } from '../../../../api/endpoints/ApiClientAdminPaymentOrder';
import { OperationService } from '../../operation/operation.service';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';

@Component({
  standalone: false,
  selector: 'mf-payment-order-set-destination-account',
  templateUrl: './payment-order-set-destination-account.component.html',
  styleUrls: ['./payment-order-set-destination-account.component.scss'],
})
export class PaymentOrderSetDestinationAccountComponent {
  constructor(
    private _apiClientAdminPaymentOrder: ApiClientAdminPaymentOrder,
    private _modalService: ModalService,
    private _operationService: OperationService,
    private _cfb: CustomFormBuilder
  ) {
    this.load(this._modalService.openData);
  }

  paymentOrderDto: PaymentOrderDto;

  async load(id: string) {
    this.paymentOrderDto = await this._apiClientAdminPaymentOrder.getById(id);
  }

  paymentOrderForm = this._cfb.group<PaymentOrderDtoFormGroup>({
    amount: ['', Validators.required, 'Monto'],
    personId: ['', Validators.required],
    person: [null, Validators.required, 'Persona'],
    destinationBankAccountId: ['', Validators.required],
    destinationBankAccount: [null, Validators.required, 'Nro. de cuenta'],
    externalId: ['', , 'Id externo'],
  });

  async save(paymentOrderDto: PaymentOrderDto) {
    await this._apiClientAdminPaymentOrder.setRefundDestinationBankAccount(paymentOrderDto);
    this._modalService.closeAsSuccess('La cuenta de destino se ha actualizado correctamente.');
  }
}
