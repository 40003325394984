<div
  *ngIf="!closed"
  class="alert"
  [ngClass]="'alert-' + type">
  <span class="alert-message">
    <ng-content></ng-content>
  </span>
  <button
    *ngIf="showCloseButton"
    class="close-btn"
    (click)="closeAlert()">
    X
  </button>
</div>
