<mf-auto-complete
  [label]="label"
  [endpointSuggest]="endpointSuggest"
  [ngModel]="value?.displayText ?? ''"
  [selectedId]="value?.id"
  [filters]="filters"
  [orderBy]="orderBy"
  [wrappedNgControl]="wrappedNgControl"
  [showAddButton]="!hideAddButton"
  (selectedItemChange)="selectedItemChange($event)" />
