import { Component, Inject, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { TemplateGenericEditComponent } from './generic/template-generic-edit.component';
import { TemplateGenericCreateComponent } from './generic/template-generic-create.component';
import { TemplateEmailCreateComponent } from './email/template-email-create.component';
import { TemplateEmailEditComponent } from './email/template-email-edit.component';
import { TemplatePdfCreateComponent } from './pdf/template-pdf-create.component';
import { CellValueType, IGridColumn } from '../../grid/interface/gird-column';
import { TemplateDto } from '../dtos/TemplateDto';
import { TemplateFiltersDto } from '../dtos/TemplateFiltersDto';
import { FilterType } from '../../grid/interface/filter-type';
import { enumName_templates } from '../enums/_enumName.templates';
import { moduleName } from '../../../../../ts-shared/src/lib/module-names';
import { GridMenuItem } from '../../grid/grid-menu/grid-menu-item';
import { Icons } from '../../icon/icons';
import { TemplateGroup } from '../enums/TemplateGroup';
import { PagedSearchDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';
import { GridComponent, IGridActionButton } from '../../grid/grid.component';
import { IApiTemplate } from '../api/IApiTemplate';
import { ModalService } from '../../services/modal.service';
import { TemplatePdfEditComponent } from './pdf/template-pdf-edit.component';
import { ComponentType } from '@angular/cdk/portal';

@Component({
  selector: 'mf-template-grid',
  templateUrl: './template-grid.component.html',
  styleUrls: ['./template-grid.component.scss'],
})
export class TemplateGridComponent {
  constructor(@Inject('IApiTemplate') private _apiTemplate: IApiTemplate, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  templateGroup = TemplateGroup;

  columns: IGridColumn<TemplateDto>[] = [
    {
      field: nameof<TemplateDto>((p) => p.group),
      header: 'Grupo',
      width: 10,
      cellOptions: { type: CellValueType.Enum, enumName: enumName_templates.TemplateGroup, enumNameModule: moduleName.templates },
      filter: {
        propertyName: nameof<TemplateFiltersDto>((p) => p.group),
        type: FilterType.Enum,
        enumName: enumName_templates.TemplateGroup,
        enumNameModule: moduleName.templates,
      },
    },
    {
      field: nameof<TemplateDto>((p) => p.type.name),
      header: 'Tipo',
      width: 15,
    },
    {
      field: nameof<TemplateDto>((p) => p.name),
      header: 'Nombre',
      width: 15,
      filter: {
        propertyName: nameof<TemplateFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (templateDto: TemplateDto) => {
        if (templateDto.group === TemplateGroup.Generic) {
          this._modalService.openRightModalFull(TemplateGenericEditComponent, { openData: templateDto.id });
        } else if (templateDto.group === TemplateGroup.Email) {
          this._modalService.openRightModalFull(TemplateEmailEditComponent, { openData: templateDto.id });
        } else if (templateDto.group === TemplateGroup.Pdf) {
          this._modalService.openRightModalFull(TemplatePdfEditComponent, { openData: templateDto.id });
        }
      },
    },
  ];

  gridActionButtons: IGridActionButton[] = [
    {
      text: 'Nueva genérica',
      onClick: () => this.openNewTemplate(TemplateGenericCreateComponent),
    },
    {
      text: 'Nuevo PDF',
      onClick: () => this.openNewTemplate(TemplatePdfCreateComponent),
    },
    {
      text: 'Nuevo correo',
      onClick: () => this.openNewTemplate(TemplateEmailCreateComponent),
    },
  ];

  apiEndpoint = (search: PagedSearchDto<TemplateFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) =>
    this._apiTemplate.getPaged(search, httpApiRequestOptions);

  openNewTemplate(component: ComponentType<TemplateGenericCreateComponent | TemplatePdfCreateComponent | TemplateEmailCreateComponent>) {
    this._modalService.openRightModalFull(component, { onCloseSuccess: () => this.grid.reloadPage() });
  }
}
