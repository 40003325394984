import { Injectable } from '@angular/core';

@Injectable()
export class BroadcastChannelService {
  constructor() {
    this.channel = new BroadcastChannel('login_channel');
  }

  private channel: BroadcastChannel;

  postMessage(message: string) {
    this.channel.postMessage(message);
    if (message === 'ReloadPage') {
      window.location.reload();
    }
  }

  onMessage(callback: (event: MessageEvent) => void) {
    this.channel.onmessage = callback;
  }
}
