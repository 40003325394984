import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { ClientDto } from '../../../../api/dtos/ClientDto';
import { TransferDto } from '../../../../api/dtos/TransferDto';
import { TransferVoucherForCollectOrderDto } from '../../../../api/dtos/TransferVoucherForCollectOrderDto';
import { ApiClientAdminConfiguration } from '../../../../api/endpoints/ApiClientAdminConfiguration';
import { ApiClientAdminTransfer } from '../../../../api/endpoints/ApiClientAdminTransfer';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { Currency } from '../../../../api/enums/Currency';
import { Roles } from '../../../../api/enums/Roles';

@Component({
  standalone: false,
  selector: 'mf-transfer-create',
  templateUrl: './transfer-create.component.html',
  styleUrls: ['./transfer-create.component.scss'],
})
export class TransferCreateComponent implements OnInit {
  constructor(
    private _apiClientAdminTransfer: ApiClientAdminTransfer,
    private _modalService: ModalService,
    private _apiClientAdminConfiguration: ApiClientAdminConfiguration,
    private _loginService: LoginService
  ) {}

  currencyList: Currency[];
  enumName = enumName_mi_pspc;
  isAdmin = false;

  transferVoucherForCollectOrderDto = new TransferVoucherForCollectOrderDto();

  currency: Currency | null;
  client: ClientDto | null;
  person: PersonDto | null;

  ngOnInit(): void {
    this.loadCurrencyList();
    this.isAdmin = this._loginService.userIsInRole([Roles[Roles.SuperAdmin]]);

    if (!this.isAdmin) {
      this.transferVoucherForCollectOrderDto.clientId = this._loginService.loggedClientId;
    }
  }

  onPersonSelected($event: any) {
    this.person = $event;
  }

  onClientSelected($event: any) {
    this.transferVoucherForCollectOrderDto.clientId = $event.id;
  }

  onCurrencySelected($event: any) {
    this.transferVoucherForCollectOrderDto.currency = $event;
  }

  async save(transferDto: TransferDto) {
    await this._apiClientAdminTransfer.create(transferDto);
    this._modalService.closeAsSuccess('La transferencia se creó con éxito');
  }

  async loadCurrencyList() {
    this.currencyList = await this._apiClientAdminConfiguration.getCurrencyList();
  }

  async submit(transferVoucherForCollectOrderDto: TransferVoucherForCollectOrderDto) {
    await this._apiClientAdminTransfer.uploadVoucherForCollectOrder(transferVoucherForCollectOrderDto);
    this._modalService.closeAsSuccess('El comprobante se cargó con éxito');
  }

  closeModal() {
    this._modalService.closeAsSuccess('');
  }
}
