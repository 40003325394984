import { Component, Inject, signal } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { LogEventDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventDto';
import { LogEventCompleteDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventCompleteDto';
import { enumName_logging } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/enums/_enumName.logging';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { IApiAdminLoggingViewer } from '../api/IApiAdminLoggingViewer';

@Component({
  selector: 'mf-logging-details',
  templateUrl: './logging-details.component.html',
  styleUrls: ['./logging-details.component.scss'],
})
export class LoggingDetailsComponent {
  constructor(
    @Inject('IApiAdminLoggingViewer') private _apiAdminLoggingViewer: IApiAdminLoggingViewer,
    private _modalService: ModalService
  ) {
    this.load(this._modalService.openData);
  }

  enumName = enumName_logging;
  moduleName = moduleName;

  logEventDto = signal<LogEventCompleteDto | null>(null);

  async load(logEventDto: LogEventDto) {
    this.logEventDto.set(await this._apiAdminLoggingViewer.getEventComplete(logEventDto.partitionKey, logEventDto.rowKey));
  }
}
