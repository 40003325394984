import { Component, Inject, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { CellValueType, IGridColumn } from '../../grid/interface/gird-column';
import { EmailSentDto } from '../dtos/EmailSentDto';
import { EmailSentFiltersDto } from '../dtos/EmailSentFiltersDto';
import { FilterType } from '../../grid/interface/filter-type';
import { moduleName } from '../../../../../ts-shared/src/lib/module-names';
import { GridMenuItem } from '../../grid/grid-menu/grid-menu-item';
import { Icons } from '../../icon/icons';
import { PagedSearchDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';
import { GridComponent } from '../../grid/grid.component';
import { IApiEmailSent } from '../api/IApiEmailSent';
import { ModalService } from '../../services/modal.service';
import { enumName_emails } from '../enums/_enumName.emails';
import { EmailSentDetailComponent } from './email-sent-detail.component';

@Component({
  selector: 'mf-email-sent-grid',
  templateUrl: './email-sent-grid.component.html',
  styleUrls: ['./email-sent-grid.component.scss'],
})
export class EmailSentGridComponent {
  constructor(@Inject('IApiEmailSent') private _apiEmailSent: IApiEmailSent, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  emailSent: EmailSentDto;

  columns: IGridColumn<EmailSentDto>[] = [
    {
      field: nameof<EmailSentDto>((p) => p.createdDate),
      header: 'Fecha',
      width: 15,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<EmailSentDto>((p) => p.to),
      header: 'A',
      width: 15,
      filter: {
        propertyName: nameof<EmailSentFiltersDto>((p) => p.to),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<EmailSentDto>((p) => p.subject),
      header: 'Asunto',
      width: 60,
      filter: {
        propertyName: nameof<EmailSentFiltersDto>((p) => p.subject),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<EmailSentDto>((p) => p.status),
      header: 'Estado',
      cellOptions: { type: CellValueType.Enum, enumName: enumName_emails.EmailSentStatus, enumNameModule: moduleName.emails },
      width: 10,
      filter: {
        propertyName: nameof<EmailSentFiltersDto>((p) => p.statusList),
        type: FilterType.EnumList,
        enumName: enumName_emails.EmailSentStatus,
        enumNameModule: moduleName.emails,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.details,
      onClick: (emailSent: EmailSentDto) => {
        this._modalService.openRightModal(EmailSentDetailComponent, { openData: emailSent.id });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<EmailSentFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) =>
    this._apiEmailSent.getPaged(search, httpApiRequestOptions);
}
