import { Component, Inject } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { IApiEmailSent } from '../api/IApiEmailSent';
import { EmailSentDto } from '../dtos/EmailSentDto';
import { enumName_emails } from '../enums/_enumName.emails';
import { moduleName } from '../../../../../ts-shared/src/lib/module-names';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  standalone: false,
  selector: 'mf-email-sent-detail',
  templateUrl: './email-sent-detail.component.html',
  styleUrls: ['./email-sent-detail.component.scss'],
})
export class EmailSentDetailComponent {
  constructor(
    @Inject('IApiEmailSent') private _apiEmailSent: IApiEmailSent,
    modalService: ModalService,
    private _domSanitizer: DomSanitizer
  ) {
    this.load(modalService.openData);
  }

  emailSent: EmailSentDto;
  enumName = enumName_emails;
  moduleName = moduleName;
  safeBodyHtml: SafeHtml;

  async load(id: string) {
    this.emailSent = await this._apiEmailSent.getById(id);

    this.safeBodyHtml = this._domSanitizer.bypassSecurityTrustHtml(this.emailSent.htmlBody);
  }
}
