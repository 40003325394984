import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PaymentOrderDto } from '../../../../api/dtos/PaymentOrderDto';
import { ApiClientAdminPaymentOrder } from '../../../../api/endpoints/ApiClientAdminPaymentOrder';
import { OperationService } from '../../operation/operation.service';
import { ApiClientAdminCollectOrder } from '../../../../api/endpoints/ApiClientAdminCollectOrder';
import { CollectOrderDto, CollectOrderDtoFormGroup } from '../../../../api/dtos/CollectOrderDto';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { Validators } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'mf-collect-order-edit-external-id',
  templateUrl: './collect-order-edit-external-id.component.html',
  styleUrls: ['./collect-order-edit-external-id.component.scss'],
})
export class CollectOrderEditExternalIdComponent {
  constructor(
    private _apiClientAdminCollectOrder: ApiClientAdminCollectOrder,
    private _modalService: ModalService,
    private _cfb: CustomFormBuilder
  ) {
    this.load(this._modalService.openData);
  }

  collectOrderDto: CollectOrderDto;

  collectOrderForm = this._cfb.group<CollectOrderDtoFormGroup>({
    externalId: ['', Validators.required, 'Nuevo Id externo'],
  });

  async load(id: string) {
    this.collectOrderDto = await this._apiClientAdminCollectOrder.getById(id);
  }

  async save(collectOrderDto: CollectOrderDto) {
    collectOrderDto.id = this.collectOrderDto.id;
    await this._apiClientAdminCollectOrder.setExternalId(collectOrderDto);
    this._modalService.closeAsSuccess('El Id externo de la orden se actualizó con éxito');
  }

  closeModal() {
    this._modalService.closeAsSuccess('');
  }
}
