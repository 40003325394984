<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (menuOpened)="isOpen = true"
  [ngStyle]="{ padding: 0 }">
  <mf-icon icon="{{ icons.moreVert }} "></mf-icon>
</button>
<mat-menu #menu="matMenu">
  @if (isOpen) { @for (menuItem of menuItems; track menuItem.label) { @if (menuItem.hide ? !menuItem.hide(item) : true) {
  <button
    [disabled]="getDisabled(menuItem)"
    [title]="getTooltip(menuItem)"
    (click)="menuItem.onClick(item)"
    mat-menu-item>
    <div style="display: flex; align-items: center">
      <mf-icon
        class="menu-icon"
        [icon]="menuItem.icon"></mf-icon>
      <div style="margin-right: 30px; white-space: nowrap">
        {{ menuItem.label }}
      </div>
    </div>
  </button>
  } } }
</mat-menu>
