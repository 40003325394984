// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { ConfigurationDto } from "../dtos/ConfigurationDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminConfiguration {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  get(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ConfigurationDto> {
    return this._httpApiClient.get("/client-admin/configuration", httpApiRequestOptions);
  }

  getCurrencyList(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<number[]> {
    return this._httpApiClient.get("/client-admin/configuration/currencies", httpApiRequestOptions);
  }

}
