// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum IdDocumentType {
	CI = 1,
	DNI = 2,
	CPF = 3,
	CC = 4,
	PASSPORT = 999,
	RUT = 1001,
	CUIT = 1002,
	CNPJ = 1003,
	NIT = 1004,
	RUC = 1005,
	RIF = 1006,
}
