// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { GatewayBankAccountDto } from "../dtos/GatewayBankAccountDto";

@Injectable({providedIn: 'root'})
export class ApiAdminGatewayBankAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    gatewayBankAccountDto: GatewayBankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayBankAccountDto> {
    return this._httpApiClient.post("/admin/gateway-bank-account", gatewayBankAccountDto, httpApiRequestOptions);
  }

  edit(
    gatewayBankAccountDto: GatewayBankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayBankAccountDto> {
    return this._httpApiClient.put("/admin/gateway-bank-account", gatewayBankAccountDto, httpApiRequestOptions);
  }

  updateGatewayBalance(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/gateway-bank-account/update-balance", null, httpApiRequestOptions);
  }

}
