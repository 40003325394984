import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { ClientDto } from '../../../../api/dtos/ClientDto';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import {
  TransferVoucherForCollectOrderDto,
  TransferVoucherForCollectOrderDtoFormGroup,
} from '../../../../api/dtos/TransferVoucherForCollectOrderDto';
import { ApiClientAdminClient } from '../../../../api/endpoints/ApiClientAdminClient';
import { ApiClientAdminPerson } from '../../../../api/endpoints/ApiClientAdminPerson';
import { ApiClientAdminUploadFile } from '../../../../api/endpoints/ApiClientAdminUploadFile';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';

@Component({
  standalone: false,
  selector: 'mf-collect-order-voucher',
  templateUrl: './collect-order-voucher.component.html',
  styleUrls: ['./collect-order-voucher.component.scss'],
})
export class CollectOrderVoucherComponent implements OnInit, OnChanges {
  constructor(
    private _cfb: CustomFormBuilder,
    private _apiClientAdminUploadFile: ApiClientAdminUploadFile,
    private _apiClientAdminClient: ApiClientAdminClient,
    private _apiClientAdminPerson: ApiClientAdminPerson
  ) {
    this.endpoint = (fileList: FileList, httpApiRequestOptions: HttpApiRequestOptions) =>
      this._apiClientAdminUploadFile.uploadCollectVoucherFile(fileList, httpApiRequestOptions);
    this.loadMiPspcId();
  }

  endpoint: any;

  @Input()
  transferVoucherForCollectOrderDto = new TransferVoucherForCollectOrderDto();
  @Input()
  personId: string | null;

  @Output()
  save = new EventEmitter<TransferVoucherForCollectOrderDto>();

  @Output()
  close = new EventEmitter();

  miPspcId: string | null;
  personDto: PersonDto | null;

  ngOnInit(): void {
    this.collectOrderVoucherForm.patchValue(this.transferVoucherForCollectOrderDto);

    if (this.personId) {
      this.loadPerson(this.personId);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['personId'] && changes['personId'].currentValue) {
      this.loadPerson(changes['personId'].currentValue);
    }
  }

  private async loadPerson(personId: string) {
    this.personDto = await this._apiClientAdminPerson.getById(personId);
  }

  async loadMiPspcId() {
    const miPspcClientDto: ClientDto = await this._apiClientAdminClient.getCurrentClient();
    this.miPspcId = miPspcClientDto.companyId;
  }

  collectOrderVoucherForm = this._cfb.group<TransferVoucherForCollectOrderDtoFormGroup>({
    amount: [null, Validators.required, 'Monto'],
    clientId: [null, Validators.required, 'Cliente'],
    createsNewOrderForBalanceAmount: [null, Validators.required, 'Ordenes de balance'],
    collectOrders: [null, Validators.required, 'Ordenes de cobro'],
    currency: [null, Validators.required, 'Moneda'],
    uploadDate: [null, Validators.required, 'Fecha'],
    destinationBankAccountId: [''],
    destinationBankAccount: ['', Validators.required, 'Cuenta de destino'],
    voucherUrl: [null, Validators.required, 'Comprobante'],
    reference: [null, Validators.required, 'Referencia'],
    sourceBankAccountId: [''],
    sourceBankAccount: [null, '', 'Cuenta de origen'],
  });

  selectItems(collectOrders: CollectOrderDto[]) {
    this.transferVoucherForCollectOrderDto.collectOrders = [];
    collectOrders.forEach((c) => {
      this.transferVoucherForCollectOrderDto.collectOrders.push({
        amount: c.amount,
        collectOrderId: c.id,
        newCollectOrderFromBalanceExternalId: c.externalId,
        newPaymentOrderForRefundExternalId: '',
      });
    });
    this.collectOrderVoucherForm.patchValue({ collectOrders: this.transferVoucherForCollectOrderDto.collectOrders });
  }

  getTransferAmount() {
    var expectedAmount = 0;

    for (var collectOrder of this.transferVoucherForCollectOrderDto.collectOrders) {
      expectedAmount += collectOrder.amount;
    }

    return expectedAmount;
  }
}
