<mf-form
  [formGroup]="naturalPersonForm"
  [entity]="naturalPersonDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-field-text formControlName="firstName" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="lastName" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="email" />
  </mf-column>

  <mf-id-document
    [personType]="personType.NaturalPerson"
    formControlName="idDocument" />

  <mf-address formControlName="address" />

  @if (isSuperAdmin) {
  <mf-column>
    <mf-field-check formControlName="isExemptFromOriginDeclaration" />
  </mf-column>
  }
</mf-form>
