import { Directive, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';
import { FiltersDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { SearchOrderDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SearchOrderDto';
import { SuggestResultDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SuggestResultDto';
import { SuggestResultItemDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SuggestResultItemDto';
import { SuggestSearchDto } from '../../../../../ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { ModalService } from '../../services/modal.service';

@Directive()
export abstract class AutoCompleteBaseEntityComponent<TEntity, TFilters extends FiltersDto>
  extends FieldMatBaseComponent<TEntity>
  implements OnInit
{
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef, protected _modalService: ModalService) {
    super(ngControl, elRef);

    if (this.defaultOrderBy) {
      this.orderBy.push({ columnName: this.defaultOrderBy, ascendent: true });
    }
  }

  defaultOrderBy: string;
  filters: TFilters = {} as any;
  orderBy: SearchOrderDto[] = [];

  @Input()
  hideAddButton = false;
  createNewComponent: any = null;

  @Output()
  itemIdChanged = new EventEmitter<string | null>();

  ngOnInit(): void {
    this.setDefaultFilters();
  }

  override get controlType(): string {
    return 'field-complete-base-entity';
  }

  abstract endpointSuggest: (
    search: SuggestSearchDto<TFilters>,
    httpApiRequestOptions: IHttpApiRequestOptions
  ) => Promise<SuggestResultDto<TEntity>>;

  setDefaultFilters() {}

  async selectedItemChange(item: SuggestResultItemDto<TEntity> | null) {
    this.value = item?.entity as any;
    this.itemIdChanged.emit((item?.entity as any)?.id);
  }

  /// Override this method to pass additional data to the create new entity modal
  getCreateNewEntityOpenData() {
    return {};
  }

  createNewClick() {
    this._modalService.openLargeModal(this.createNewComponent, {
      openData: this.getCreateNewEntityOpenData(),
      onCloseSuccess: (result) => {
        this.selectedItemChange({ entity: result } as any);
      },
    });
  }
}
