import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { IGridFilter } from '../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-filter';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModelValueBaseComponent } from '../../../../libs/utils/ng-shared-components/src/lib/model-value-base.component';
import { FilterEntityType } from './view-models/FilterEntityType';

@Component({
  selector: 'mf-pspc-grid-filter-entity',
  templateUrl: './grid-filter-entity.component.html',
  styleUrls: ['./grid-filter-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MiPspcGridFilterEntityComponent),
      multi: true,
    },
  ],
})
export class MiPspcGridFilterEntityComponent extends ModelValueBaseComponent<any> {
  gridFilter: IGridFilter;
  filterEntityType = FilterEntityType;

  @Output()
  onChange = new EventEmitter<any>();

  onModelChange(value: any) {
    this.onChange.emit(value);
  }
}
