import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { numericString } from '../../../../../../libs/utils/ts-shared/src';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { GatewayDto, GatewayDtoFormGroup } from '../../../api/dtos/GatewayDto';
import { ConfigurationDto } from '../../../api/dtos/ConfigurationDto';
import { ApiClientAdminConfiguration } from '../../../api/endpoints/ApiClientAdminConfiguration';
import { ApiAdminConfiguration } from '../../../api/endpoints/ApiAdminConfiguration';

@Component({
  selector: 'mf-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss'],
})
export class GatewayComponent {
  constructor(private _cfb: CustomFormBuilder, private _configurationApiAdmin: ApiAdminConfiguration) {}
  @Input()
  gatewayDto: GatewayDto;
  configurationDto: ConfigurationDto;

  @Output()
  save = new EventEmitter<GatewayDto>();

  numericRegex = new RegExp(numericString);

  gatewayForm = this._cfb.group<GatewayDtoFormGroup>({
    configurationId: [null, Validators.required, ''],
    configuration: [null, Validators.required, 'Configuración'],
    name: [null, Validators.required, 'Nombre'],
    country: ['', Validators.required, 'País'],
  });

  ngOnInit(): void {
    this.gatewayForm.patchValue(this.gatewayDto);
    this.loadConfiguration();
  }

  async loadConfiguration() {
    this.configurationDto = await this._configurationApiAdmin.getById(this.gatewayDto.configurationId);
    this.gatewayForm.patchValue({ configuration: this.configurationDto });
  }

  enumName = enumName_mi_shared;
  enumNameModule = moduleName.shared;
}
