import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../../../libs/utils/auth-shared/src/lib/services/login.service';

@Component({
  standalone: false,
  selector: 'mifinanzas-login-google-callback',
  templateUrl: './login-google-callback.component.html',
  styleUrls: ['./login-google-callback.component.scss'],
})
export class LoginGoogleCallbackComponent {
  constructor(private _loginService: LoginService, private _router: Router, private _activatedRoute: ActivatedRoute) {
    this._loginService.loginWithToken(this._activatedRoute.snapshot.queryParams['token']);
    this._router.navigate([this._activatedRoute.snapshot.queryParams['returnUrl'] ?? '/']);
  }
}
