import { Component, Input, OnInit, signal, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { NavigationService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { AccreditationsByAccountDto } from '../../../api/dtos/AccreditationsByAccountDto';
import { AccreditationsByAccountFiltersDto } from '../../../api/dtos/AccreditationsByAccountFiltersDto';
import { ApiAdminAccreditationsByAccount } from '../../../api/endpoints/ApiAdminAccreditationsByAccount';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { AccreditationsByAccountStatus } from '../../../api/enums/AccreditationsByAccountStatus';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { AccreditationsByAccountDetailsComponent } from './accreditations-by-account-details.component';
import { AccreditationsByAccountVoucherCreateComponent } from './accreditations-by-account-voucher/accreditations-by-account-voucher-create.component';
import { TransferDetailsComponent } from '../payment-methods/transfer/transfer-details.component';
import { AccreditationsByAccountCancelComponent } from './accreditations-by-account-voucher/accreditations-by-account-cancel.component';
import { ApiAdminAccreditation } from '../../../api/endpoints/ApiAdminAccreditation';
import { FilterEntityType } from '../../view-models/FilterEntityType';

@Component({
  selector: 'mf-accreditations-by-account-grid',
  templateUrl: './accreditations-by-account-grid.component.html',
  styleUrls: ['./accreditations-by-account-grid.component.scss'],
})
export class AccreditationsByAccountGridComponent implements OnInit {
  constructor(
    private _apiAdminAccreditationsByAccount: ApiAdminAccreditationsByAccount,
    private _apiAdminAccreditation: ApiAdminAccreditation,
    private _modalService: ModalService,
    private _navigationService: NavigationService,
    private _activatedRoute: ActivatedRoute
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() showCreateButton = false;
  @Input() disableCreateButton = true;

  initialFilters = signal<AccreditationsByAccountFiltersDto | null>(null);
  accreditationByAccountStatus = AccreditationsByAccountStatus;

  mustIncludeFields = [
    nameof<AccreditationsByAccountDto>((e) => e.accreditationId),
    nameof<AccreditationsByAccountDto>((e) => e.bankFileUrl),
  ];

  columns: IGridColumn<AccreditationsByAccountDto>[] = [
    {
      header: 'Acreditación',
      field: nameof<AccreditationsByAccountDto>((e) => e.accreditation.accreditationNumber),
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((e) => e.accreditation),
        type: FilterType.Entity,
        entityType: FilterEntityType.Accreditation,
      },
      width: 10,
    },
    {
      header: 'Fecha',
      field: nameof<AccreditationsByAccountDto>((e) => e.accreditation.date),
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((e) => e.accreditationDate),
        type: FilterType.Date,
      },
      width: 20,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      header: 'Estado',
      field: nameof<AccreditationsByAccountDto>((a) => a.status),
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((e) => e.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.AccreditationsByAccountStatus,
      },
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.AccreditationsByAccountStatus,
      },
    },
    {
      header: 'Banco de Origen',
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((a) => a.sourceBank),
        type: FilterType.Entity,
        entityType: FilterEntityType.Bank,
      },
      field: nameof<AccreditationsByAccountDto>((a) => a.sourceAccount.bank.name),
      width: 10,
    },
    {
      header: 'Tipo',
      field: nameof<AccreditationsByAccountDto>((a) => a.type),
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((a) => a.type),
        type: FilterType.Enum,
        enumName: enumName_mi_pspc.AccreditationsByAccountType,
      },
      width: 15,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.AccreditationsByAccountType,
      },
    },
    {
      header: 'Moneda',
      field: nameof<AccreditationsByAccountDto>((a) => a.sourceAccount.currency),
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((a) => a.sourceAccountCurrencyList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      header: 'Monto transferido',
      field: nameof<AccreditationsByAccountDto>((a) => a.amountTransferred),
      filter: {
        propertyName: nameof<AccreditationsByAccountFiltersDto>((a) => a.amountTransferred),
        type: FilterType.NumericRange,
      },
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.details,
      onClick: (item: AccreditationsByAccountDto) => {
        this._modalService.openRightModal(AccreditationsByAccountDetailsComponent, { openData: item.id });
      },
    },
    {
      label: 'Descargar archivo',
      icon: Icons.download,
      hide: (accreditationsByAccountDto: AccreditationsByAccountDto) =>
        accreditationsByAccountDto.status !== AccreditationsByAccountStatus.Pending,
      onClick: (accreditationsByAccountDto: AccreditationsByAccountDto) => {
        this.downloadFile(accreditationsByAccountDto);
      },
    },
    {
      label: 'Comprobante',
      icon: Icons.upload,
      hide: (accreditationsByAccountDto: AccreditationsByAccountDto) =>
        accreditationsByAccountDto.status !== AccreditationsByAccountStatus.FileDownloaded,
      onClick: (accreditationsByAccountDto: AccreditationsByAccountDto) => {
        this._modalService.openNormalModal(AccreditationsByAccountVoucherCreateComponent, {
          openData: {
            accreditationsByAccountDto: accreditationsByAccountDto,
          },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Cancelar',
      icon: Icons.delete,
      hide: (accreditationsByAccountDto: AccreditationsByAccountDto) =>
        accreditationsByAccountDto.status == AccreditationsByAccountStatus.Cancelled ||
        accreditationsByAccountDto.status == AccreditationsByAccountStatus.Completed,
      onClick: (accreditationsByAccountDto: AccreditationsByAccountDto) => {
        this._modalService.openNormalModal(AccreditationsByAccountCancelComponent, {
          openData: {
            accreditationsByAccountDto: accreditationsByAccountDto,
          },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Ver transferencias',
      icon: Icons.list,
      onClick: (item: AccreditationsByAccountDto) => {
        this._modalService.openRightModal(TransferDetailsComponent, {
          openData: {
            accreditationsByAccountId: item.id,
          },
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<AccreditationsByAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminAccreditationsByAccount.getPaged(search, httpApiRequestOptions);

  ngOnInit(): void {
    const filters = new AccreditationsByAccountFiltersDto();
    if (!this._activatedRoute.snapshot.queryParams.accreditationId) {
      this.initialFilters.set(filters);
      return;
    }

    const request = async () => {
      const accreditation = await this._apiAdminAccreditation.getById(this._activatedRoute.snapshot.queryParams.accreditationId);
      filters.accreditationId = accreditation.id;
      filters.accreditation = accreditation;
      this.initialFilters.set(filters);
    };
    request();
  }

  async downloadFile(accreditationsByAccountDto: AccreditationsByAccountDto) {
    await this._apiAdminAccreditationsByAccount.setAsFileDownloaded(accreditationsByAccountDto.id);
    this._navigationService.navigateNewTab(accreditationsByAccountDto.bankFileUrl);
    this.grid.reloadPage();
  }
}
