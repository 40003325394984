import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import {} from '@angular/material/dialog';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { AppUserCreateDto, AppUserCreateDtoFormGroup } from '../../../api/dtos/AppUserCreateDto';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { PersonType } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';

@Component({
  standalone: false,
  selector: 'mf-app-user',
  templateUrl: './app-user.component.html',
  styleUrls: ['./app-user.component.scss'],
})
export class AppUserComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  appUserCreateDto: AppUserCreateDto;

  @Output()
  save = new EventEmitter<AppUserCreateDto>();

  @Output()
  close = new EventEmitter();

  personType = PersonType;

  userForm = this._cfb.group<AppUserCreateDtoFormGroup>({
    id: [GUID_EMPTY],
    naturalPerson: [null, Validators.required, 'Persona'],
    password: ['', Validators.required, 'Contraseña'],
    passwordConfirm: [
      '',
      [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {
          return control.value != control.parent?.get('password')?.value
            ? {
                forbiddenName: {
                  displayMessage: 'La confirmación de la contraseña no coincide con la contraseña',
                },
              }
            : null;
        },
      ],
      'Confirmación de contraseña',
    ],
  });
}
