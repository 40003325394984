import { Component } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TemplateDtoFormGroup } from '../../../../../../libs/utils/ng-shared-components/src/lib/templates/dtos/TemplateDto';
import { TemplatePdfDto, TemplatePdfDtoFormGroup } from './dtos/TemplatePdfDto';
import { moduleName } from '../../../../ts-shared/src/lib/module-names';
import { enumName_templates } from './enums/_enumName.templates';
import { PaperSize } from './enums/PaperSize';

@Component({
  selector: 'mf-template-header-info-pdf',
  templateUrl: './template-header-info-pdf.component.html',
  styleUrls: ['./template-header-info-pdf.component.scss'],
})
export class TemplateHeaderInfoPdfComponent {
  constructor(private _formGroup: FormGroupDirective) {}

  templateForm: FormGroup<TemplatePdfDtoFormGroup>;

  enumName = enumName_templates;
  moduleName = moduleName;
  paperSize = PaperSize;

  ngOnInit(): void {
    this.templateForm = this._formGroup.control as FormGroup<TemplateDtoFormGroup>;
  }

  get templatePdfDto() {
    return this.templateForm.value as TemplatePdfDto;
  }
}
