import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { LoginService } from '../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { NotificationService } from '../../../../../../libs/utils/ng-shared-components/src';
import { INotificationTrayService } from '../../../../../../libs/utils/ng-shared-components/src/lib/notification-tray/INotificationTray';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { OperationDto, OperationDtoFormGroup } from '../../../api/dtos/OperationDto';
import { ApiClientAdminConfiguration } from '../../../api/endpoints/ApiClientAdminConfiguration';
import { ApiClientAdminOperation } from '../../../api/endpoints/ApiClientAdminOperation';
import { Currency } from '../../../api/enums/Currency';
import { OperationStatus } from '../../../api/enums/OperationStatus';
import { Roles } from '../../../api/enums/Roles';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { OperationService } from './operation.service';
import { ApiClientAdminClient } from '../../../api/endpoints/ApiClientAdminClient';

@Component({
  selector: 'mf-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss'],
})
export class OperationComponent implements OnInit {
  constructor(
    private _cfb: CustomFormBuilder,
    private _modalService: ModalService,
    private _operationService: OperationService,
    private _apiClientAdminOperation: ApiClientAdminOperation,
    private _notificationService: NotificationService,
    private _apiClientAdminConfiguration: ApiClientAdminConfiguration,
    private _loginService: LoginService,
    private _apiClientAdminClient: ApiClientAdminClient,
    @Inject('INotificationTrayService') public notificationTrayService: INotificationTrayService
  ) {
    this.setClient();
    this.operationDto = this._operationService.operationDto;
  }

  ngOnInit(): void {
    this._operationService.updatedOperationDto.subscribe((operationDto: OperationDto) => {
      this.operationDto = operationDto;
    });
    this.loadCurrencyList();
  }

  operationDto: OperationDto;
  @Input() textButton: string;
  @Input() isEditing = false;

  @Output()
  save = new EventEmitter<OperationDto>();

  currencyList: Currency[];
  operationStatus = OperationStatus;
  isSuperAdmin = false;
  clientName: string;

  operationForm = this._cfb.group<OperationDtoFormGroup>({
    id: [GUID_EMPTY],
    clientId: [null],
    client: [null, , 'Cliente'],
    currency: [null, Validators.required, 'Moneda'],
    reason: [null, Validators.required, 'Motivo de la operación'],
  });

  enumName = enumName_mi_pspc;

  closeModal() {
    this._modalService.closeAsSuccess('');
  }

  async executeOperation() {
    this._modalService.openYesOrNoModal('¿Está seguro desea empezar a cobrar?', {
      onCloseSuccess: async () => {
        const operation = await this._apiClientAdminOperation.completeDraftAndStartCashing(this.operationDto.id);

        if (operation.status === OperationStatus.Cashing) {
          this._notificationService.showSuccess('La operación comenzó a cobrar con éxito.');
        }

        if (operation.status === OperationStatus.UnderReviewBeforeCashing) {
          this._notificationService.showWarning('La operación se encuentra en revisión, revise incidentes');
        }

        this.notificationTrayService.loadNotifications();
        this._operationService.reloadOperation(this.operationDto.id);
      },
    });
  }

  async updateAllowPayments() {
    this._modalService.openYesOrNoModal('Si habilitas los pagos, ya no podrás agregar más ordenes ¿Esta seguro?', {
      onCloseSuccess: async () => {
        await this._apiClientAdminOperation.closeOperationToNewOrders(this.operationDto.id);
        this._operationService.reloadOperation(this.operationDto.id);
      },
    });
  }

  async updateOperation() {
    await this._apiClientAdminOperation.updateDraftOrders(this.operationDto.id);
    this._notificationService.showSuccess('Operación actualizada');
    this._operationService.reloadOperation(this.operationDto.id);
  }

  async loadCurrencyList() {
    this.currencyList = await this._apiClientAdminConfiguration.getCurrencyList();
  }

  async setClient() {
    this.isSuperAdmin = this._loginService.userIsInRole([Roles[Roles.SuperAdmin]]);

    if (!this.isSuperAdmin) {
      this.clientName = this._loginService.loggedClientName;
      this.operationForm.patchValue({
        clientId: this._loginService.loggedClientId,
        client: await this._apiClientAdminClient.getCurrentClient(),
      });
    }
  }
}
