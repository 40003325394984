// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';

export class ConciliationFiltersDto extends FiltersDto {
  date: FilterRangeDateDto;
}

export interface ConciliationFiltersDtoFormGroup extends FiltersDtoFormGroup {
  date?: any;
}
