import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { PaymentOrderDto } from '../../../api/dtos/PaymentOrderDto';
import { PaymentOrderFiltersDto } from '../../../api/dtos/PaymentOrderFiltersDto';
import { ApiClientAdminPaymentOrder } from '../../../api/endpoints/ApiClientAdminPaymentOrder';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';

@Component({
  selector: 'mf-autocomplete-payment-order',
  templateUrl: './autocomplete-payment-order.component.html',
  styleUrls: ['./autocomplete-payment-order.component.scss'],
})
export class AutocompletePaymentOrderComponent extends AutoCompleteBaseEntityComponent<PaymentOrderDto | null, PaymentOrderFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiClientAdminPaymentOrder: ApiClientAdminPaymentOrder
  ) {
    super(ngControl, elRef);
  }

  override defaultOrderBy = nameof<PaymentOrderDto>((c) => c.amount);

  override endpointSuggest = (search: SuggestSearchDto<PaymentOrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminPaymentOrder.suggest(search, httpApiRequestOptions);
}
