import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiAdminBankMovement } from '../../../../api/endpoints/ApiAdminBankMovement';
import { GridComponent, IGridActionButton } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { BankMovementFiltersDto } from '../../../../api/dtos/BankMovementFiltersDto';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { BankMovementDto } from '../../../../api/dtos/BankMovementDto';
import { nameof } from 'ts-simple-nameof';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-bank-movement-grid',
  templateUrl: './bank-movement-grid.component.html',
  styleUrls: ['./bank-movement-grid.component.scss'],
})
export class BankMovementGridComponent implements OnInit {
  constructor(public _apiAdminBankMovement: ApiAdminBankMovement, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;

  filters: BankMovementFiltersDto;

  ngOnInit(): void {
    this.filters = new BankMovementFiltersDto();
  }
  columns: IGridColumn<BankMovementDto>[] = [
    {
      field: nameof<BankMovementDto>((bm) => bm.gatewayBankAccount.accountNumber),
      header: 'Cuenta',
      width: 20,
    },
    {
      field: nameof<BankMovementDto>((bm) => bm.createdDate),
      header: 'Fecha',
      width: 20,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<BankMovementDto>((bm) => bm.movementType),
      header: 'Tipo',
      width: 5,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
    { field: nameof<BankMovementDto>((bm) => bm.conciliationRowId), header: 'DaMap Id', width: 10 },
    { field: nameof<BankMovementDto>((bm) => bm.conciliationId), header: 'DaMap Conc.', width: 10 },
    {
      field: nameof<BankMovementDto>((bm) => bm.reference),
      header: 'Referencia',
      width: 50,
    },
    {
      field: nameof<BankMovementDto>((bm) => bm.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<BankMovementDto>((bm) => bm.amount),
      header: 'Monto',
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.list,
      onClick: (bankMovementDto: BankMovementDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: bankMovementDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
  ];

  gridActionButtons: IGridActionButton[] = [
    {
      text: 'Actualizar movimientos',
      onClick: () => this.getBankMovements,
    },
  ];

  apiEndpoint = (search: PagedSearchDto<BankMovementFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminBankMovement.getPaged(search, httpApiRequestOptions);

  async getBankMovements() {
    await this._apiAdminBankMovement.getFromDaMap();
    this.grid.reloadPage();
  }
}
