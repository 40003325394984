// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PaymentTransactionFiltersDto, PaymentTransactionFiltersDtoFormGroup } from './PaymentTransactionFiltersDto';
import { AccreditationsByAccountDto } from './AccreditationsByAccountDto';

export class TransferFiltersDto extends PaymentTransactionFiltersDto {
  accreditationConsolidationNumber: number | null = null;
  accreditationOriginNumber: number | null = null;
  accreditationByAccountId: string | null = null;
  accreditationByAccount: AccreditationsByAccountDto;
}

export interface TransferFiltersDtoFormGroup extends PaymentTransactionFiltersDtoFormGroup {
  accreditationConsolidationNumber?: any;
  accreditationOriginNumber?: any;
  accreditationByAccountId?: any;
  accreditationByAccount?: any;
}
