import { Component, ElementRef, Input, OnChanges, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { BankAccountDto } from '../../../api/dtos/BankAccountDto';
import { BankAccountFiltersDto } from '../../../api/dtos/BankAccountFiltersDto';
import { ApiClientAdminBankAccount } from '../../../api/endpoints/ApiClientAdminBankAccount';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { Currency } from '../../../api/enums/Currency';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  selector: 'mf-autocomplete-bank-account',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteBankAccountComponent
  extends AutoCompleteBaseEntityComponent<BankAccountDto | null, BankAccountFiltersDto>
  implements OnChanges
{
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiClientAdminBankAccount: ApiClientAdminBankAccount,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
  }

  override defaultOrderBy = nameof<BankAccountDto>((p) => p.accountNumber);

  @Input()
  personId: string | null;

  @Input()
  currency?: Currency;

  override endpointSuggest = (search: SuggestSearchDto<BankAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminBankAccount.suggest(search, httpApiRequestOptions);

  override setDefaultFilters() {
    this.filters.personId = this.personId ? this.personId : null;
    if (this.currency) {
      this.filters.currencyList = [];
      this.filters.currencyList.push(this.currency);
    }
  }

  ngOnChanges() {
    this.setDefaultFilters();

    if (
      this.value &&
      (this.value.personId != this.filters.personId || (this.value.currency && !this.filters.currencyList.includes(this.value.currency)))
    ) {
      this.selectedItemChange(null);
    }
  }
}
