import { Injectable } from '@angular/core';
import { NotificationService } from '../../../../../../../libs/utils/ng-shared-components/src';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminIssue } from '../../../../api/endpoints/ApiAdminIssue';
import { IssueFiltersDto } from '../../../../api/dtos/IssueFiltersDto';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { RelatedEntityIssuePopupComponent } from './related-entity-issue-popup.component';
import { IssueDto } from '../../../../api/dtos/IssueDto';
import { IssueStatus } from '../../../../api/enums/IssueStatus';

@Injectable()
export class RelatedEntityIssuePopupService {
  constructor(
    private _apiAdminIssue: ApiAdminIssue,
    private _notificationService: NotificationService,
    private _modalService: ModalService
  ) {}

  public async checkRelatedIssuesAndOpenPopup(entityId: string) {
    const search = new PagedSearchDto<IssueFiltersDto>();
    search.filters = new IssueFiltersDto();
    search.filters.entityId = entityId;
    search.filters.statusList.push(IssueStatus.New);
    search.filters.statusList.push(IssueStatus.Ongoing);
    const httpApiRequestOptions = new HttpApiRequestOptions();
    const pagedResult = await this._apiAdminIssue.getPaged(search, httpApiRequestOptions);

    if (pagedResult.pageItemList.length > 0) {
      const items: IssueDto[] = [];
      pagedResult.pageItemList.forEach((item) => {
        items.push(item.item);
      });
      this._modalService.openLargeModal(RelatedEntityIssuePopupComponent, {
        openData: {
          entityId: entityId,
          items: items,
        },
        onCloseSuccess: () => {
          this._notificationService.showSuccess('Incidentes resueltos');
        },
      });
    }
  }
}
