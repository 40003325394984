import { Component, ElementRef, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';
import NumberFormat = Intl.NumberFormat;

@Component({
  standalone: false,
  selector: 'mf-field-numeric',
  templateUrl: 'field-numeric.component.html',
  styleUrls: ['field-numeric.component.scss'],
})
export class FieldNumericComponent extends FieldMatBaseComponent<number> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }

  override get controlType(): string {
    return 'field-numeric';
  }

  private _format: NumberFormat;
  private _partialUpdateTimeOut: any;
  public hasFocus: boolean;
  public flagBlur: boolean;
  public textValue: string | null = null;

  @Input() allowNulls = false;
  @Input() intOnly = false;
  @Input() decimals = 2;
  @Input() controlName: string;
  @Output() partialUpdate = new EventEmitter<number>();
  @Output() blur = new EventEmitter<any>();

  private setFormat(): void {
    if (this.intOnly) {
      this._format = new Intl.NumberFormat('es-UY', {
        style: 'decimal',
        minimumFractionDigits: 0,
      });
      return;
    }
    this._format = new Intl.NumberFormat('es-UY', {
      style: 'decimal',
      minimumFractionDigits: this.decimals,
    });
  }

  private setTextValue(value: number | null): void {
    if (value == null || !this._format) {
      this.textValue = '';
      return;
    }
    this.textValue = this._format.format(value);
  }

  private replaceCommaToDot(value: string): string {
    if (value.indexOf(',') !== -1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] === ',') {
          value = value.replace(',', '.');
        }
      }
    }
    return value;
  }

  private replaceDotToSpace(value: string): string {
    if (value.indexOf('.') !== -1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] === '.') {
          value = value.replace('.', '');
        }
      }
    }
    return value;
  }

  public ngOnInit(): void {
    this.setFormat();
    this.setTextValue(this.value);
  }

  protected override onValueAssigned(val: number | null): void {
    if (val !== null && isNaN(val)) {
      val = null;
    }

    if (val == null && !this.allowNulls) {
      val = 0;
    }

    this.assignValue(val as any);
    if (this.flagBlur) {
      this.flagBlur = false;
      this.hasFocus = false;
    }

    if (this.hasFocus) {
      this.textValue = this.value == null ? null : this.value.toString();
    } else {
      this.setTextValue(this.value);
    }
  }

  public override onWriteValue(val: any): void {
    this.onValueAssigned(val);
  }

  public onBlur(): void {
    this.flagBlur = true;
    if (this.allowNulls && (this.textValue == null || this.textValue === '')) {
      this.value = null as any;
      this.blur.emit();
      this.onTouched();
      return;
    }

    if (this._partialUpdateTimeOut) {
      clearTimeout(this._partialUpdateTimeOut);
    }
    this.value = parseFloat(this.textValue as any);
    this.partialUpdate.emit(this.value);
    this.blur.emit();
    this.onTouched();
  }

  public onFocus($event: any): void {
    this.hasFocus = true;
    this.textValue = this.value == null ? (this.textValue = null) : this.value.toString();
    setTimeout(() => {
      if (this.hasFocus) {
        $event.target.select();
      }
    }, 10);
  }

  onCopy(item: any) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/plain', item);
      e.preventDefault();
      document.removeEventListener('copy', null as any);
    });
    navigator.clipboard.writeText('copy');
  }

  public onPaste(event: ClipboardEvent) {
    event.preventDefault();

    if (!event.clipboardData) {
      return;
    }

    let pastedText = event.clipboardData.getData('text');

    pastedText = this.replaceDotToSpace(pastedText);
    pastedText = this.replaceCommaToDot(pastedText);

    if (this.intOnly) {
      const parsedInt = parseInt(pastedText, 10);
      if (!isNaN(parsedInt)) {
        this.value = parsedInt;
      }
      return;
    }

    const parsedNumber = parseFloat(pastedText);
    if (!isNaN(parsedNumber)) {
      this.value = parsedNumber;
    }
  }

  public onKeyDown(event: any): void {
    if (this._partialUpdateTimeOut) {
      clearTimeout(this._partialUpdateTimeOut);
    }

    this._partialUpdateTimeOut = setTimeout(() => {
      this.partialUpdate.emit(parseFloat(this.textValue as any));
    }, 1000);

    if (event.code === 'KeyV' && event.ctrlKey) {
      return;
    }
    if (event.ctrlKey && event.keyCode === 67) {
      this.onCopy(this.textValue);
      return;
    }
    if (
      event.code === 'Backspace' ||
      event.code === 'Delete' ||
      event.code === 'Tab' ||
      event.code === 'Escape' ||
      event.code === 'Enter' ||
      event.code === 'Home' ||
      event.code === 'End' ||
      event.code === 'ArrowDown' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowRight' ||
      event.code === 'ArrowLeft' ||
      event.keyCode === 8
    ) {
      return;
    }

    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
      return;
    }

    if (event.code === 'NumpadDecimal' || event.code === 'Period') {
      if (this.intOnly) {
        event.preventDefault();
        return;
      }

      if (this.textValue != null && this.textValue.indexOf('.') !== -1) {
        event.preventDefault();
        return;
      }

      return;
    }

    if (event.code === 'NumpadSubtract') {
      event.preventDefault();
      if (this.textValue != null && this.textValue.indexOf('-') !== -1) {
        return;
      }

      this.textValue = '-' + this.textValue;
      return;
    }

    event.preventDefault();
  }

  public onEnter($event: any) {
    $event.target.blur();
  }
}
