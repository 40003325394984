<ng-container [formGroup]="templateForm">
  <mf-column [md]="2">
    @if (templateDto.group == templateGroup.Generic) {
    <mf-field-select
      formControlName="genericType"
      [enumName]="enumName.TemplateGenericType"
      [enumNameModule]="moduleName.templates"
      (ngModelChange)="genericTypeChange()" />
    } @else {
    <mf-autocomplete-template-type
      formControlName="type"
      [templateGroup]="templateForm.controls.group.value"
      (itemIdChanged)="templateForm.patchValue({ typeId: $event }); templateTypeChanged.emit($event)"
      [readOnly]="!!templateForm.controls.typeId.value" />
    }
  </mf-column>
  <mf-column [md]="2">
    <mf-field-text formControlName="name" />
  </mf-column>
  @if (templateDto.group == templateGroup.Generic) {
  <mf-column [md]="1">
    <mf-field-text formControlName="code" />
  </mf-column>
  <mf-column [md]="1">
    <mf-field-select
      formControlName="restrictTo"
      [enumName]="enumName.TemplateGroup"
      [enumNameModule]="moduleName.templates" />
  </mf-column>
  } @if (templateDto.group == templateGroup.Pdf) {
  <mf-template-header-info-pdf />
  } @if (templateDto.group == templateGroup.Email) {
  <mf-template-header-info-email />
  }
</ng-container>
