import { Component, HostBinding, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'mf-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class LayoutColumnComponent {
  @Input() md: number = 4;
  @Input()
  align: 'left' | 'center' | 'right' = 'left';
  @Input()
  isRowButtons = false;
  @Input()
  label: string = '';
  @Input()
  noPadding: boolean = false;
  @Input()
  minHeight: number;

  @HostBinding('class')
  get class(): string {
    return (
      `col-md-${this.md} ` +
      (this.noPadding ? 'no-padding ' : ' ' + (this.isRowButtons ? 'row-buttons ' : ' ') + (this.align ? 'text-align-' + this.align : ''))
    );
  }

  @HostBinding('style')
  get style(): string {
    return this.minHeight ? 'min-height: ' + this.minHeight + 'px' : '';
  }
}
