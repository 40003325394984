import { Component, ElementRef, Inject, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IHttpApiRequestOptions } from '../IHttpApiRequestOptions';
import { TemplateTypeDto } from './dtos/TemplateTypeDto';
import { TemplateGroup } from './enums/TemplateGroup';
import { TemplateTypeFiltersDto } from './dtos/TemplateTypeFiltersDto';
import { AutoCompleteBaseEntityComponent } from '../fields/auto-complete/auto-complete-base-entity.component';
import { IApiTemplateType } from './api/IApiTemplateType';
import { SuggestSearchDto } from '../../../../ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { ModalService } from '../services/modal.service';

@Component({
  standalone: false,
  selector: 'mf-autocomplete-template-type',
  templateUrl: './autocomplete-template-type.component.html',
  styleUrls: ['./autocomplete-template-type.component.scss'],
})
export class AutocompleteTemplateTypeComponent extends AutoCompleteBaseEntityComponent<TemplateTypeDto | null, TemplateTypeFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    @Inject('IApiTemplateType') private _apiTemplate: IApiTemplateType,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
  }

  @Input()
  templateGroup: TemplateGroup;

  override setDefaultFilters() {
    this.filters.group = this.templateGroup;
  }

  override endpointSuggest = (suggestSearchDto: SuggestSearchDto<TemplateTypeFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) =>
    this._apiTemplate.suggest(suggestSearchDto, httpApiRequestOptions);
}
