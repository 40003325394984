import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { AppUserCreateDto } from '../../../api/dtos/AppUserCreateDto';
import { ApiAdminAppUser } from '../../../api/endpoints/ApiAdminAppUser';

@Component({
  standalone: false,
  selector: 'mf-app-user-create',
  templateUrl: './app-user-create.component.html',
  styleUrls: ['./app-user-create.component.scss'],
})
export class AppUserCreateComponent {
  constructor(private _apiAdminAppUser: ApiAdminAppUser, private _modalService: ModalService) {}

  async save(appUserDto: AppUserCreateDto) {
    await this._apiAdminAppUser.create(appUserDto);
    this._modalService.closeAsSuccess('El usuario se creó con éxito');
  }

  close() {
    this._modalService.closeAsSuccess('');
  }
}
