<button
  mat-raised-button
  [color]="color"
  [class.spinner]="isLoading"
  [disabled]="disabled || isLoading">
  @if (icon) {
  <mat-icon>{{ icon }}</mat-icon>
  }
  {{ text }}
</button>
