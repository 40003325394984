import { Component, Input } from '@angular/core';
import { enumName_templates } from '../enums/_enumName.templates';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { TemplateDesignFieldDto } from '../dtos/TemplateDesignFieldDto';
import { Icons } from '../../icon/icons';
import { TemplateDesignFieldType } from '../enums/TemplateDesignFieldType';

@Component({
  standalone: false,
  selector: 'mf-template-editor-field',
  templateUrl: './template-editor-field.component.html',
  styleUrls: ['./template-editor-field.component.scss'],
})
export class TemplateEditorFieldComponent {
  @Input()
  templateDesignFieldDto: TemplateDesignFieldDto;

  enumNameTemplates = enumName_templates;
  moduleName = moduleName;

  icons = Icons;

  get expandable() {
    return (
      this.templateDesignFieldDto.fieldType == TemplateDesignFieldType.RepeatableField ||
      this.templateDesignFieldDto.fieldType == TemplateDesignFieldType.RelatedSchema
    );
  }

  get expandIcon() {
    return this.templateDesignFieldDto.expanded ? Icons.expandLess : Icons.expandMore;
  }

  get icon() {
    return this.templateDesignFieldDto.fieldType == TemplateDesignFieldType.Field ? Icons.computer : Icons.expandLess;
  }
}
