// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum IssueType {
	UnconciliatedTransfer = 1,
	UnrecognizedBankMovement = 2,
	InvalidBankAccount = 3,
	InconsistentPerson = 4,
	KYC = 5,
	Others = 6,
	TransferCancelled = 7,
	TransferRejected = 8,
	SourceAccountRequired = 9,
	DestinationAccountRequired = 10,
}
