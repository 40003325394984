import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankAccountDto } from '../../../../api/dtos/BankAccountDto';
import { ApiClientAdminBankAccount } from '../../../../api/endpoints/ApiClientAdminBankAccount';

@Component({
  standalone: false,
  selector: 'mf-bank-account-create',
  templateUrl: './bank-account-create.component.html',
  styleUrls: ['./bank-account-create.component.scss'],
})
export class BankAccountCreateComponent {
  constructor(private _apiClientAdminBankAccount: ApiClientAdminBankAccount, private _modalService: ModalService) {}

  async save(bankAccountDto: BankAccountDto) {
    bankAccountDto = await this._apiClientAdminBankAccount.create(bankAccountDto);

    if (bankAccountDto.isVerified) {
      return this._modalService.closeAsSuccess('La cuenta se creó con éxito');
    }

    return this._modalService.closeWithWarnings('La cuenta se creó con éxito, requiere validación manual');
  }

  close() {
    this._modalService.closeAsSuccess('');
  }
}
