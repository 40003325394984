import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { INotificationTrayService } from '../../../../../libs/utils/ng-shared-components/src/lib/notification-tray/INotificationTray';
import { NotificationTrayDto } from '../../api/dtos/NotificationTrayDto';
import { ApiAdminNotification } from '../../api/endpoints/ApiAdminNotification';
import { AppRoute } from '../app.routes.path';
import { NavigationService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { IssueType } from '../../api/enums/IssueType';
import { ApiClientAdminPerson } from '../../api/endpoints/ApiClientAdminPerson';
import { PersonType } from '../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { IAutocompleteService } from '../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/IAutocomplete';
import { CompanyFiltersDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/CompanyFiltersDto';
import { SuggestResultDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto';
import { SuggestSearchDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { HttpApiRequestOptions } from '../../api/endpoints/HttpApiRequestOptions';
import { PersonFiltersDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/PersonFiltersDto';
import { ApiClientAdminCompany } from '../../api/endpoints/ApiClientAdminCompany';

@Injectable()
export class AutocompleteService implements IAutocompleteService {
  constructor(private _apiClientAdminCompany: ApiClientAdminCompany, private _apiClientAdminPerson: ApiClientAdminPerson) {}

  async suggestCompany(search: SuggestSearchDto<CompanyFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) {
    return await this._apiClientAdminCompany.suggest(search, httpApiRequestOptions);
  }

  async suggestPerson(
    search: SuggestSearchDto<PersonFiltersDto>,
    httpApiRequestOptions: HttpApiRequestOptions
  ): Promise<SuggestResultDto<any>> {
    return await this._apiClientAdminPerson.suggest(search, httpApiRequestOptions);
  }
}
