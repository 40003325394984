import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminCompany } from '../../../../api/endpoints/ApiClientAdminCompany';
import { CompanyDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';

@Component({
  selector: 'mf-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss'],
})
export class CompanyEditComponent {
  constructor(private _apiClientAdminCompany: ApiClientAdminCompany, private _modalService: ModalService) {
    this.load();
  }

  companyDto: CompanyDto;

  async load() {
    this.companyDto = await this._apiClientAdminCompany.getById(this._modalService.openData);
  }

  async save(company: CompanyDto) {
    await this._apiClientAdminCompany.edit(company);
    this._modalService.closeAsSuccess('La empresa se editó con éxito');
  }
}
