import { Component, signal } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { CustomFormBuilder } from '../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { AppRoute } from '../app.routes.path';

@Component({
  standalone: false,
  selector: 'mifinanzas-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(
    private _cfb: CustomFormBuilder,
    private _loginService: LoginService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  authForm = this._cfb.group({
    username: ['', [Validators.required], 'Usuario'],
    password: ['', [Validators.required], 'Contraseña'],
  });

  isLoading = false;
  errorMessage = signal('');
  appRoute = AppRoute;

  login() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const loginRequest = this.authForm.value;
    this._loginService.login(
      loginRequest.username!,
      loginRequest.password!,
      () => {
        this._router.navigate(['']).then();
      },
      (errorMessage: string) => {
        this.errorMessage.set(errorMessage);
        this.isLoading = false;
      }
    );
  }

  loginGoogle() {
    this._loginService.loginGoogle(this._activatedRoute.snapshot.queryParams['returnUrl'] ?? '');
  }
}
