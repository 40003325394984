<mf-page title="Agregar cuenta de destino">
  @if (paymentOrderDto) {
  <mf-form
    [formGroup]="paymentOrderForm"
    [entity]="paymentOrderDto"
    (save)="save($event)"
    [disableButton]="paymentOrderForm.controls.destinationBankAccountId == null">
    <mf-column-display label="Cliente">
      {{ paymentOrderDto.operation.client.name }}
    </mf-column-display>

    <mf-column-display label="Id externo">
      {{ paymentOrderDto.externalId }}
    </mf-column-display>

    <mf-column-display label="Operación">
      {{ paymentOrderDto.operation.operationNumber }}
    </mf-column-display>

    <mf-column-display label="Persona">
      {{ paymentOrderDto.person.fullName }}
    </mf-column-display>

    <mf-column-display label="Monto">
      {{ paymentOrderDto.amount }}
    </mf-column-display>

    <mf-row>
      <mf-autocomplete-bank-account
        formControlName="destinationBankAccount"
        [personId]="paymentOrderDto.personId"
        [currency]="paymentOrderDto.operation.currency"
        [includeAuthorized]="true"
        (itemIdChanged)="paymentOrderForm.patchValue({ destinationBankAccountId: $event })" />
    </mf-row>
  </mf-form>
  }
</mf-page>
