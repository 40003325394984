// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { NotificationDto } from "../dtos/NotificationDto";

@Injectable({providedIn: 'root'})
export class ApiAdminNotification {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    notificationDto: NotificationDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NotificationDto> {
    return this._httpApiClient.post("/admin/notification", notificationDto, httpApiRequestOptions);
  }

  reactivate(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/notification/reactivate/" + id, null, httpApiRequestOptions);
  }

}
