import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  standalone: false,
  selector: 'mf-transfer-popup',
  templateUrl: './transfer-popup.component.html',
  styleUrls: ['./transfer-popup.component.scss'],
})
export class TransferPopupComponent {
  constructor(modalService: ModalService) {
    this.accreditationOriginNumber = modalService.openData.accreditationOriginNumber;
    this.accreditationConsolidationNumber = modalService.openData.accreditationConsolidationNumber;
  }

  accreditationOriginNumber: number;
  accreditationConsolidationNumber: number;
}
