// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BankBranchDto } from "../dtos/BankBranchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminBankBranch {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    bankBranchDto: BankBranchDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankBranchDto> {
    return this._httpApiClient.post("/admin/bank-branch", bankBranchDto, httpApiRequestOptions);
  }

  edit(
    bankBranchDto: BankBranchDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankBranchDto> {
    return this._httpApiClient.put("/admin/bank-branch", bankBranchDto, httpApiRequestOptions);
  }

}
