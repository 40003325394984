<mf-page title="Actualizar Id externo">
  @if (paymentOrderDto) {
  <mf-form
    [formGroup]="paymentOrderForm"
    [entity]="paymentOrderDto"
    (save)="save($event)"
    [closeButtonShow]="true"
    (closeButtonClick)="closeModal()">
    <mf-column-display label="Cliente">
      {{ paymentOrderDto.operation.client.name }}
    </mf-column-display>

    <mf-column-display label="Persona">
      {{ paymentOrderDto.person.fullName }}
    </mf-column-display>

    <mf-column-display label="Monto">
      {{ paymentOrderDto.operation.currency | customCurrency }} {{ paymentOrderDto.amount }}
    </mf-column-display>

    <mf-column-display label="Id externo">
      {{ paymentOrderDto.externalId }}
    </mf-column-display>

    <mf-column [md]="4">
      <mf-field-text formControlName="externalId" />
    </mf-column>
  </mf-form>
  }
</mf-page>
