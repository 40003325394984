// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { FilterRangeNumericDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeNumericDto';
import { ClientDto } from './ClientDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';
import { BankDto } from './BankDto';

export class PaymentTransactionFiltersDto extends FiltersDto {
  amount: FilterRangeNumericDto;
  clientId: string | null = null;
  client: ClientDto;
  createdDate: FilterRangeDateDto;
  currencyList: number[] = [];
  bankSourceId: string | null = null;
  bankSource: BankDto;
  bankDestinationId: string | null = null;
  bankDestination: BankDto;
  destinationAccountId: string | null = null;
  destinationAccountHolderName: string = '';
  destinationAccountNumber: string = '';
  movementTypeList: number[] = [];
  reference: string = '';
  sourceAccountId: string | null = null;
  sourceAccountNumber: string = '';
  sourceAccountHolderName: string = '';
  statusList: number[] = [];
  status: number[] = [];
  types: number[] = [];
}

export interface PaymentTransactionFiltersDtoFormGroup extends FiltersDtoFormGroup {
  amount?: any;
  clientId?: any;
  client?: any;
  createdDate?: any;
  currencyList?: any;
  bankSourceId?: any;
  bankSource?: any;
  bankDestinationId?: any;
  bankDestination?: any;
  destinationAccountId?: any;
  destinationAccountHolderName?: any;
  destinationAccountNumber?: any;
  movementTypeList?: any;
  reference?: any;
  sourceAccountId?: any;
  sourceAccountNumber?: any;
  sourceAccountHolderName?: any;
  statusList?: any;
  status?: any;
  types?: any;
}
