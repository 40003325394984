<mf-page title="Importar Operaciones">
  <mf-row>
    <mf-link
      text="Descargar plantilla"
      target="_blank"
      (click)="downloadTemplate($event)" />
  </mf-row>
  <form [formGroup]="operationForm">
    <mf-column [md]="operationImportRequestResult ? 4 : 12">
      <mf-field-file-upload
        (uploadCompleted)="upload($event)"
        formControlName="importUrl"
        [endpointUpload]="endpoint" />
    </mf-column>
    @if(operationImportRequestResult) {
    <mf-column-display
      [md]="5"
      label="Motivo"
      >{{ operationImportRequestResult.operation.reason }}
    </mf-column-display>
    <mf-column-display
      [md]="3"
      label="Monto">
      {{ operationImportRequestResult.operation.currencyIsoCode }} {{ operationImportRequestResult.operation.amount }}
    </mf-column-display>
    }
  </form>

  @if(operationImportRequestResult) { @if(operationImportRequestResult.validationStatus == OrderRequestValidationStatus.Error &&
  operationImportRequestResult.operationValidation.length > 0) {
  <mf-row>
    <mf-alert type="danger">
      <ul>
        @for (reason of operationImportRequestResult.operationValidation; track reason.details) {
        <li>{{ reason.details }}</li>
        }
      </ul>
    </mf-alert>
  </mf-row>
  }

  <mf-row label="Operaciones de Cobro ({{ operationImportRequestResult.collectOrderValidation.length }})">
    <mf-grid
      [clientSideItems]="operationImportRequestResult.collectOrderValidation"
      [columns]="columns"
      [getRowClass]="getRowClass" />
  </mf-row>
  <mf-row-separator />
  <mf-row label="Operaciones de Pago ({{ operationImportRequestResult.paymentOrderValidation.length }})">
    <mf-grid
      [clientSideItems]="operationImportRequestResult.paymentOrderValidation"
      [columns]="columns"
      [getRowClass]="getRowClass" />
  </mf-row>

  @if(this.operationRequestResult && !this.operationRequestResult.isSuccess) {
  <mf-row><h2>Resultado de la importación</h2></mf-row>
  @if(operationRequestResult.operationValidation.length > 0) {
  <mf-row>
    <mf-alert type="danger">
      <ul>
        @for (reason of operationRequestResult.operationValidation; track reason.details) {
        <li>{{ reason.details }}</li>
        }
      </ul>
    </mf-alert>
  </mf-row>
  } @if(collectOrdersWithError.length > 0){
  <mf-row label="Operaciones de Cobro">
    <mf-grid
      [clientSideItems]="collectOrdersWithError"
      [columns]="columns"
      [getRowClass]="getRowClass" />
  </mf-row>
  } @if(paymentOrdersWithError.length > 0){
  <mf-row-separator />
  <mf-row label="Operaciones de Pago">
    <mf-grid
      [clientSideItems]="paymentOrdersWithError"
      [columns]="columns"
      [getRowClass]="getRowClass" />
  </mf-row>
  } }}

  <mf-row>
    @if(validationStatus == OrderRequestValidationStatus.Warning) {
    <mf-field-check
      [(ngModel)]="omitWarnings"
      label="Omitir advertencias y continuar con la importación" />
    } @if(validationStatus == OrderRequestValidationStatus.Error) {
    <mat-error>El archivo subido contiene errores, deben corregirse para poder crear la operación</mat-error>
    }
  </mf-row>

  <mf-column align="left">
    <mf-button-secondary
      text="Cerrar"
      (click)="closeModal()" />
  </mf-column>
  @if(operationImportRequestResult) {
  <mf-column
    [md]="8"
    align="right">
    <mf-button
      text="Confirmar"
      [disabled]="isUploadDisabled()"
      (click)="uploadOperation()" />
  </mf-column>
  }
</mf-page>
