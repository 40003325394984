import { Component, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent, RowClass } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { BankAccountDto } from '../../../../api/dtos/BankAccountDto';
import { PersonBankAccountDto, PersonBankAccountDtoFormGroup } from '../../../../api/dtos/PersonBankAccountDto';
import { PersonBankAccountFiltersDto } from '../../../../api/dtos/PersonBankAccountFiltersDto';
import { ApiAdminPersonBankAccount } from '../../../../api/endpoints/ApiAdminPersonBankAccount';
import { ApiClientAdminBankAccount } from '../../../../api/endpoints/ApiClientAdminBankAccount';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { NotificationService } from '../../../../../../../libs/utils/ng-shared-components/src';
import { IGridFilter } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-filter';

@Component({
  standalone: false,
  selector: 'mf-bank-account-add-associated-person',
  templateUrl: './bank-account-add-associated-person.component.html',
  styleUrls: ['./bank-account-add-associated-person.component.scss'],
})
export class BankAccountAddAssociatedPersonComponent {
  constructor(
    private _cfb: CustomFormBuilder,
    private _apiAdminPersonBankAccount: ApiAdminPersonBankAccount,
    private _apiClientAdminBankAccount: ApiClientAdminBankAccount,
    private _modalService: ModalService,
    private _notificationService: NotificationService
  ) {
    this.load(this._modalService.openData);
  }

  bankAccountDto: BankAccountDto;
  filter: PersonBankAccountFiltersDto;

  @ViewChild('grid')
  grid: GridComponent;

  globalFilters: IGridFilter[] = [
    {
      header: 'Incluir Inactivas',
      propertyName: nameof<PersonBankAccountFiltersDto>((b) => b.includeInactive),
      type: FilterType.Boolean,
    },
  ];

  columns: IGridColumn<ApiAdminPersonBankAccount>[] = [
    {
      field: nameof<PersonBankAccountDto>((p) => p.person.fullName),
      header: 'Persona asociada',
      width: 100,
      filter: {
        propertyName: nameof<PersonBankAccountFiltersDto>((p) => p.person),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<PersonBankAccountDto>((p) => p.bankAccount.holderName),
      header: 'Titular',
      width: 100,
      filter: {
        propertyName: nameof<PersonBankAccountFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<PersonBankAccountDto>((p) => p.bankAccount.accountNumber),
      header: 'Numero de cuenta',
      width: 100,
      filter: {
        propertyName: nameof<PersonBankAccountFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<PersonBankAccountDto>((p) => p.inactive),
      header: 'Inactiva',
      width: 20,
      cellOptions: { type: CellValueType.Boolean },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Desvincular persona',
      icon: Icons.edit,
      hide: (personBankAccount: PersonBankAccountDto) => personBankAccount.inactive,
      onClick: async (personBankAccount: PersonBankAccountDto) => {
        await this._apiAdminPersonBankAccount.delete(personBankAccount.id);
        this.grid.reloadPage();
      },
    },
    {
      label: 'Reactivar',
      icon: Icons.check,
      hide: (personBankAccount: PersonBankAccountDto) => !personBankAccount.inactive,
      onClick: (personBankAccount: PersonBankAccountDto) => {
        this._modalService.openYesOrNoModal({
          openData: {
            title:
              'Esta seguro que desea asociar a ' +
              personBankAccount.person.fullName +
              ' la cuenta ' +
              personBankAccount.bankAccount.accountNumber +
              '?',
          },
          onCloseSuccess: async () => {
            await this._apiAdminPersonBankAccount.reactivate(personBankAccount.id);
            this._notificationService.showSuccess('La persona ha sido asociada correctamente');
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  addAssociatedPersonForm = this._cfb.group<PersonBankAccountDtoFormGroup>({
    id: [GUID_EMPTY],
    personId: ['', Validators.required],
    person: [null, Validators.required, 'Persona'],
    bankAccountId: ['', Validators.required],
    bankAccount: [null, Validators.required, 'Cuenta'],
  });

  async load(id: string) {
    this.bankAccountDto = await this._apiClientAdminBankAccount.getById(id);
    this.addAssociatedPersonForm.patchValue({
      bankAccountId: this.bankAccountDto.id,
      bankAccount: this.bankAccountDto,
    });
    this.filter = new PersonBankAccountFiltersDto();
    this.filter.bankAccount = this.bankAccountDto;
    this.filter.includeInactive = true;
  }

  async save(personBankAccount: PersonBankAccountDto) {
    await this._apiAdminPersonBankAccount.create(personBankAccount);
    this.grid.reloadPage();
    this.addAssociatedPersonForm.reset({
      id: GUID_EMPTY,
      bankAccountId: this.bankAccountDto.id,
      bankAccount: this.bankAccountDto,
    });

    Object.keys(this.addAssociatedPersonForm.controls).forEach((key) => {
      this.addAssociatedPersonForm.get(key)?.setErrors(null);
    });
  }

  getRowClass(row: any) {
    if (!row.item.inactive) {
      return null;
    }
    return RowClass.Inactive;
  }

  apiEndpoint = (search: PagedSearchDto<PersonBankAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminPersonBankAccount.getPaged(search, httpApiRequestOptions);
}
