<mf-column [md]="6">
  <mf-field-numeric
    label="{{ gridFilter.header }} min"
    [allowNulls]="true"
    [decimals]="gridFilter.intOnly ? 0 : 2"
    [ngModel]="min"
    (ngModelChange)="onNgModelChangeMin($event)" />
</mf-column>
<mf-column [md]="6">
  <mf-field-numeric
    label="{{ gridFilter.header }} max"
    [allowNulls]="true"
    [decimals]="gridFilter.intOnly ? 0 : 2"
    [ngModel]="max"
    (ngModelChange)="onNgModelChangeMax($event)" />
</mf-column>
