<mf-page title="Filtros de búsqueda">
  @for (gridFilter of gridFiltersService.gridFilters; track $index) {
  <mf-grid-filter
    [gridFilter]="gridFilter"
    [ngModel]="gridFiltersService.editingFilters[gridFilter.propertyName]"
    (ngModelChange)="gridFiltersService.editingFilters[gridFilter.propertyName] = $event"
    (relatedIdChange)="onRelatedIdChange(gridFilter.propertyName, $event)" />
  }
  <mf-row-buttons>
    <mf-button-secondary
      text="Aplicar filtros"
      (click)="applyFiltersClick()" />
  </mf-row-buttons>
</mf-page>
