import { Component, Inject } from '@angular/core';
import { GUID_EMPTY } from '../../../../../../ts-shared/src/lib/utils-guid';
import { ModalService } from '../../../services/modal.service';
import { IApiTemplateGeneric } from '../../api/IApiTemplateGeneric';
import { TemplateDto } from '../../dtos/TemplateDto';
import { TemplateGenericDto } from '../../dtos/TemplateGenericDto';
import { TemplateGroup } from '../../enums/TemplateGroup';

@Component({
  standalone: false,
  selector: 'mf-template-generic-create',
  templateUrl: './template-generic-create.component.html',
  styleUrls: ['./template-generic-create.component.scss'],
})
export class TemplateGenericCreateComponent {
  constructor(@Inject('IApiTemplateGeneric') private _apiGenericTemplate: IApiTemplateGeneric, private _modalService: ModalService) {
    this.templateGenericDto = new TemplateGenericDto();
    this.templateGenericDto.id = GUID_EMPTY;
    this.templateGenericDto.group = TemplateGroup.Generic;
  }

  templateGenericDto: TemplateGenericDto;

  async save(templateDto: TemplateDto) {
    await this._apiGenericTemplate.create(templateDto as TemplateGenericDto);
    this._modalService.closeAsSuccess('La plantilla genérica se ha creado con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
