import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accreditationsByAccountType',
  standalone: false,
})
export class AccreditationsByAccountTypePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 1:
        return 'Al mismo banco';
      case 2:
        return 'Transferencias internas';
      default:
        return '';
    }
  }
}
