// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { OperationSimpleDto } from './OperationSimpleDto';
import { AppUserSimpleDto } from './AppUserSimpleDto';
import { FilterRangeDateDto } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FilterRangeDateDto';

export class IssueFiltersDto extends FiltersDto {
  entityId: string | null = null;
  operationBlockedId: string | null = null;
  operationBlocked: OperationSimpleDto;
  assignedTo: AppUserSimpleDto;
  assignedToId: string | null = null;
  createdDate: FilterRangeDateDto;
  entityIdList: string[] = [];
  levelList: number[] = [];
  typeList: number[] = [];
  statusList: number[] = [];
  issueNumber: number | null = null;
  description: string = '';
}

export interface IssueFiltersDtoFormGroup extends FiltersDtoFormGroup {
  entityId?: any;
  operationBlockedId?: any;
  operationBlocked?: any;
  assignedTo?: any;
  assignedToId?: any;
  createdDate?: any;
  entityIdList?: any;
  levelList?: any;
  typeList?: any;
  statusList?: any;
  issueNumber?: any;
  description?: any;
}
