<div class="notification">
  <div (click)="handleNotificationClick(notification)" [ngClass]="{'btn': shouldHaveButton(notification)}">
    <div class="title">
      {{ notification.title }}
    </div>
    <span class="date">
      {{ notification.date | date : 'dd-MM-yy, h:mm a' }}
    </span>
    <p>
      {{ notification.text }}
    </p>
  </div>

  <div class="btn-container">
    <mf-button-icon
      [icon]="icons.archive"
      size="small"
      (click)="toggleRead(notification.isRead)">
    </mf-button-icon>

    @if (notification.fileUrl != null) {
    <mf-button-icon
      [icon]="icons.download"
      size="small"
      (click)="downloadFile()"></mf-button-icon>
    } @if (notification.issueId != null) {
    <mf-button-icon
      [icon]="icons.openInNew"
      size="small"
      (click)="navigateToRelatedEntityPage()"></mf-button-icon>
    }
  </div>
</div>
