{
	"_locale": "EsUy",
	"EmailSentStatus": {
		"7": "Descartado",
		"5": "Abierto",
		"9": "Rebotado",
		"4": "Entregado",
		"8": "Diferido",
		"11": "Error",
		"1": "Pendiente",
		"6": "Clickeado",
		"10": "Spam",
		"2": "Enviado",
		"3": "Procesado"
	}
}
