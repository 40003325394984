// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents

export class SwitchClientRequestDto  {
  username: string = '';
  clientId: string = '';
}

export interface SwitchClientRequestDtoFormGroup  {
  username?: any;
  clientId?: any;
}
