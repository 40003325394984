// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { PaymentOrderDto } from "../dtos/PaymentOrderDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { PaymentOrderFiltersDto } from "../dtos/PaymentOrderFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminPaymentOrder {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    paymentOrderDto: PaymentOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PaymentOrderDto> {
    return this._httpApiClient.post("/client-admin/payment-order", paymentOrderDto, httpApiRequestOptions);
  }

  delete(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/payment-order/delete/" + id, null, httpApiRequestOptions);
  }

  editDraft(
    paymentOrderDto: PaymentOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PaymentOrderDto> {
    return this._httpApiClient.put("/client-admin/payment-order", paymentOrderDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PaymentOrderDto> {
    return this._httpApiClient.get("/client-admin/payment-order/" + id, httpApiRequestOptions);
  }

  getByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PaymentOrderDto> {
    return this._httpApiClient.get("/client-admin/payment-order/get-by-external-id/" + externalId, httpApiRequestOptions);
  }

  getAllByExternalId(
    externalId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PaymentOrderDto[]> {
    return this._httpApiClient.get("/client-admin/payment-order/get-all-by-external-id/" + externalId, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<PaymentOrderFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<PaymentOrderDto>> {
    return this._httpApiClient.post("/client-admin/payment-order/paged", filters, httpApiRequestOptions);
  }

  suggest(
    paymentOrderFilterDto: SuggestSearchDto<PaymentOrderFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<PaymentOrderDto>> {
    return this._httpApiClient.post("/client-admin/payment-order/suggest", paymentOrderFilterDto, httpApiRequestOptions);
  }

  setExternalId(
    paymentOrderDto: PaymentOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PaymentOrderDto> {
    return this._httpApiClient.put("/client-admin/payment-order/set-external-id", paymentOrderDto, httpApiRequestOptions);
  }

  setRefundDestinationBankAccount(
    paymentOrderDto: PaymentOrderDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/client-admin/payment-order/set-refund-destination-bank-account", paymentOrderDto, httpApiRequestOptions);
  }

}
