@if (!viewType) {
<mf-page title="Nueva persona">
  <mf-row-buttons>
    <mf-button
      text="Persona natural"
      (click)="viewType = personType.NaturalPerson" />
    <mf-button
      text="Empresa"
      (click)="viewType = personType.Company" />
  </mf-row-buttons>
</mf-page>
} @else { @if (viewType === personType.NaturalPerson) {
<mf-natural-person-create />
} @else {
<mf-company-create />
}}
