<mf-page title="Actualizar Id externo">
  @if (collectOrderDto) {
  <mf-form
    [formGroup]="collectOrderForm"
    [entity]="collectOrderDto"
    (save)="save($event)"
    [closeButtonShow]="true"
    (closeButtonClick)="closeModal()">
    <mf-column-display label="Cliente">
      {{ collectOrderDto.operation.client.name }}
    </mf-column-display>

    <mf-column-display label="Persona">
      {{ collectOrderDto.person.fullName }}
    </mf-column-display>

    <mf-column-display label="Monto">
      {{ collectOrderDto.operation.currency | customCurrency }} {{ collectOrderDto.amount }}
    </mf-column-display>

    <mf-column-display label="Id externo">
      {{ collectOrderDto.externalId }}
    </mf-column-display>

    <mf-column [md]="4">
      <mf-field-text formControlName="externalId" />
    </mf-column>
  </mf-form>
  }
</mf-page>
