<mf-form
  [formGroup]="bankAccountForm"
  [entity]="bankAccountDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-autocomplete-bank
      formControlName="bank"
      (itemIdChanged)="bankAccountForm.patchValue({ bankId: $event })" />
  </mf-column>

  <mf-column>
    <mf-autocomplete-bank-branch
      formControlName="bankBranch"
      [bankId]="bankId"
      [autoCompleteMaxItems]="100"
      (itemIdChanged)="bankAccountForm.patchValue({ bankBranchId: $event })" />
  </mf-column>

  <mf-column>
    <mf-field-select
      formControlName="accountType"
      [enumName]="enumName.AccountType" />
  </mf-column>

  <mf-column>
    <mf-field-select
      formControlName="currency"
      [enumName]="enumName.Currency" />
  </mf-column>

  <mf-column>
    <mf-field-text formControlName="accountNumber" />
  </mf-column>

  <mf-column>
    <mf-field-toggle formControlName="isDefault" />
  </mf-column>

  <mf-row />
  <mf-column>
    <mf-autocomplete-person
      formControlName="holder"
      (itemIdChanged)="setPersonValue($event)" />
  </mf-column>

  <mf-column>
    <mf-field-text
      formControlName="holderName"
      [readOnly]="true" />
  </mf-column>

  <mf-column-display label="Verificada">
    <mf-icon [icon]="bankAccountForm.value.isVerified ? icons.checkCircle : icons.cross" />
  </mf-column-display>

  <mf-row />

  <mf-holder-verification
    #holderVerificationComponent
    (validate)="validationInfoDto = $event">
  </mf-holder-verification>

  <mf-row align="right">
    @if (!hideVerifyButton) {
    <mf-button-secondary
      text="Verificar"
      (click)="markAsVerified()" />
    }
    <mf-button-secondary
      text="Consultar banco"
      (click)="getHolderInfo()" />
  </mf-row>
</mf-form>
