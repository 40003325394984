import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { AppUserRegisterDto, AppUserRegisterDtoFormGroup } from '../../../api/dtos/AppUserRegisterDto';
import { ApiAuth } from '../../../api/endpoints/ApiAuth';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { LoginService } from '../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { NaturalPersonDto, NaturalPersonDtoFormGroup } from '../../../../../../libs/utils/ts-shared/src/lib/api/dtos/NaturalPersonDto';
import { PersonType } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { IdDocumentDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/dtos/IdDocumentDto';

@Component({
  standalone: false,
  selector: 'mf-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  constructor(
    private _apiAuth: ApiAuth,
    private _loginService: LoginService,
    private _cfb: CustomFormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this.load();
  }

  appUserRegisterDto: AppUserRegisterDto;
  naturalPersonDto: NaturalPersonDto;
  isLoading = false;
  errorMessage = '';
  enumName = enumName_mi_pspc;
  personType = PersonType;

  IdDocumentDto: IdDocumentDto;

  load() {
    this.appUserRegisterDto = new AppUserRegisterDto();
    this.appUserRegisterDto.naturalPerson = new NaturalPersonDto();
    this.appUserRegisterDto.naturalPerson.firstName = this._activatedRoute.snapshot.queryParams['firstName'];
    this.appUserRegisterDto.naturalPerson.lastName = this._activatedRoute.snapshot.queryParams['lastName'];
    this.appUserRegisterDto.naturalPerson.email = this._activatedRoute.snapshot.queryParams['email'];
    this.appUserRegisterDto.naturalPerson.idDocument = new IdDocumentDto();
    this.appUserRegisterDto.naturalPerson.idDocument.id = GUID_EMPTY;
    this.appUserRegisterDto.isGoogle = this._activatedRoute.snapshot.queryParams['loginProvider'] == 'google';
    this.appUserRegisterForm.patchValue(this.appUserRegisterDto);
    this.appUserRegisterForm.controls.password?.setValidators(this.appUserRegisterDto.isGoogle ? null : [Validators.required]);
    this.appUserRegisterForm.controls.passwordConfirm?.setValidators(this.appUserRegisterDto.isGoogle ? null : [Validators.required]);
  }

  appUserRegisterForm = this._cfb.group<AppUserRegisterDtoFormGroup>({
    password: ['', null, 'Contraseña'],
    passwordConfirm: ['', null, 'Confirmar contraseña'],
    naturalPerson: this._cfb.group<NaturalPersonDtoFormGroup>({
      firstName: ['', Validators.required, 'Nombre'],
      lastName: ['', Validators.required, 'Apellido'],
      idDocument: [null, Validators.required],
      email: ['', Validators.required, 'Email'],
    }),
  });

  async register(appUserRegisterDto: AppUserRegisterDto) {
    this.isLoading = true;
    const loginResponse = await this._apiAuth.registerUser(appUserRegisterDto);
    this._loginService.loginWithToken(loginResponse.token);
    this._router.navigate([this._activatedRoute.snapshot.queryParams['returnUrl'] ?? '/']);
  }
}
