import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { ConfigurationDto } from '../../../api/dtos/ConfigurationDto';
import { ApiClientAdminConfiguration } from '../../../api/endpoints/ApiClientAdminConfiguration';

@Component({
  standalone: false,
  selector: 'mf-configuration-edit',
  templateUrl: './configuration-edit.component.html',
  styleUrls: ['./configuration-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationEditComponent {
  constructor(private _configurationApiClientAdmin: ApiClientAdminConfiguration) {
    this.loadConfiguration();
  }

  configurationDto = signal<ConfigurationDto | null>(null);

  async loadConfiguration() {
    this.configurationDto.set(await this._configurationApiClientAdmin.get());
  }
}
