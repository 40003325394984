import { Component, HostBinding, Input } from '@angular/core';
import { LayoutColumnDisplayComponent } from './column-display.component';

@Component({
  selector: 'mf-row-display',
  templateUrl: './column-display.component.html',
  styleUrls: ['./column-display.component.scss'],
})
export class LayoutRowDisplayComponent extends LayoutColumnDisplayComponent {
  constructor() {
    super();
    this.md = 12;
  }
}
