// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { GatewayBankAccountDto } from "../dtos/GatewayBankAccountDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { GatewayBankAccountFiltersDto } from "../dtos/GatewayBankAccountFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminGatewayBankAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayBankAccountDto> {
    return this._httpApiClient.get("/admin/gateway-bank-account/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<GatewayBankAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<GatewayBankAccountDto>> {
    return this._httpApiClient.post("/admin/gateway-bank-account/paged", filters, httpApiRequestOptions);
  }

  suggest(
    gatewayBankAccountFilterDto: SuggestSearchDto<GatewayBankAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<GatewayBankAccountDto>> {
    return this._httpApiClient.post("/admin/gateway-bank-account/suggest", gatewayBankAccountFilterDto, httpApiRequestOptions);
  }

}
