import { Component, Inject } from '@angular/core';
import { IApiTemplateEmail } from '../../api/IApiTemplateEmail';
import { TemplateEmailDto } from '../../dtos/TemplateEmailDto';
import { GUID_EMPTY } from '../../../../../../ts-shared/src/lib/utils-guid';
import { ModalService } from '../../../services/modal.service';
import { TemplateGroup } from '../../enums/TemplateGroup';
import { TemplateDto } from '../../dtos/TemplateDto';

@Component({
  selector: 'mf-template-email-create',
  templateUrl: './template-email-create.component.html',
  styleUrls: ['./template-email-create.component.scss'],
})
export class TemplateEmailCreateComponent {
  constructor(@Inject('IApiTemplateEmail') private _apiEmailTemplate: IApiTemplateEmail, private _modalService: ModalService) {
    this.templateEmailDto = new TemplateEmailDto();
    this.templateEmailDto.id = GUID_EMPTY;
    this.templateEmailDto.group = TemplateGroup.Email;
  }

  templateEmailDto: TemplateEmailDto;

  async save(templateDto: TemplateDto) {
    await this._apiEmailTemplate.create(templateDto as TemplateEmailDto);
    this._modalService.closeAsSuccess('La plantilla de correo se creó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
