// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BankDto } from "../dtos/BankDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { BankFiltersDto } from "../dtos/BankFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { BankSimpleDto } from "../dtos/BankSimpleDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminBank {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankDto> {
    return this._httpApiClient.get("/client-admin/bank/" + id, httpApiRequestOptions);
  }

  getBySwiftCode(
    swiftCode: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankDto> {
    return this._httpApiClient.get("/client-admin/bank/by-swift-code?" + 
      (swiftCode ? "&swiftCode=" + swiftCode : ""), httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<BankFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<BankDto>> {
    return this._httpApiClient.post("/client-admin/bank/paged", filters, httpApiRequestOptions);
  }

  suggest(
    bankFiltersDto: SuggestSearchDto<BankFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<BankSimpleDto>> {
    return this._httpApiClient.post("/client-admin/bank/suggest", bankFiltersDto, httpApiRequestOptions);
  }

}
