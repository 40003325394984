import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';

@Component({
  standalone: false,
  selector: 'mf-collect-order-voucher-edit',
  templateUrl: './collect-order-voucher-edit.component.html',
  styleUrls: ['./collect-order-voucher-edit.component.scss'],
})
export class CollectOrderVoucherEditComponent {
  constructor(private _modalService: ModalService) {
    this.load(this._modalService.openData.collectOrder);
  }

  collectOrder: CollectOrderDto;

  load(collectOrder: CollectOrderDto) {
    this.collectOrder = collectOrder;
  }

  updateAmount(newAmount: number) {
    this.collectOrder.amount = newAmount;
  }

  async save() {
    this._modalService.closeAsSuccess('La orden se editó con éxito', this.collectOrder);
  }
}
