// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { LoginResponse } from "../../../../../libs/utils/auth-shared/src/lib/dto/LoginResponse";
import { LoginRequest } from "../../../../../libs/utils/auth-shared/src/lib/dto/LoginRequest";
import { AppUserRegisterDto } from "../dtos/AppUserRegisterDto";
import { SwitchClientRequestDto } from "../dtos/SwitchClientRequestDto";

@Injectable({providedIn: 'root'})
export class ApiAuth {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  get(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LoginResponse> {
    return this._httpApiClient.get("/auth/test/" + id, httpApiRequestOptions);
  }

  login(
    loginRequest: LoginRequest,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LoginResponse> {
    return this._httpApiClient.post("/auth/login", loginRequest, httpApiRequestOptions);
  }

  google(
    returnUrl: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.get("/auth/google-login?" + 
      (returnUrl ? "&returnUrl=" + returnUrl : ""), httpApiRequestOptions);
  }

  externalLoginCallback(
    returnUrl: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.get("/auth/google-login-callback?" + 
      (returnUrl ? "&returnUrl=" + returnUrl : ""), httpApiRequestOptions);
  }

  registerUser(
    appUserRegisterDto: AppUserRegisterDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LoginResponse> {
    return this._httpApiClient.post("/auth/register", appUserRegisterDto, httpApiRequestOptions);
  }

  switchClient(
    switchClientRequest: SwitchClientRequestDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<LoginResponse> {
    return this._httpApiClient.post("/auth/switch-client", switchClientRequest, httpApiRequestOptions);
  }

}
