<mf-page title="Cuentas">
  <mf-row>
    <mf-grid
      #grid
      actionButtonText="Nueva cuenta"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [initialFilters]="initialFilters"
      [globalFilters]="globalFilters"
      [menuItems]="menuItems"
      [getRowClass]="getRowClass"
      (actionButtonClick)="openNewBankAccountPopup()" />
  </mf-row>
</mf-page>
