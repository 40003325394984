// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { AccreditationDto } from "../dtos/AccreditationDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { AccreditationFiltersDto } from "../dtos/AccreditationFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminAccreditation {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    accreditationDto: AccreditationDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationDto> {
    return this._httpApiClient.post("/admin/accreditation", accreditationDto, httpApiRequestOptions);
  }

  edit(
    accreditationDto: AccreditationDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationDto> {
    return this._httpApiClient.put("/admin/accreditation", accreditationDto, httpApiRequestOptions);
  }

  cancel(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/accreditation/cancel/" + id, null, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationDto> {
    return this._httpApiClient.get("/admin/accreditation/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<AccreditationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<AccreditationDto>> {
    return this._httpApiClient.post("/admin/accreditation/paged", filters, httpApiRequestOptions);
  }

  generateForCompensation(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationDto> {
    return this._httpApiClient.post("/admin/accreditation/generate-for-compensation", null, httpApiRequestOptions);
  }

  generateForPayment(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AccreditationDto> {
    return this._httpApiClient.post("/admin/accreditation/generate-for-payment", null, httpApiRequestOptions);
  }

  suggest(
    accreditationFiltersDto: SuggestSearchDto<AccreditationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<AccreditationDto>> {
    return this._httpApiClient.post("/admin/accreditation/suggest", accreditationFiltersDto, httpApiRequestOptions);
  }

}
