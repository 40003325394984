// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BankAccountDto } from "../dtos/BankAccountDto";

@Injectable({providedIn: 'root'})
export class ApiAdminBankAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  edit(
    bankAccountDto: BankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountDto> {
    return this._httpApiClient.put("/admin/bank-account", bankAccountDto, httpApiRequestOptions);
  }

  validate(
    bankAccountDto: BankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountDto> {
    return this._httpApiClient.put("/admin/bank-account/validate-account", bankAccountDto, httpApiRequestOptions);
  }

  inactivate(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/bank-account/inactivate/" + id, null, httpApiRequestOptions);
  }

}
