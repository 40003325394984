// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { PaymentTransactionFiltersDto, PaymentTransactionFiltersDtoFormGroup } from './PaymentTransactionFiltersDto';

export class TransferFiltersDto extends PaymentTransactionFiltersDto {
  accreditationByAccountId: string | null = null;
}

export interface TransferFiltersDtoFormGroup extends PaymentTransactionFiltersDtoFormGroup {
  accreditationByAccountId?: any;
}
