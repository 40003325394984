// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { NotificationDto } from "../dtos/NotificationDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { NotificationFiltersDto } from "../dtos/NotificationFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { NotificationTrayDto } from "../dtos/NotificationTrayDto";
import { IssueDto } from "../dtos/IssueDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminNotification {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NotificationDto> {
    return this._httpApiClient.get("/admin/notification/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<NotificationFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<NotificationDto>> {
    return this._httpApiClient.post("/admin/notification/paged", filters, httpApiRequestOptions);
  }

  getCurrent(
    includeRead: boolean,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<NotificationTrayDto> {
    return this._httpApiClient.get("/admin/notification?" + 
      (includeRead ? "&includeRead=" + includeRead : ""), httpApiRequestOptions);
  }

  getRelatedIssue(
    notificationId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<IssueDto> {
    return this._httpApiClient.get("/admin/notification/related-issue/" + notificationId, httpApiRequestOptions);
  }

  inactivate(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.delete("/admin/notification/inactivate/" + id, httpApiRequestOptions);
  }

  setAllAsRead(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/notification/set-all-as-read", null, httpApiRequestOptions);
  }

  setAsRead(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/notification/set-as-read/" + id, null, httpApiRequestOptions);
  }

  downloadNotificationFile(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<string> {
    return this._httpApiClient.put("/admin/notification/download-file/" + id, null, httpApiRequestOptions);
  }

}
