import { Component, ElementRef, Input, OnChanges, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { BankBranchDto } from '../../../api/dtos/BankBranchDto';
import { BankBranchFiltersDto } from '../../../api/dtos/BankBranchFiltersDto';
import { ApiClientAdminBankBranch } from '../../../api/endpoints/ApiClientAdminBankBranch';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankBranchCreateComponent } from '../../pages/banks/bank-branch/bank-branch-create.component';

@Component({
  standalone: false,
  selector: 'mf-autocomplete-bank-branch',
  templateUrl: './autocomplete-bank-branch.component.html',
  styleUrls: ['./autocomplete-bank-branch.component.scss'],
})
export class AutocompleteBankBranchComponent
  extends AutoCompleteBaseEntityComponent<BankBranchDto | null, BankBranchFiltersDto>
  implements OnChanges
{
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiClientAdminBankBranch: ApiClientAdminBankBranch,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
    this.createNewComponent = BankBranchCreateComponent;
  }

  override defaultOrderBy = nameof<BankBranchDto>((p) => p.name);

  @Input()
  bankId?: string;
  @Input()
  autoCompleteMaxItems: number;

  override getCreateNewEntityOpenData() {
    return { bankId: this.bankId };
  }

  override endpointSuggest = (search: SuggestSearchDto<BankBranchFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminBankBranch.suggest(search, httpApiRequestOptions);

  override setDefaultFilters() {
    this.filters.bankId = this.bankId ? this.bankId : null;
  }

  ngOnChanges() {
    this.setDefaultFilters();

    if (this.value && this.value.bankId != this.filters.bankId) {
      this.selectedItemChange(null);
    }
  }
}
