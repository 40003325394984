<mf-page title="Incidentes relacionados">
  <mf-row>
    <mf-issue-grid
      [rowSelection]="true"
      [hideFilters]="true"
      (selectedItems)="selectItems($event)"
      [items]="items" />
  </mf-row>

  <mf-row-buttons>
    <mf-button-secondary
      text="Cancelar"
      (click)="cancel()" />
    <mf-button
      [disabled]="issueResolutionListDto.entityIdList.length == 0"
      text="Resolver"
      (click)="submit()" />
  </mf-row-buttons>
</mf-page>
