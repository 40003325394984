// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { IssueDto } from "../dtos/IssueDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { IssueFiltersDto } from "../dtos/IssueFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";
import { IssueResolutionListDto } from "../dtos/IssueResolutionListDto";

@Injectable({providedIn: 'root'})
export class ApiAdminIssue {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    issueDto: IssueDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<IssueDto> {
    return this._httpApiClient.post("/admin/issue", issueDto, httpApiRequestOptions);
  }

  edit(
    issueDto: IssueDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<IssueDto> {
    return this._httpApiClient.put("/admin/issue", issueDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<IssueDto> {
    return this._httpApiClient.get("/admin/issue/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<IssueFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<IssueDto>> {
    return this._httpApiClient.post("/admin/issue/paged", filters, httpApiRequestOptions);
  }

  suggest(
    issueFilterDto: SuggestSearchDto<IssueFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<IssueDto>> {
    return this._httpApiClient.post("/admin/issue/suggest", issueFilterDto, httpApiRequestOptions);
  }

  resolve(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<IssueDto> {
    return this._httpApiClient.put("/admin/issue/resolve/" + id, null, httpApiRequestOptions);
  }

  reopen(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/issue/reopen/" + id, null, httpApiRequestOptions);
  }

  assignToCurrentUser(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/issue/assign-to-current-user/" + id, null, httpApiRequestOptions);
  }

  bulkResolve(
    issueResolutionListDto: IssueResolutionListDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/issue/bulk-resolve", issueResolutionListDto, httpApiRequestOptions);
  }

}
