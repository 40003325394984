<mf-form
  [formGroup]="bankBranchForm"
  [entity]="bankBranchDto"
  (save)="save.emit($event)">
  <mf-column>
    <mf-autocomplete-bank
      formControlName="bank"
      [hideAddButton]="false"
      (itemIdChanged)="bankBranchForm.patchValue({ bankId: $event })" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="branchCode" />
  </mf-column>
</mf-form>
