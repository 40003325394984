import { ChangeDetectionStrategy, Component, ElementRef, Inject, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { CompanyDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';
import { CompanyFiltersDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/CompanyFiltersDto';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { ApiClientAdminCompany } from '../../../api/endpoints/ApiClientAdminCompany';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CompanyCreateComponent } from '../../pages/person/company/company-create.component';

@Component({
  selector: 'mf-autocomplete-company',
  templateUrl: './autocomplete-company.component.html',
  styleUrl: './autocomplete-company.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteCompanyComponent extends AutoCompleteBaseEntityComponent<CompanyDto | null, CompanyFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    modalService: ModalService,
    private _apiClientAdminCompany: ApiClientAdminCompany
  ) {
    super(ngControl, elRef, modalService);
    //this.createNewComponent = CompanyCreateComponent;
  }

  override defaultOrderBy = nameof<CompanyDto>((c) => c.name);

  override endpointSuggest = (search: SuggestSearchDto<CompanyFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminCompany.suggest(search, httpApiRequestOptions);
}
