import { Component, Inject } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { IApiTemplateGeneric } from '../../api/IApiTemplateGeneric';
import { ModalService } from '../../../services/modal.service';
import { TemplateGenericDto } from '../../dtos/TemplateGenericDto';
import { TemplateDto } from '../../dtos/TemplateDto';

@Component({
  selector: 'mf-template-generic-edit',
  templateUrl: './template-generic-edit.component.html',
  styleUrls: ['./template-generic-edit.component.scss'],
})
export class TemplateGenericEditComponent {
  constructor(@Inject('IApiTemplateGeneric') private _apiGenericTemplate: IApiTemplateGeneric, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  templateGenericDto: TemplateGenericDto;

  async load(id: string) {
    this.templateGenericDto = await this._apiGenericTemplate.getById(id);
  }

  async save(templateDto: TemplateDto) {
    await this._apiGenericTemplate.edit(templateDto as TemplateGenericDto);
    this._modalService.closeAsSuccess('La plantilla genérica se ha editado con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
