import { Component, ViewChild } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CreateBcuReportPopupComponent } from './create/create-bcu-report-popup.component';
import { BcuReportFiltersDto } from '../../../../api/dtos/BcuReportFiltersDto';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { ApiAdminBcuReport } from '../../../../api/endpoints/ApiAdminBcuReport';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { BcuReportDto } from '../../../../api/dtos/BcuReportDto';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { NavigationService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GatewayDto } from '../../../../api/dtos/GatewayDto';

@Component({
  selector: 'mf-bcu-report',
  templateUrl: './bcu-report-grid.component.html',
  styleUrls: ['./bcu-report-grid.component.scss'],
})
export class BcuReportGridComponent {
  constructor(
    private _modalService: ModalService,
    private _apiAdminBcuReport: ApiAdminBcuReport,
    private _navigationService: NavigationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;
  filters = new BcuReportFiltersDto();

  mustIncludeFields = [
    nameof<BcuReportDto>((e) => e.webPaymentsXmlFilePath),
    nameof<BcuReportDto>((e) => e.paymentOrCollectionServicesXmlFilePath),
  ];

  columns: IGridColumn<BcuReportDto>[] = [
    { field: nameof<BcuReportDto>((b) => b.bcuReportNumber), header: 'Número', width: 10 },
    { field: nameof<BcuReportDto>((b) => b.communicationCode), header: 'Código', width: 20 },
    { field: nameof<BcuReportDto>((b) => b.bank.name), header: 'Banco', width: 20 },
    {
      field: nameof<BcuReportDto>((b) => b.startDate),
      header: 'Fecha de inicio',
      width: 20,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: false },
    },
    {
      field: nameof<BcuReportDto>((b) => b.endDate),
      header: 'Fecha de fin',
      width: 20,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: false },
    },
    {
      field: nameof<BcuReportDto>((b) => b.appUser?.naturalPerson?.fullName),
      header: 'Administrador',
      width: 20,
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Descargar xml 1',
      icon: Icons.download,
      hide: (bcuReportDto: BcuReportDto) => bcuReportDto.webPaymentsXmlFilePath == '',
      onClick: (bcuReportDto: BcuReportDto) => {
        this.downloadFile(bcuReportDto.webPaymentsXmlFilePath);
      },
    },
    {
      label: 'Descargar xml 2',
      icon: Icons.download,
      hide: (bcuReportDto: BcuReportDto) => bcuReportDto.paymentOrCollectionServicesXmlFilePath == '',
      onClick: (bcuReportDto: BcuReportDto) => {
        this.downloadFile(bcuReportDto.paymentOrCollectionServicesXmlFilePath);
      },
    },
  ];

  downloadFile(urlFilePath: string) {
    this._navigationService.navigateNewTab(urlFilePath);
    this.grid.reloadPage();
  }

  apiEndpoint = (search: PagedSearchDto<BcuReportFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminBcuReport.getPaged(search, httpApiRequestOptions);

  openBcuReportPopup() {
    this._modalService.openRightModal(CreateBcuReportPopupComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
