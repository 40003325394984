import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MenuGroup } from '../MenuGroup';
import { IRoleProvider } from '../IRoleProvider';
import { MenuItem } from '../MenuItem';

@Component({
  standalone: false,
  selector: 'mf-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
})
export class MenuGroupComponent implements OnInit {
  @Input()
  menuGroup: MenuGroup;
  @Input()
  roleProvider: IRoleProvider;
  showItems: boolean = false;
  itemsToShow: MenuItem[] = [];
  menuGroupToShow: { items: MenuItem[]; showTitle: boolean };

  @Output()
  navigateTo: EventEmitter<string> = new EventEmitter();

  ngOnInit(): void {
    this.menuGroupToShow = { items: [], showTitle: false };
    this.menuGroup.items.map((item) => {
      if (this.roleProvider.userIsInRole(item.roles)) {
        this.itemsToShow.push(item);
      }
    });

    if (this.itemsToShow.length == 0) {
      this.menuGroupToShow.showTitle = false;
    } else {
      this.menuGroupToShow.showTitle = true;
      this.menuGroupToShow.items = this.itemsToShow;
    }
  }

  navigate(path: string) {
    this.navigateTo.emit(path);
  }
}
