<mf-form
  [formGroup]="clientForm"
  [entity]="clientDto"
  (save)="save.emit($event)">
  @if (clientDto) {
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-field-text formControlName="endpointUrl" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-company
      formControlName="company"
      (itemIdChanged)="clientForm.patchValue({ companyId: $event })" />
  </mf-column>
  <mf-column-display label="Id"> {{ clientDto.id }} </mf-column-display>
  <mf-column>
    <mf-field-text formControlName="apiKey" />
  </mf-column>
  }
</mf-form>
