import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { numericString } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-regex';
import { BankDto, BankDtoFormGroup } from '../../../../api/dtos/BankDto';
import { enumName_mi_shared } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  standalone: false,
  selector: 'mf-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss'],
})
export class BankComponent {
  constructor(private _cfb: CustomFormBuilder) {}
  @Input()
  bankDto: BankDto;

  @Output()
  save = new EventEmitter<BankDto>();

  @Output()
  close = new EventEmitter();

  numericRegex = new RegExp(numericString);

  bankForm = this._cfb.group<BankDtoFormGroup>({
    bankCode: [null, Validators.required, 'Código banco'],
    name: [null, Validators.required, 'Nombre'],
    country: [null, Validators.required, 'País'],
    swiftCode: [null, null, 'Código SWIFT'],
    swiftCountryCode: [null, Validators.pattern(this.numericRegex), 'Código SWIFT país'],
    accountNumberMinLength: [null, Validators.required, 'Largo de cuenta mínimo'],
    accountNumberMaxLength: [null, Validators.required, 'Largo de cuenta máximo'],
    accountPrefixBranchCodeLength: [null, null, 'Largo del prefijo de sucursal en el número de cuenta'],
    isDigitalWallet: [false, null, 'Billetera electronica'],
    daMapId: [null, null, 'DaMap Id conexión'],
  });

  ngOnInit(): void {
    this.bankForm.patchValue(this.bankDto);
  }

  enumName = enumName_mi_shared;
  enumNameModule = moduleName.shared;
}
