// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BankAccountDto } from "../dtos/BankAccountDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { BankAccountFiltersDto } from "../dtos/BankAccountFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";
import { BankAccountValidationInfoDto } from "../dtos/BankAccountValidationInfoDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminBankAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    bankAccountDto: BankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountDto> {
    return this._httpApiClient.post("/client-admin/bank-account", bankAccountDto, httpApiRequestOptions);
  }

  edit(
    bankAccountDto: BankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountDto> {
    return this._httpApiClient.put("/client-admin/bank-account", bankAccountDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountDto> {
    return this._httpApiClient.get("/client-admin/bank-account/" + id, httpApiRequestOptions);
  }

  getByAccountNumber(
    accountNumber: string,
    
    currency: number,
    
    bankCode: string,
    
    country: number,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountDto> {
    return this._httpApiClient.get("/client-admin/bank-account/by-account-number?" + 
      (accountNumber ? "&accountNumber=" + accountNumber : "") + 
      (currency ? "&currency=" + currency : "") + 
      (bankCode ? "&bankCode=" + bankCode : "") + 
      (country ? "&country=" + country : ""), httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<BankAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<BankAccountDto>> {
    return this._httpApiClient.post("/client-admin/bank-account/paged", filters, httpApiRequestOptions);
  }

  suggest(
    bankAccountFilterDto: SuggestSearchDto<BankAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<BankAccountDto>> {
    return this._httpApiClient.post("/client-admin/bank-account/suggest", bankAccountFilterDto, httpApiRequestOptions);
  }

  getValidationInfo(
    bankAccountDto: BankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankAccountValidationInfoDto> {
    return this._httpApiClient.post("/client-admin/bank-account/validation-info", bankAccountDto, httpApiRequestOptions);
  }

}
