import { Component, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { ClientDto } from '../../../api/dtos/ClientDto';
import { ClientUsersAdminDto, ClientUsersAdminDtoFormGroup } from '../../../api/dtos/ClientUsersAdminDto';
import { ClientUsersAdminFiltersDto } from '../../../api/dtos/ClientUsersAdminFiltersDto';
import { ApiAdminClient } from '../../../api/endpoints/ApiAdminClient';
import { ApiClientAdminUsers } from '../../../api/endpoints/ApiClientAdminUsers';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { GUID_EMPTY } from '../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ApiClientAdminClient } from '../../../api/endpoints/ApiClientAdminClient';
import { LoginService } from '../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { Roles } from '../../../api/enums/Roles';

@Component({
  selector: 'mf-client-add-users',
  templateUrl: './client-add-users.component.html',
  styleUrls: ['./client-add-users.component.scss'],
})
export class ClientAddUsersComponent {
  constructor(
    private _cfb: CustomFormBuilder,
    private _apiAdminClient: ApiAdminClient,
    private _apiClientAdminClient: ApiClientAdminClient,
    private _apiClientAdminUsers: ApiClientAdminUsers,
    private _modalService: ModalService,
    private _loginService: LoginService
  ) {
    this.load(this._modalService.openData);
  }

  clientDto: ClientDto;
  filter: ClientUsersAdminFiltersDto;

  @ViewChild('grid')
  grid: GridComponent;

  columns: IGridColumn<ApiClientAdminUsers>[] = [
    {
      field: nameof<ClientUsersAdminDto>((r) => r.appUser.naturalPerson.firstName),
      header: 'Nombre',
      width: 100,
      filter: {
        propertyName: nameof<ClientUsersAdminFiltersDto>((r) => r.appUserName),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<ClientUsersAdminDto>((u) => u.appUser.naturalPerson.email),
      header: 'Email',
      width: 100,
      filter: {
        propertyName: nameof<ClientUsersAdminFiltersDto>((r) => r.defaultTextSearch),
        type: FilterType.Text,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Desvincular usuario',
      icon: Icons.edit,
      onClick: async (clientUsersAdminDto: ClientUsersAdminDto) => {
        await this._apiClientAdminUsers.delete(clientUsersAdminDto.id);
        this.grid.reloadPage();
      },
    },
  ];

  addUsersForm = this._cfb.group<ClientUsersAdminDtoFormGroup>({
    id: [GUID_EMPTY],
    clientId: ['', Validators.required],
    client: [null, Validators.required, 'Cliente'],
    appUserId: ['', Validators.required],
    appUser: [null, Validators.required, 'Usuario'],
  });

  async load(id: string) {
    this.clientDto = this._loginService.userIsInRole([Roles[Roles.SuperAdmin]])
      ? await this._apiAdminClient.getById(id)
      : await this._apiClientAdminClient.getCurrentClient();
    this.addUsersForm.patchValue({
      clientId: this.clientDto.id,
      client: this.clientDto,
    });
    this.filter = new ClientUsersAdminFiltersDto();
    this.filter.client = this.clientDto;
  }

  async save(clientUsersAdmin: ClientUsersAdminDto) {
    await this._apiClientAdminUsers.create(clientUsersAdmin);
    this.grid.reloadPage();
  }
  apiEndpoint = (search: PagedSearchDto<ClientUsersAdminFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminUsers.getPaged(search, httpApiRequestOptions);
}
