import { Component } from '@angular/core';
import { Roles } from '../../../api/enums/Roles';
import { enumValueTextList } from '../../../../../../libs/utils/ts-shared/src/lib/utils-enum';
import { MenuGroup } from '../../../../../../libs/utils/ng-shared-components/src/lib/left-menu/MenuGroup';
import { MenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/left-menu/MenuItem';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { AppRoute } from '../../app.routes.path';
import { NavigationService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { LoginService } from '../../../../../../libs/utils/auth-shared/src/lib/services/login.service';

@Component({
  selector: 'mf-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  constructor(private _navigationService: NavigationService, public loginService: LoginService) {}

  private roles(roles: number[]): string[] {
    return enumValueTextList(roles, Roles);
  }

  private rolExecutor(): string[] {
    return this.roles([Roles.SuperAdmin, Roles.Executor]);
  }

  private rolClientAdmin(): string[] {
    return this.roles([Roles.SuperAdmin, Roles.ClientAdmin]);
  }

  private rolSuperAdmin(): string[] {
    return this.roles([Roles.SuperAdmin]);
  }

  menuItems: MenuGroup[] = [
    new MenuGroup('Clientes', [new MenuItem(AppRoute.clients, Icons.group, 'Clientes', this.rolSuperAdmin())]),
    new MenuGroup('Personas', [
      new MenuItem(AppRoute.persons.naturalPersons.list, Icons.naturalPerson, 'Personas', this.rolClientAdmin()),
      new MenuItem(AppRoute.persons.companies.list, Icons.company, 'Empresas', this.rolClientAdmin()),
    ]),
    new MenuGroup('Bancos', [
      new MenuItem(AppRoute.banks, Icons.bank, 'Bancos', this.rolSuperAdmin()),
      new MenuItem(AppRoute.bankBranches, Icons.bankBranch, 'Sucursales', this.rolSuperAdmin()),
      new MenuItem(AppRoute.bankAccounts, Icons.accountBalance, 'Cuentas', this.rolClientAdmin()),
      new MenuItem(AppRoute.gatewayBankAccounts, Icons.gateway, 'Pasarela', this.rolSuperAdmin()),
      new MenuItem(AppRoute.bcuReport, Icons.report, 'Reporte BCU', this.rolSuperAdmin()),
    ]),
    new MenuGroup('Operaciones', [new MenuItem(AppRoute.operations, Icons.operations, 'Operaciones', this.rolClientAdmin())]),
    new MenuGroup('Ordenes', [
      new MenuItem(AppRoute.orders.collect.list, Icons.collects, 'Cobros', this.rolClientAdmin()),
      new MenuItem(AppRoute.orders.payment.list, Icons.payments, 'Pagos', this.rolClientAdmin()),
    ]),
    new MenuGroup('Transacciones', [
      new MenuItem(AppRoute.paymentTransactions.transfers.list, Icons.transfer, 'Transferencias', this.rolClientAdmin()),
      new MenuItem(AppRoute.paymentTransactions.bankMovements.list, Icons.bankMovement, 'Movimientos bancarios', this.rolExecutor()),
    ]),
    new MenuGroup('Seguridad', [new MenuItem(AppRoute.users, Icons.user, 'Usuarios', this.rolExecutor())]),
    new MenuGroup('General', [new MenuItem(AppRoute.valueLists, Icons.list, 'Listas administrables', this.rolSuperAdmin())]),
    new MenuGroup('Desarrollo', [
      new MenuItem(AppRoute.development.logging.list, Icons.computer, 'Logging', this.rolSuperAdmin()),
      new MenuItem(AppRoute.development.template.list, Icons.template, 'Plantillas', this.rolSuperAdmin()),
      new MenuItem(AppRoute.development.emailSent.list, Icons.email, 'Correos enviados', this.rolSuperAdmin()),
    ]),
    new MenuGroup('Configuración', [new MenuItem(AppRoute.configuration, Icons.settings, 'Configuración', this.rolSuperAdmin())]),
    new MenuGroup('Notificaciones', [new MenuItem(AppRoute.notifications, Icons.notifications, 'Notificaciones', this.rolSuperAdmin())]),
    new MenuGroup('Incidentes', [new MenuItem(AppRoute.issues, Icons.issue, 'Incidentes', this.rolSuperAdmin())]),
    new MenuGroup('Acreditaciones', [
      new MenuItem(AppRoute.accreditations, Icons.accreditation, 'Acreditaciones', this.rolSuperAdmin()),
      new MenuItem(AppRoute.accreditationsByAccount, Icons.accreditationByAccount, 'Acreditaciones por cuenta', this.rolSuperAdmin()),
    ]),
    new MenuGroup('Conciliaciones', [new MenuItem(AppRoute.conciliations, Icons.conciliations, 'Conciliaciones', this.rolExecutor())]),
    new MenuGroup('Pasarelas', [new MenuItem(AppRoute.gateways, Icons.gateway, 'Pasarelas', this.rolExecutor())]),
  ];

  navigateTo(event: string) {
    this._navigationService.navigate(event);
  }
}
