import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent, IGridActionButton } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { NotificationDto } from '../../../api/dtos/NotificationDto';
import { NotificationFiltersDto } from '../../../api/dtos/NotificationFiltersDto';
import { ApiClientAdminNotification } from '../../../api/endpoints/ApiClientAdminNotification';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { NotificationCreateComponent } from './notification-create.component';
import { NotificationDetailsComponent } from './notification-details.component';

@Component({
  selector: 'mf-notification-grid',
  templateUrl: './notification-grid.component.html',
  styleUrls: ['./notification-grid.component.scss'],
})
export class NotificationGridComponent {
  constructor(private _apiClientAdminNotification: ApiClientAdminNotification, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;
  notificationDto: NotificationDto;

  gridActionButtons: IGridActionButton[] = [
    {
      text: 'Nueva notificación',
      onClick: () => this.openNewNotificationPopup(),
    },
  ];

  columns: IGridColumn<NotificationDto>[] = [
    {
      field: nameof<NotificationDto>((o) => o.date),
      header: 'Fecha',
      width: 4,
      cellOptions: {
        type: CellValueType.Date,
        dateIncludeTime: true,
      },
      filter: {
        propertyName: nameof<NotificationFiltersDto>((o) => o.createdDate),
        type: FilterType.Date,
      },
    },
    {
      field: nameof<NotificationDto>((o) => o.title),
      header: 'Título',
      width: 10,
    },
    {
      field: nameof<NotificationDto>((o) => o.text),
      header: 'Texto',
      width: 10,
    },
    {
      field: nameof<NotificationDto>((o) => o.isRead),
      header: 'Leído',
      width: 1,
      cellOptions: {
        type: CellValueType.Boolean,
      },
      filter: {
        propertyName: nameof<NotificationFiltersDto>((o) => o.isRead),
        type: FilterType.Boolean,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver detalles',
      icon: Icons.search,
      onClick: (notification: NotificationDto) => {
        this._modalService.openRightModal(NotificationDetailsComponent, {
          openData: notification.id,
          onCloseSuccess: async () => {
            this.grid.reloadPage();
          },
        });
      },
    },
    {
      label: 'Eliminar',
      icon: Icons.delete,
      onClick: (notification: NotificationDto) => {
        this._apiClientAdminNotification.inactivate(notification.id);
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<NotificationFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminNotification.getPaged(search, httpApiRequestOptions);

  openNewNotificationPopup() {
    this._modalService.openRightModal(NotificationCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
