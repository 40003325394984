<mf-form
  [formGroup]="collectOrderVoucherForm"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-row>
    <mf-field-file-upload
      formControlName="voucherUrl"
      [endpointUpload]="endpoint" />
  </mf-row>

  <mf-column-display label="Monto esperado">
    {{ getTransferAmount() | number : '1.2-2' }}
  </mf-column-display>

  <mf-column>
    <mf-field-numeric formControlName="amount" />
  </mf-column>

  <mf-column>
    <mf-field-date formControlName="uploadDate" />
  </mf-column>

  <mf-row>
    @if (!personDto?.isExemptFromOriginDeclaration){
    <mf-column>
      <mf-autocomplete-bank-account
        formControlName="sourceBankAccount"
        [personId]="personId"
        [includeAuthorized]="true"
        [currency]="transferVoucherForCollectOrderDto.currency"
        (itemIdChanged)="collectOrderVoucherForm.patchValue({ sourceBankAccountId: $event })">
      </mf-autocomplete-bank-account>
    </mf-column>
    } @else {
    <mf-column-display label="Cuenta de origen"> No declara cuenta de origen</mf-column-display>
    }
    <mf-column>
      <mf-autocomplete-gateway-bank-account
        formControlName="destinationBankAccount"
        [currency]="transferVoucherForCollectOrderDto.currency"
        (itemIdChanged)="collectOrderVoucherForm.patchValue({ destinationBankAccountId: $event })">
      </mf-autocomplete-gateway-bank-account>
    </mf-column>
    <mf-column>
      <mf-field-check
        formControlName="createsNewOrderForBalanceAmount"
        label="Crear ordenes de balance" />
    </mf-column>
  </mf-row>

  <mf-row>
    <mf-field-text formControlName="reference" />
  </mf-row>

  <mf-row>
    <mf-collect-order-voucher-grid
      (selectedItems)="selectItems($event)"
      [personId]="personId"
      [currency]="transferVoucherForCollectOrderDto.currency"
      [clientId]="transferVoucherForCollectOrderDto.clientId ? transferVoucherForCollectOrderDto.clientId : null"
      [transferVoucherForCollectOrderItemDto]="transferVoucherForCollectOrderDto.collectOrders" />
  </mf-row>
</mf-form>
