import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO, toDate } from 'date-fns';

@Pipe({
  name: 'dateUtc',
  standalone: false,
})
export class DateUtcPipe implements PipeTransform {
  transform(value: Date | string, showTime?: boolean, showMs?: boolean): string {
    if (typeof value === 'string') {
      value = parseISO(value);
    }

    return format(value, showTime ? (showMs ? 'dd/MM/yyyy HH:mm:sss.ddd' : 'dd/MM/yyyy HH:mm') : 'dd/MM/yyyy');
  }
}
