import { Component, ElementRef, Input, OnChanges, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { ValueListDto } from '../../../api/dtos/ValueListDto';
import { ValueListFiltersDto } from '../../../api/dtos/ValueListFiltersDto';
import { ApiAdminValueList } from '../../../api/endpoints/ApiAdminValueList';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { Country } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/Country';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  standalone: false,
  selector: 'mf-autocomplete-value-list',
  templateUrl: './autocomplete-value-list.component.html',
  styleUrls: ['./autocomplete-value-list.component.scss'],
})
export class AutocompleteValueListComponent
  extends AutoCompleteBaseEntityComponent<ValueListDto | null, ValueListFiltersDto>
  implements OnChanges
{
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiAdminValueList: ApiAdminValueList,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
  }

  @Input()
  valueListType: number;

  @Input()
  country: Country;

  ngOnChanges() {
    this.applyFilters();
  }

  override defaultOrderBy = nameof<ValueListDto>((p) => p.valueListType);

  override endpointSuggest = (search: SuggestSearchDto<ValueListFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminValueList.suggest(search, httpApiRequestOptions);

  applyFilters() {
    this.filters = new ValueListFiltersDto();
    this.filters.type = this.valueListType;
    if (this.country) {
      this.filters.country = this.country;
    }
  }
}
