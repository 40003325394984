import { Component, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminCompany } from '../../../../api/endpoints/ApiClientAdminCompany';
import { CompanyEditComponent } from './company-edit.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { CompanyCreateComponent } from './company-create.component';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { CompanyDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/CompanyDto';
import { PersonDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { enumName_mi_shared } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { PersonFiltersDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/PersonFiltersDto';
import { CompanyFiltersDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/persons/CompanyFiltersDto';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mf-company-grid',
  templateUrl: './company-grid.component.html',
  styleUrls: ['./company-grid.component.scss'],
})
export class CompanyGridComponent {
  constructor(
    public _apiClientAdminCompany: ApiClientAdminCompany,
    private _modalService: ModalService,
    private _activatedRoute: ActivatedRoute
  ) {
    this.initialFilters.id = this._activatedRoute.snapshot.queryParams.id;
  }

  @ViewChild('grid')
  grid: GridComponent;

  initialFilters = new CompanyFiltersDto();

  columns: IGridColumn<CompanyDto>[] = [
    {
      field: nameof<CompanyDto>((p) => p.fullName),
      header: 'Nombre',
      width: 100,
      filter: {
        propertyName: nameof<PersonFiltersDto>((p) => p.defaultTextSearch),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<CompanyDto>((p) => p.idDocument.type),
      header: 'Tipo de documento',
      width: 20,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_shared.IdDocumentType,
        enumNameModule: moduleName.shared,
      },
    },
    {
      field: nameof<PersonDto>((p) => p.idDocument.documentNumber),
      header: 'Documento',
      width: 20,
      filter: {
        propertyName: nameof<PersonFiltersDto>((p) => p.documentNumber),
        type: FilterType.Text,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Editar',
      icon: Icons.edit,
      onClick: (companyDto: CompanyDto) => {
        this._modalService.openRightModal(CompanyEditComponent, {
          openData: companyDto.id,
          onCloseSuccess: () => {
            this.grid.reloadPage();
          },
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<CompanyFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminCompany.getPaged(search, httpApiRequestOptions);

  openNewCompanyPopup() {
    this._modalService.openRightModal(CompanyCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
