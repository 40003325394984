import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { AccreditationDto } from '../../../api/dtos/AccreditationDto';
import { AccreditationFiltersDto } from '../../../api/dtos/AccreditationFiltersDto';
import { ApiAdminAccreditation } from '../../../api/endpoints/ApiAdminAccreditation';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';

@Component({
  standalone: false,
  selector: 'mf-autocomplete-accreditation',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteAccreditationComponent extends AutoCompleteBaseEntityComponent<AccreditationDto | null, AccreditationFiltersDto> {
  constructor(
    @Optional() @Self() ngControl: NgControl,
    elRef: ElementRef,
    private _apiAdminAccreditation: ApiAdminAccreditation,
    modalService: ModalService
  ) {
    super(ngControl, elRef, modalService);
  }

  showAddButton = true;

  override defaultOrderBy = nameof<AccreditationDto>((p) => p.accreditationNumber);

  override endpointSuggest = (search: SuggestSearchDto<AccreditationFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminAccreditation.suggest(search, httpApiRequestOptions);
}
