import { Component, signal } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiAdminAccreditationsByAccount } from '../../../../api/endpoints/ApiAdminAccreditationsByAccount';
import { AccreditationsByAccountDto } from '../../../../api/dtos/AccreditationsByAccountDto';
import { AccreditationsByAccountStatus } from '../../../../api/enums/AccreditationsByAccountStatus';

@Component({
  standalone: false,
  selector: 'mf-accreditations-by-account-popup',
  templateUrl: './accreditations-by-account-popup.component.html',
  styleUrls: ['./accreditations-by-account-popup.component.scss'],
})
export class AccreditationsByAccountPopupComponent {
  constructor(private _apiAdminAccreditationsByAccount: ApiAdminAccreditationsByAccount, private _modalService: ModalService) {
    this.load(this._modalService.openData);
  }

  async load(id: string) {
    const list = await this._apiAdminAccreditationsByAccount.getAllByAccreditationId(id);
    this.accreditationsByAccountList.set(list);
  }

  accreditationsByAccountList = signal<AccreditationsByAccountDto[] | null>(null);
  accreditationByAccountStatus = AccreditationsByAccountStatus;

  downloadFile(id: string) {}
}
