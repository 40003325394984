import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Icons } from '../icon/icons';
import { LabeledFormControl } from './form-builder/labeled-form-control';

@Component({
  standalone: false,
  selector: 'mf-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.scss'],
})
export class ValidationSummaryComponent {
  @Input() linkedFormGroup: FormGroup;
  @Input() triedSubmit: boolean = false;
  icons = Icons;
  isExpanded = false;

  ngOnInit(): void {
    if (!this.linkedFormGroup) {
      throw new Error('linkedFormGroup attribute bind is required for mf-validation-summary');
    }
  }

  private getErrors(group: FormGroup): string[] {
    const errors: string[] = [];
    Object.keys(group.controls).forEach((controlName) => {
      const control = group.get(controlName) as LabeledFormControl;
      const controlErrors = control.errors;

      if (control instanceof FormGroup) {
        const groupErrors = this.getErrors(control);
        if (groupErrors.length > 0) {
          errors.push(...groupErrors);
        }
      }

      if (controlErrors) {
        Object.keys(controlErrors).forEach((key) => {
          const label = control.label; // Usar el label del control
          switch (key) {
            case 'required':
              errors.push(`Campo requerido: ${label}`);
              break;
            // Agregar más casos de error según sea necesario
            default:
              errors.push(`Campo inválido: ${label}`);
              break;
          }
        });
      }
    });
    return errors;
  }

  get errorList(): string[] {
    return this.getErrors(this.linkedFormGroup);
  }

  get mustShow(): boolean {
    if (this.triedSubmit && this.linkedFormGroup) {
      return this.linkedFormGroup.invalid && this.triedSubmit;
    }

    return false;
  }
}
