import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../../../libs/utils/ng-shared-components/src';
import { FileUploadResponseDto } from '../../../../../libs/utils/ng-shared-components/src/lib/fields/field-file-upload/dtos/FileUploadResponseDto';
import { LayoutService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/layout.service';
import { HttpApiRequestOptions } from './HttpApiRequestOptions';
import { LoginService } from '../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { IApiUrlService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/api-url.service';

@Injectable({ providedIn: 'root' })
export class HttpApiClient {
  _baseUrl: string;

  constructor(
    private _httpClient: HttpClient,
    private _loginService: LoginService,
    private _notificationService: NotificationService,
    private _layoutService: LayoutService,
    @Inject('IApiUrlService') apiUrlService: IApiUrlService
  ) {
    this._baseUrl = apiUrlService.apiUrl;
  }

  private errorHandler = (error: HttpErrorResponse, spinnerToken: number) => {
    if (spinnerToken) {
      this._layoutService.globalSpinnerHide(spinnerToken);
    }

    if (error.status === 404) {
      this._notificationService.showError('No se encontró la acción en el servidor');
      return;
    }

    if (error.status >= 400 && error.status < 500) {
      let message = error.error.message ?? error.error.title ?? 'Error en la consulta';

      if (error.error.errors) {
        message += ': ' + Object.values(error.error.errors).join(', ');
      }

      this._notificationService.showError(message);
      return;
    }

    if (error.status >= 500 && error.status < 600) {
      this._notificationService.showError('Hubo un error interno en el servidor');
      return;
    }

    return this._notificationService.showError('Error al comunicarse con el servidor');
  };

  private headers() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this._loginService.jwtToken}`,
      }),
    };
  }

  private createPromise<T>(request: Observable<T>, httpApiRequestOptions?: HttpApiRequestOptions): Promise<T> {
    const spinnerToken = !httpApiRequestOptions?.preventSpinner ? this._layoutService.globalSpinnerShow() : 0;

    return new Promise<T>((resolve, reject) => {
      request.subscribe({
        next: (data) => {
          if (spinnerToken) {
            this._layoutService.globalSpinnerHide(spinnerToken);
          }
          resolve(data);
        },
        error: (error) => {
          if (spinnerToken) {
            this._layoutService.globalSpinnerHide(spinnerToken);
          }
          if (!httpApiRequestOptions?.preventErrorHandler) {
            this.errorHandler(error, spinnerToken);
          }
          this._loginService.autoLogout();
          reject(error);
        },
      });
    });
  }

  public delete<T>(url: string, httpApiRequestOptions?: HttpApiRequestOptions): Promise<T> {
    return this.createPromise(this._httpClient.delete<T>(this._baseUrl + url, this.headers()), httpApiRequestOptions);
  }

  public get<T>(url: string, httpApiRequestOptions?: HttpApiRequestOptions): Promise<T> {
    return this.createPromise(this._httpClient.get<T>(this._baseUrl + url, this.headers()), httpApiRequestOptions);
  }

  public post<T>(url: string, body: any, httpApiRequestOptions?: HttpApiRequestOptions): Promise<T> {
    return this.createPromise(this._httpClient.post<T>(this._baseUrl + url, body, this.headers()), httpApiRequestOptions);
  }

  public put<T>(url: string, body: any, httpApiRequestOptions?: HttpApiRequestOptions): Promise<T> {
    return this.createPromise(this._httpClient.put<T>(this._baseUrl + url, body, this.headers()), httpApiRequestOptions);
  }

  public uploadFile(url: string, fileList: FileList, httpApiRequestOptions?: HttpApiRequestOptions): Promise<FileUploadResponseDto> {
    const file = fileList[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append(file.name, file);
    const req = new HttpRequest('POST', this._baseUrl + url, formData, {
      reportProgress: true,
      headers: new HttpHeaders({
        Authorization: `Bearer ${this._loginService.jwtToken}`,
      }),
    });

    return new Promise<FileUploadResponseDto>((resolve, reject) => {
      this._httpClient.request(req).subscribe({
        next: (data: any) => {
          if (data.type === HttpEventType.UploadProgress) {
            if (httpApiRequestOptions?.progressCallback) {
              const progress = Math.round((100 * data.loaded) / (data.total ?? 1));
              httpApiRequestOptions.progressCallback(progress);
            }
            return;
          }

          if (data.type === HttpEventType.Response) {
            resolve(data.body);
          }
        },
        error: (error: any) => {
          const errorMessage =
            error.status === 401 ? 'No tiene permisos para realizar esta acción' : error?.error?.message ?? 'Error al subir el archivo';
          reject(errorMessage);
        },
      });
    });
  }

  public downloadFile(url: string, expectedContentType: string, filename: string, params?: any): Observable<Blob> {
    let headers = new HttpHeaders({
      'Content-Type': 'text/plain',
      Authorization: `Bearer ${this._loginService.jwtToken}`,
    });

    const request = this._httpClient.get(this._baseUrl + url, { headers, params, responseType: 'blob' });
    request.subscribe({
      next: (file: any) => {
        const blob = new Blob([file], { type: expectedContentType });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      },
    });

    return request;
  }
}
