<mf-page title="Notificaciones">
  <mf-row>
    <mf-grid
      #grid
      [actionButtons]="gridActionButtons"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems"
      [globalFilters]="globalFilters"
      [getRowClass]="getRowClass" />
  </mf-row>
</mf-page>
