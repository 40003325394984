@if (bankAccountDto) {
<mf-page title="Administrar personas asociadas a la cuenta de {{ bankAccountDto.holderName }}">
  <mf-row>
    <mf-form
      [formGroup]="addAssociatedPersonForm"
      (save)="save($event)">
      <mf-autocomplete-person
        formControlName="person"
        (itemIdChanged)="addAssociatedPersonForm.patchValue({ personId: $event })" />
    </mf-form>
  </mf-row>
  <mf-grid
    #grid
    [apiEndpoint]="apiEndpoint"
    [columns]="columns"
    [menuItems]="menuItems"
    [initialFilters]="filter" />
</mf-page>
}
