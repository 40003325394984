import { Component, Input } from '@angular/core';
import { MenuItem } from '../MenuItem';

@Component({
  standalone: false,
  selector: 'mf-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input()
  menuItem: MenuItem;
}
