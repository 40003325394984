import { Component, EventEmitter, Output } from '@angular/core';
import { BankAccountDto } from '../../../../../api/dtos/BankAccountDto';
import { BankAccountValidationInfoDto } from '../../../../../api/dtos/BankAccountValidationInfoDto';
import { ApiClientAdminBankAccount } from '../../../../../api/endpoints/ApiClientAdminBankAccount';

@Component({
  standalone: false,
  selector: 'mf-holder-verification',
  templateUrl: './holder-verification.component.html',
  styleUrls: ['./holder-verification.component.scss'],
})
export class HolderVerificationComponent {
  constructor(private _apiClientAdminBankAccount: ApiClientAdminBankAccount) {}

  bankAccount: BankAccountDto;

  @Output()
  validate = new EventEmitter<BankAccountValidationInfoDto>();

  bankAccountValidationInfoDto: BankAccountValidationInfoDto;

  async getHolderInfo(bankAccount: BankAccountDto) {
    if (!bankAccount) {
      return;
    }

    this.bankAccountValidationInfoDto = await this._apiClientAdminBankAccount.getValidationInfo(bankAccount);
    this.validate.emit(this.bankAccountValidationInfoDto);
  }

  accountInfoIsAvailable() {
    return (
      this.bankAccountValidationInfoDto && (this.bankAccountValidationInfoDto.errorMessage || this.bankAccountValidationInfoDto.holderName)
    );
  }

  getHolderName() {
    if (this.accountInfoIsAvailable()) {
      return this.bankAccountValidationInfoDto.errorMessage + this.bankAccountValidationInfoDto.holderName;
    }

    if (this.bankAccount?.holderOnValidation) {
      return this.bankAccount?.holderOnValidation;
    }

    return '--';
  }

  getCurrencies() {
    if (this.accountInfoIsAvailable()) {
      return this.bankAccountValidationInfoDto.errorMessage + this.bankAccountValidationInfoDto.currencies;
    }

    if (this.bankAccount?.currenciesOnValidation) {
      return this.bankAccount?.currenciesOnValidation;
    }

    return '--';
  }
}
