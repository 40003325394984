import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IGridFilter } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';

@Component({
  standalone: false,
  selector: 'mf-grid-filter-checkbox',
  templateUrl: './grid-filter-checkbox.component.html',
  styleUrls: ['./grid-filter-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterCheckboxComponent),
      multi: true,
    },
  ],
})
export class GridFilterCheckboxComponent extends GridFilterBase<IGridFilter, boolean> {}
