import { Component, Input } from '@angular/core';
import { HeaderMenuItem } from './header-menu-item';

@Component({
  standalone: false,
  selector: 'mf-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input()
  menuItems: HeaderMenuItem[] = [];
  @Input()
  buttonName: string = '';
  @Input()
  buttonLegend: string = '';
}
