import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminNotification } from '../../../api/endpoints/ApiClientAdminNotification';
import { NotificationDto } from '../../../api/dtos/NotificationDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';

@Component({
  selector: 'mf-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
})
export class NotificationDetailsComponent {
  constructor(private _apiClientAdminNotification: ApiClientAdminNotification, modalService: ModalService) {
    this.load(modalService.openData);
  }

  enumName = enumName_mi_pspc;
  notificationDto: NotificationDto;

  async load(id: string) {
    this.notificationDto = await this._apiClientAdminNotification.getById(id);
  }
}
