import { Component, EventEmitter, Output } from '@angular/core';
import { IssueResolutionListDto } from '../../../../api/dtos/IssueResolutionListDto';
import { IssueDto } from '../../../../api/dtos/IssueDto';
import { ApiAdminIssue } from '../../../../api/endpoints/ApiAdminIssue';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  standalone: false,
  selector: 'mf-related-entity-issue-popup',
  templateUrl: './related-entity-issue-popup.component.html',
  styleUrls: ['./related-entity-issue-popup.component.scss'],
})
export class RelatedEntityIssuePopupComponent {
  constructor(private _apiAdminIssue: ApiAdminIssue, private _modalService: ModalService) {
    this.items = this._modalService.openData.items;
  }

  @Output()
  save = new EventEmitter<IssueResolutionListDto>();

  issueResolutionListDto = new IssueResolutionListDto();
  items: IssueDto[];

  selectItems(issues: IssueDto[]) {
    this.issueResolutionListDto.entityIdList = [];
    issues.forEach((c) => {
      this.issueResolutionListDto.entityIdList.push(c.id);
    });
  }

  async submit() {
    await this._apiAdminIssue.bulkResolve(this.issueResolutionListDto);
    this._modalService.closeAsSuccess('Incidentes resueltos con éxito');
  }

  cancel() {
    this._modalService.closeAsCancel();
  }
}
