// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { GatewayDto } from "../dtos/GatewayDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { GatewayFiltersDto } from "../dtos/GatewayFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminGateway {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    gatewayDto: GatewayDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayDto> {
    return this._httpApiClient.post("/admin/gateway", gatewayDto, httpApiRequestOptions);
  }

  edit(
    gatewayDto: GatewayDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayDto> {
    return this._httpApiClient.put("/admin/gateway", gatewayDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<GatewayDto> {
    return this._httpApiClient.get("/admin/gateway/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<GatewayFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<GatewayDto>> {
    return this._httpApiClient.post("/admin/gateway/paged", filters, httpApiRequestOptions);
  }

  suggest(
    gatewayFilterDto: SuggestSearchDto<GatewayFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<GatewayDto>> {
    return this._httpApiClient.post("/admin/gateway/suggest", gatewayFilterDto, httpApiRequestOptions);
  }

}
