import { Component } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { PagedSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { FilterType } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { TrackDto } from '../../../api/dtos/TrackDto';
import { TrackFiltersDto } from '../../../api/dtos/TrackFiltersDto';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';
import { ApiClientAdminTrack } from '../../../api/endpoints/ApiClientAdminTrack';
import { SearchOrderDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SearchOrderDto';

@Component({
  standalone: false,
  selector: 'mf-track-grid',
  templateUrl: './track-grid.component.html',
  styleUrls: ['./track-grid.component.scss'],
})
export class TrackGridComponent {
  constructor(public _apiClientAdminTrack: ApiClientAdminTrack, private _modalService: ModalService) {}

  columns: IGridColumn<TrackDto>[] = [
    {
      field: nameof<TrackDto>((t) => t.date),
      header: 'Fecha',
      width: 20,
      cellOptions: {
        type: CellValueType.Date,
        dateIncludeTime: true,
      },
      filter: {
        propertyName: nameof<TrackDto>((t) => t.date),
        type: FilterType.Date,
      },
    },
    {
      field: nameof<TrackDto>((t) => t.displayText),
      header: 'Descripción',
      width: 60,
      filter: {
        propertyName: nameof<TrackDto>((t) => t.displayText),
        type: FilterType.Text,
      },
      orderByPrevent: true,
    },
    {
      field: nameof<TrackDto>((t) => t.user.naturalPerson.fullName),
      header: 'Persona',
      width: 30,
      filter: {
        propertyName: nameof<TrackDto>((t) => t.userId),
        type: FilterType.Text,
      },
      render: (item: TrackDto) => (item.user?.naturalPerson?.fullName ? item.user.naturalPerson.fullName : 'Creado por api'),
    },
  ];

  defaultOrderBy: SearchOrderDto = { ascendent: true, columnName: nameof<TrackDto>((t) => t.date) };

  apiEndpoint = (search: PagedSearchDto<TrackFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) => {
    search.filters.entityId = this._modalService.openData;
    search.orderBy = search.orderBy.length == 0 ? [this.defaultOrderBy] : search.orderBy;
    return this._apiClientAdminTrack.getPaged(search, httpApiRequestOptions);
  };
}
