// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { BankDto } from "../dtos/BankDto";

@Injectable({providedIn: 'root'})
export class ApiAdminBank {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    bankDto: BankDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankDto> {
    return this._httpApiClient.post("/admin/bank", bankDto, httpApiRequestOptions);
  }

  edit(
    bankDto: BankDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<BankDto> {
    return this._httpApiClient.put("/admin/bank", bankDto, httpApiRequestOptions);
  }

}
