import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { GatewayBankAccountDto, GatewayBankAccountDtoFormGroup } from '../../../../api/dtos/GatewayBankAccountDto';
import { Currency } from '../../../../api/enums/Currency';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';

@Component({
  standalone: false,
  selector: 'mf-gateway-bank-account',
  templateUrl: './gateway-bank-account.component.html',
  styleUrls: ['./gateway-bank-account.component.scss'],
})
export class GatewayBankAccountComponent implements OnInit {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  gatewayBankAccountDto: GatewayBankAccountDto;
  @Input()
  miPspcId: string;

  @Output()
  save = new EventEmitter<GatewayBankAccountDto>();

  @Output()
  close = new EventEmitter();

  enumName = enumName_mi_pspc;
  currencies = Currency;

  ngOnInit(): void {
    this.gatewayBankAccountForm.patchValue(this.gatewayBankAccountDto);
  }

  get currency(): Currency {
    return this.gatewayBankAccountForm.value.currency as Currency;
  }

  get bankId(): string {
    return this.gatewayBankAccountForm.value.bankId as string;
  }

  gatewayBankAccountForm = this._cfb.group<GatewayBankAccountDtoFormGroup>({
    id: [GUID_EMPTY],
    currency: [null, Validators.required, 'Moneda'],
    bank: [null, Validators.required, 'Banco'],
    bankId: ['', Validators.required],
    bankBranch: [null, Validators.required, 'Sucursal'],
    bankBranchId: ['', Validators.required],
    accountNumber: [null, Validators.required, 'Número de cuenta'],
    accountType: [null, Validators.required, 'Tipo de cuenta'],
    holder: [null, Validators.required, 'Titular'],
    holderId: ['', Validators.required],
  });
}
