import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { nameof } from 'ts-simple-nameof';
import { LoginService } from '../../../../../../../libs/utils/auth-shared/src/lib/services/login.service';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { BankAccountDto } from '../../../../api/dtos/BankAccountDto';
import { BankAccountFiltersDto } from '../../../../api/dtos/BankAccountFiltersDto';
import { ApiAdminBankAccount } from '../../../../api/endpoints/ApiAdminBankAccount';
import { ApiClientAdminBankAccount } from '../../../../api/endpoints/ApiClientAdminBankAccount';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { Roles } from '../../../../api/enums/Roles';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { BankAccountAddAssociatedPersonComponent } from './bank-account-add-associated-person.component';
import { BankAccountCreateComponent } from './bank-account-create.component';
import { BankAccountEditComponent } from './bank-account-edit.component';

@Component({
  selector: 'mf-bank-account-grid',
  templateUrl: './bank-account-grid.component.html',
  styleUrls: ['./bank-account-grid.component.scss'],
})
export class BankAccountGridComponent {
  constructor(
    private _apiClientAdminBankAccount: ApiClientAdminBankAccount,
    private _apiAdminBankAccount: ApiAdminBankAccount,
    private _modalService: ModalService,
    private _activatedRoute: ActivatedRoute,
    private _loginService: LoginService
  ) {
    if (this._activatedRoute.snapshot.queryParams.id) {
      this.initialFilters.id = this._activatedRoute.snapshot.queryParams.id;
    }
  }
  @ViewChild('grid')
  grid: GridComponent;
  bankAccount: BankAccountDto;

  initialFilters = new BankAccountFiltersDto();

  columns: IGridColumn<BankAccountDto>[] = [
    { field: nameof<BankAccountDto>((p) => p.accountNumber), header: 'Nro cuenta', width: 10 },
    {
      field: nameof<BankAccountDto>((p) => p.bank.name),
      header: 'Banco',
      width: 5,
      filter: { propertyName: nameof<BankAccountFiltersDto>((p) => p.bank), type: FilterType.Entity, entityType: 'Bank' },
    },
    {
      field: nameof<BankAccountDto>((p) => p.bankBranch.name),
      header: 'Sucursal',
      width: 5,
    },
    { field: nameof<BankAccountDto>((p) => p.person.fullName), header: 'Titular', width: 10 },
    {
      field: nameof<BankAccountDto>((p) => p.currency),
      header: 'Moneda',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<BankAccountFiltersDto>((p) => p.currencyList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      field: nameof<BankAccountDto>((p) => p.accountType),
      header: 'Tipo',
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.AccountType,
      },
    },
    { field: nameof<BankAccountDto>((p) => p.isVerified), header: 'Verificada', width: 5 },
  ];

  menuItems: GridMenuItem[] = !this._loginService.userIsInRole([Roles[Roles.SuperAdmin]])
    ? [
        {
          label: 'No disponible',
          icon: Icons.clear,
          onClick: () => {},
        },
      ]
    : [
        {
          label: 'Editar',
          icon: Icons.edit,
          onClick: (bankAccountDto: BankAccountDto) => {
            this._modalService.openRightModal(BankAccountEditComponent, {
              openData: bankAccountDto.id,
              onCloseSuccess: () => {
                this.grid.reloadPage();
              },
            });
          },
        },
        {
          label: 'Inactivar',
          icon: Icons.delete,
          onClick: (bankAccountDto: BankAccountDto) => {
            this._modalService.openYesOrNoModal('¿Está seguro de inactivar la cuenta bancaria?', {
              onCloseSuccess: async () => {
                await this._apiAdminBankAccount.inactivate(bankAccountDto.id);
                this.grid.reloadPage();
              },
            });
          },
        },
        {
          label: 'Ver tracking',
          icon: Icons.list,
          onClick: (bankAccountDto: BankAccountDto) => {
            this._modalService.openLargeModal(TrackingPopupComponent, {
              openData: bankAccountDto.id,
              onCloseSuccess: async () => {},
            });
          },
        },
        {
          label: 'Personas asociadas',
          icon: Icons.edit,
          onClick: (bankAccountDto: BankAccountDto) => {
            this._modalService.openRightModal(BankAccountAddAssociatedPersonComponent, {
              openData: bankAccountDto.id,
              onCloseSuccess: () => {
                this.grid.reloadPage();
              },
            });
          },
        },
      ];

  apiEndpoint = (search: PagedSearchDto<BankAccountFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminBankAccount.getPaged(search, httpApiRequestOptions);

  openNewBankAccountPopup() {
    this._modalService.openRightModal(BankAccountCreateComponent, {
      onCloseSuccess: () => {
        this.grid.reloadPage();
      },
    });
  }
}
