// File generated by: MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
export enum OrderStatus {
	Draft = 1,
	Pending = 2,
	Processing = 3,
	Completed = 4,
	Cancelled = 5,
	Refunding = 6,
	Refunded = 7,
}
