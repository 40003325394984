import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankBranchDto } from '../../../../api/dtos/BankBranchDto';
import { ApiAdminBankBranch } from '../../../../api/endpoints/ApiAdminBankBranch';
import { ApiClientAdminBankBranch } from '../../../../api/endpoints/ApiClientAdminBankBranch';

@Component({
  standalone: false,
  selector: 'mf-bank-branch-edit',
  templateUrl: './bank-branch-edit.component.html',
  styleUrls: ['./bank-branch-edit.component.scss'],
})
export class BankBranchEditComponent {
  constructor(
    private _apiAdminBankBranch: ApiAdminBankBranch,
    private _apiClientAdminBankBranch: ApiClientAdminBankBranch,
    private _modalService: ModalService
  ) {
    this.load();
  }

  bankBranchDto: BankBranchDto;

  async load() {
    this.bankBranchDto = await this._apiClientAdminBankBranch.getById(this._modalService.openData);
  }

  async save(bankBranchDto: BankBranchDto) {
    await this._apiAdminBankBranch.edit(bankBranchDto);
    this._modalService.closeAsSuccess('Banco editado correctamente');
  }
}
