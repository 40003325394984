import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { nameof } from 'ts-simple-nameof';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { GridComponent } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { NavigationService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { OrderDto } from '../../../../api/dtos/OrderDto';
import { OrderFiltersDto } from '../../../../api/dtos/OrderFiltersDto';
import { ApiClientAdminOrderGeneric } from '../../../../api/endpoints/ApiClientAdminOrderGeneric';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { AppRoute } from '../../../app.routes.path';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';

@Component({
  standalone: false,
  selector: 'mf-order-grid',
  templateUrl: './order-grid.component.html',
  styleUrls: ['./order-grid.component.scss'],
})
export class OrderGridComponent implements OnInit {
  constructor(
    public _apiClientAdminOrder: ApiClientAdminOrderGeneric,
    private _modalService: ModalService,
    private _navigationService: NavigationService
  ) {}

  @ViewChild('grid')
  grid: GridComponent;

  @Input() hideFilters = false;
  @Input() showCreateButton = false;
  @Input() transferId: string;

  filters: OrderFiltersDto;

  mustIncludeFields = [nameof<OrderDto>((o) => o.operation.operationNumber)];

  ngOnInit(): void {
    this.filters = new OrderFiltersDto();
    if (this.transferId) {
      this.filters.transferId = this.transferId;
    }
  }

  columns: IGridColumn<OrderDto>[] = [
    {
      field: nameof<OrderDto>((o) => o.type),
      header: 'Tipo',
      width: 5,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderType,
      },

      filter: {
        propertyName: nameof<OrderFiltersDto>((o) => o.type),
        type: FilterType.Enum,
        enumName: enumName_mi_pspc.OrderType,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.person.displayText),
      header: 'Contraparte',
      width: 10,

      filter: {
        propertyName: nameof<OrderFiltersDto>((o) => o.person.displayText),
        type: FilterType.Text,
      },
    },
    {
      field: nameof<OrderDto>((o) => o.status),
      header: 'Estado',
      width: 5,

      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.OrderStatus,
      },
      filter: {
        propertyName: nameof<OrderFiltersDto>((o) => o.statusList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.OrderStatus,
      },
    },
    { field: nameof<OrderDto>((o) => o.destinationBankAccount.bank.name), header: 'Destino', width: 5 },
    { field: nameof<OrderDto>((o) => o.destinationBankAccount.accountNumber), header: 'Nro cuenta', width: 5, align: 'right' },
    { field: nameof<OrderDto>((o) => o.destinationBankAccount.holder.displayText), header: 'Titular', width: 10, align: 'right' },
    {
      field: nameof<OrderDto>((o) => o.destinationBankAccount.currency),
      header: 'Moneda',
      width: 5,
      align: 'right',
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
      filter: {
        propertyName: nameof<OrderFiltersDto>((o) => o.currency),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    { field: nameof<OrderDto>((o) => o.amount), header: 'Monto', width: 10, align: 'right' },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.list,
      onClick: (orderDto: OrderDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: orderDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
    {
      label: 'Ver operación',
      icon: Icons.search,
      onClick: (orderDto: OrderDto) => {
        this._navigationService.navigateNewTab(AppRoute.operations, ['operationNumber=' + orderDto.operation.operationNumber]);
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<OrderFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminOrder.getPaged(search, httpApiRequestOptions);
}
