import { Component, EventEmitter, HostBinding, Input, Output, forwardRef } from '@angular/core';
import { FilterType } from '../interface/filter-type';
import { IGridFilter } from '../interface/grid-filter';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModelValueBaseComponent } from '../../model-value-base.component';

@Component({
  standalone: false,
  selector: 'mf-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterComponent),
      multi: true,
    },
  ],
})
export class GridFilterComponent extends ModelValueBaseComponent<any> {
  @Input()
  gridFilter: IGridFilter;

  @HostBinding('class.')
  highlight = '';

  filterType = FilterType;

  @Output()
  relatedIdChange = new EventEmitter<string>();

  updateCompleteValue(value: any): void {
    this.value = value;
  }
}
