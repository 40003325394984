export const Icons = {
  accountCircle: 'account_circle',
  accountBalance: 'account_balance_wallet',
  accreditation: 'history_2',
  accreditationByAccount: 'history_toggle_off',
  archive: 'archive',
  bank: 'account_balance',
  bankBranch: 'business',
  bankMovement: 'sync_alt',
  computer: 'computer',
  company: 'store',
  conciliations: 'compare_arrows',
  chevronLeft: 'chevron_left',
  checkCircle: 'check_circle',
  chevronRight: 'chevron_right',
  check: 'check',
  clear: 'clear',
  collects: 'arrow_upward',
  create: 'add',
  cross: 'close_small',
  details: 'details',
  delete: 'delete',
  download: 'download',
  edit: 'edit',
  email: 'email',
  error: 'warning',
  expandLess: 'expand_less',
  expandMore: 'expand_more',
  file: 'file_upload',
  filterAlt: 'filter_alt',
  filterList: 'filter_list',
  firstPage: 'first_page',
  gateway: 'key',
  google: 'label_important',
  group: 'group',
  issue: 'report',
  lastPage: 'last_page',
  list: 'list',
  logout: 'logout',
  operations: 'payments',
  payments: 'arrow_downward',
  markAsRead: 'visibility',
  markAsUnread: 'visibility_off',
  menu: 'menu',
  moreVert: 'more_vert',
  naturalPerson: 'person',
  notifications: 'notifications',
  openInNew: 'open_in_new',
  refresh: 'refresh',
  report: 'post_add',
  search: 'search',
  settings: 'settings',
  star: 'star',
  template: 'rounded_corner',
  transfer: 'drag_handle',
  unarchive: 'unarchive',
  undo: 'undo',
  upload: 'upload',
  user: 'account_box',
  web: 'web',
};
