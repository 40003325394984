<mf-form
  [formGroup]="clientForm"
  [entity]="clientDto"
  (save)="save.emit($event)">
  @if (clientDto) {
  <mf-column>
    <mf-field-text formControlName="name" />
  </mf-column>
  <mf-column>
    <mf-autocomplete-company
      label="Empresa"
      formControlName="company"
      (itemIdChanged)="clientForm.patchValue({ companyId: $event })" />
  </mf-column>
  <mf-column>
    <mf-field-text
      label="Mail para notificaciones"
      formControlName="notificationEmail" />
  </mf-column>
  <mf-column>
    <mf-field-text
      label="Endpoint Url"
      formControlName="endpointUrl" />

    <mf-button
      text="Test WebHook"
      (click)="testWebHook()" />
  </mf-column>
  @if (clientDto.id) {
  <mf-column>
    <mf-field-text
      formControlName="apiKey"
      hint="ClientID: {{ clientDto.id }}" />
  </mf-column>
  } }
</mf-form>
