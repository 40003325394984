import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { ApiClientAdminUploadFile } from '../../../../api/endpoints/ApiClientAdminUploadFile';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { ClientDto } from '../../../../api/dtos/ClientDto';
import { ApiAdminClient } from '../../../../api/endpoints/ApiAdminClient';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import {
  TransferVoucherForCollectOrderDto,
  TransferVoucherForCollectOrderDtoFormGroup,
} from '../../../../api/dtos/TransferVoucherForCollectOrderDto';

@Component({
  selector: 'mf-collect-order-voucher',
  templateUrl: './collect-order-voucher.component.html',
  styleUrls: ['./collect-order-voucher.component.scss'],
})
export class CollectOrderVoucherComponent implements OnInit {
  constructor(
    private _cfb: CustomFormBuilder,
    private _apiClientAdminUploadFile: ApiClientAdminUploadFile,
    private _apiAdminClient: ApiAdminClient
  ) {
    this.endpoint = (fileList: FileList, httpApiRequestOptions: HttpApiRequestOptions) =>
      this._apiClientAdminUploadFile.uploadFile(fileList, httpApiRequestOptions);
    this.loadMiPspcId();
  }

  endpoint: any;

  @Input()
  transferVoucherForCollectOrderDto = new TransferVoucherForCollectOrderDto();
  @Input()
  personId: string | null;

  @Output()
  save = new EventEmitter<TransferVoucherForCollectOrderDto>();

  miPspcId: string | null;

  ngOnInit(): void {
    this.collectOrderVoucherForm.patchValue(this.transferVoucherForCollectOrderDto);
  }

  async loadMiPspcId() {
    const miPspcClientDto: ClientDto = await this._apiAdminClient.getDefault();
    this.miPspcId = miPspcClientDto.companyId;
  }

  collectOrderVoucherForm = this._cfb.group<TransferVoucherForCollectOrderDtoFormGroup>({
    amount: [null, Validators.required, 'Monto'],
    clientId: [null, Validators.required, 'Cliente'],
    collectOrders: [null, Validators.required],
    currency: [null, Validators.required, 'Moneda'],
    uploadDate: [null, Validators.required, 'Fecha'],
    destinationBankAccountId: ['', Validators.required],
    destinationBankAccount: ['', Validators.required, 'Cuenta de destino'],
    voucherUrl: [null, Validators.required, 'Comprobante'],
    reference: [null, Validators.required, 'Referencia'],
    sourceBankAccountId: ['', Validators.required],
    sourceBankAccount: ['', Validators.required, 'Cuenta de origen'],
  });

  selectItems(collectOrders: CollectOrderDto[]) {
    this.transferVoucherForCollectOrderDto.collectOrders = [];
    collectOrders.forEach((c) => {
      this.transferVoucherForCollectOrderDto.collectOrders.push({
        amount: c.amount,
        collectOrderId: c.id,
        newCollectOrderFromBalanceExternalId: c.externalId,
        newPaymentOrderForRefundExternalId: '',
      });
    });
    this.collectOrderVoucherForm.patchValue({ collectOrders: this.transferVoucherForCollectOrderDto.collectOrders });
  }
}
