<ng-content></ng-content>
@if (!validationSummaryHide || !acceptButtonHide || closeButtonShow) {
<mf-row align="right">
  @if (closeButtonShow) {
  <mf-button-secondary
    [text]="closeButtonText"
    (click)="closeButtonClick.emit()" />
  } @if (!validationSummaryHide) {
  <mf-validation-summary
    [linkedFormGroup]="formGroup"
    [triedSubmit]="triedSubmit" />
  } @if (!acceptButtonHide) {
  <mf-button
    [disabled]="disableButton"
    [text]="textButton"
    [isLoading]="isLoading"
    (click)="submit()" />
  }
</mf-row>
}
