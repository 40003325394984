// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { ClientDto } from "../dtos/ClientDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminClient {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getCurrentClient(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ClientDto> {
    return this._httpApiClient.get("/client-admin/client/get-current-client", httpApiRequestOptions);
  }

  editCurrent(
    clientDto: ClientDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<ClientDto> {
    return this._httpApiClient.put("/client-admin/client", clientDto, httpApiRequestOptions);
  }

}
