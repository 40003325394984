export enum FilterEntityType {
  Accreditation = 'Accreditation',
  Bank = 'Bank',
  Client = 'Client',
  Person = 'Person',
  BankAccount = 'BankAccount',
  GatewayBankAccount = 'GatewayBankAccount',
  Operation = 'Operation',
  User = 'User',
}
