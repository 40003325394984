import { Component, ElementRef } from '@angular/core';
import { ButtonComponent } from './button.component';

@Component({
  standalone: false,
  selector: 'mf-button-secondary',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonSecondaryComponent extends ButtonComponent {
  constructor(_ref: ElementRef) {
    super(_ref);
    this.color = 'secondary';
  }
}
