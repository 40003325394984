import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { CollectOrderDto } from '../../../../api/dtos/CollectOrderDto';
import { ApiClientAdminCollectOrder } from '../../../../api/endpoints/ApiClientAdminCollectOrder';

@Component({
  standalone: false,
  selector: 'mf-collect-order-edit',
  templateUrl: './collect-order-edit.component.html',
  styleUrls: ['./collect-order-edit.component.scss'],
})
export class CollectOrderEditComponent {
  constructor(private _apiClientAdminCollectOrder: ApiClientAdminCollectOrder, private _modalService: ModalService) {
    this.load(this._modalService.openData.collectOrderId);
  }

  collectOrderDto: CollectOrderDto;

  async load(id: string) {
    this.collectOrderDto = await this._apiClientAdminCollectOrder.getById(id);
  }

  async save(collectOrderDto: CollectOrderDto) {
    collectOrderDto.id = this.collectOrderDto.id;
    await this._apiClientAdminCollectOrder.editDraft(collectOrderDto);
    this._modalService.closeAsSuccess('La orden se editó con éxito');
  }
}
