<ng-container [formGroup]="templateForm">
  <mf-column [md]="1">
    <mf-field-select
      formControlName="paperSize"
      [enumName]="enumName.PaperSize"
      [enumNameModule]="moduleName.templates" />
  </mf-column>
  <mf-column [md]="1">
    <mf-field-select
      formControlName="orientation"
      [enumName]="enumName.PaperOrientation"
      [enumNameModule]="moduleName.templates" />
  </mf-column>
  <mf-column [md]="1">
    <mf-field-toggle formControlName="displayPageNumber" />
  </mf-column>
  @if (templatePdfDto.paperSize == paperSize.Custom) {
  <mf-column [md]="1">
    <mf-field-numeric formControlName="paperCustomWidth" />
  </mf-column>
  <mf-column [md]="1">
    <mf-field-numeric formControlName="paperCustomHeight" />
  </mf-column>
  }
</ng-container>
