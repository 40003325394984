<mf-page title="Acreditaciones por cuenta">
  <mf-row>
    @if (initialFilters()) {
    <mf-grid
      #grid
      [initialFilters]="initialFilters()!"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [mustIncludeFields]="mustIncludeFields"
      [menuItems]="menuItems" />
    }
  </mf-row>
</mf-page>
