import { Component, Inject, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Icons } from '../../icon/icons';
import { INotificationTrayService } from '../INotificationTray';

@Component({
  standalone: false,
  selector: 'mf-notification-tray',
  templateUrl: './notification-tray.component.html',
  styleUrls: ['./notification-tray.component.scss'],
})
export class NotificationTrayComponent {
  constructor(@Inject('INotificationTrayService') public notificationTrayService: INotificationTrayService) {}

  @ViewChild(MatMenuTrigger)
  matMenuTrigger: MatMenuTrigger;

  counter: number;
  icons = Icons;

  openNotifications() {
    this.notificationTrayService.navigateToNotificationPage();
    this.matMenuTrigger.closeMenu();
  }

  setAllAsRead() {
    this.notificationTrayService.setAllAsRead();
  }

  get notificationTray() {
    return this.notificationTrayService.notificationTray;
  }
}
