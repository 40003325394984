import { Component, ViewChild } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ValueListDto } from '../../../api/dtos/ValueListDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { ValueListComponent } from './value-list.component';
import { ApiAdminValueList } from '../../../api/endpoints/ApiAdminValueList';

@Component({
  standalone: false,
  selector: 'mf-value-list-create',
  templateUrl: './value-list-create.component.html',
  styleUrls: ['./value-list-create.component.scss'],
})
export class ValueListCreateComponent {
  constructor(private apiValueList: ApiAdminValueList, private _modalService: ModalService) {}

  @ViewChild('valueListComponent')
  valueListComponent: ValueListComponent;
  enumName: enumName_mi_pspc;

  async submit(valueListDto: ValueListDto) {
    await this.apiValueList.create(valueListDto);
    this._modalService.closeAsSuccess('La lista administrable se creo con éxito');
  }

  close() {
    this._modalService.closeAsSuccess('');
  }
}
