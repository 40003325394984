import { Component } from '@angular/core';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { GatewayDto } from '../../../api/dtos/GatewayDto';
import { ApiAdminGateway } from '../../../api/endpoints/ApiAdminGateway';

@Component({
  selector: 'mf-gateway-create',
  templateUrl: './gateway-create.component.html',
  styleUrls: ['./gateway-create.component.scss'],
})
export class GatewayCreateComponent {
  constructor(private _apiAdminGateway: ApiAdminGateway, private _modalService: ModalService) {}

  async save(gatewayDto: GatewayDto) {
    await this._apiAdminGateway.create(gatewayDto);
    this._modalService.closeAsSuccess('La pasarela se creó con éxito');
  }
}
