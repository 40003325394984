// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { BaseEntityDto, BaseEntityDtoFormGroup } from './BaseEntityDto';
import { Currency } from '../enums/Currency';

export class OperationSimpleDto extends BaseEntityDto {
  amount: number = 0;
  currency: Currency;
  operationNumber: number | null = null;
}

export interface OperationSimpleDtoFormGroup extends BaseEntityDtoFormGroup {
  amount?: any;
  currency?: any;
  operationNumber?: any;
}
