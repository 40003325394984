import { Component, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  standalone: false,
  selector: 'mf-link',
  templateUrl: 'link.component.html',
  styleUrls: ['link.component.scss'],
})
export class LinkComponent {
  constructor(private _navigationService: NavigationService) {}

  @Input() href: string = '';
  @Input() text: string = '';
  @Input() target: '_blank' | 'self' = 'self';

  click($event: MouseEvent) {
    $event.preventDefault();
    if (this.href == null || this.href === '') {
      return;
    }

    if (this.target == '_blank') {
      this._navigationService.navigateNewTab(this.href);
      return;
    }
    this._navigationService.navigate(this.href);
  }
}
