import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { CustomFormBuilder } from '../../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { PersonType } from '../../../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { BankAccountDto, BankAccountDtoFormGroup } from '../../../../api/dtos/BankAccountDto';
import { BankAccountValidationInfoDto } from '../../../../api/dtos/BankAccountValidationInfoDto';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { HolderVerificationComponent } from './holder-verification/holder-verification.component';

@Component({
  standalone: false,
  selector: 'mf-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
})
export class BankAccountComponent {
  constructor(private _cfb: CustomFormBuilder, private cd: ChangeDetectorRef) {}

  enumName = enumName_mi_pspc;
  icons = Icons;
  personType = PersonType;
  validationInfoDto: BankAccountValidationInfoDto;

  get bankId(): string {
    return this.bankAccountForm.value.bankId as string;
  }

  @Input()
  bankAccountDto: BankAccountDto;
  @Input()
  hideVerifyButton = false;
  @ViewChild('holderVerificationComponent')
  holderVerificationComponent: HolderVerificationComponent;
  @Output()
  save = new EventEmitter<BankAccountDto>();
  @Output()
  verify = new EventEmitter();

  @Output()
  close = new EventEmitter();

  bankAccountForm = this._cfb.group<BankAccountDtoFormGroup>({
    id: [GUID_EMPTY],
    accountNumber: ['', Validators.required, 'Nro de cuenta'],
    accountType: ['', Validators.required, 'Tipo de cuenta'],
    associatedPersonBankAccountsList: [null, ,],
    bankId: ['', Validators.required],
    bank: [null, Validators.required, 'Banco'],
    bankBranchId: [GUID_EMPTY],
    bankBranch: [null, , 'Sucursal'],
    currency: ['', Validators.required, 'Moneda'],
    holderId: ['', Validators.required],
    holderName: ['', Validators.required, 'Titular'],
    holder: [null, Validators.required, 'Persona'],
    isDefault: [false, Validators.required, 'Predeterminada'],
    isVerified: [false],
  });

  ngOnInit(): void {
    this.bankAccountForm.patchValue(this.bankAccountDto);
  }

  ngAfterViewInit() {
    const validationInfo = new BankAccountValidationInfoDto();
    validationInfo.holderName = this.bankAccountDto?.holderOnValidation;
    validationInfo.currencies = this.bankAccountDto?.currenciesOnValidation;
    this.holderVerificationComponent.bankAccountValidationInfoDto = validationInfo;
    this.cd.detectChanges();
  }

  setPersonValue(event: string | null) {
    this.bankAccountForm.patchValue({ holderId: event });
    const personName = this.bankAccountForm.value.holder?.fullName;
    this.bankAccountForm.patchValue({ holderName: personName });
  }

  getHolderInfo() {
    this.bankAccountForm.markAllAsTouched();
    if (this.bankAccountForm.invalid) {
      return;
    }
    this.bankAccountDto = { ...this.bankAccountDto, ...this.bankAccountForm.value };
    this.holderVerificationComponent.getHolderInfo(this.bankAccountDto);
  }

  markAsVerified() {
    this.bankAccountForm.markAllAsTouched();
    if (this.bankAccountForm.invalid) {
      return;
    }
    this.bankAccountDto = { ...this.bankAccountDto, ...this.bankAccountForm.value };
    this.verify.emit();
  }
}
