<mf-page title="Conciliaciones">
  <mf-row>
    <mf-grid
      #grid
      actionButtonText="Enviar transferencias a DaMap"
      [initialFilters]="filters"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems"
      (actionButtonClick)="conciliate()" />
  </mf-row>
</mf-page>
