<mf-page title="Detalles de la notificación">
  @if (notificationDto) {
  <mf-column-display label="Id">{{ notificationDto.id }}</mf-column-display>
  <mf-column-display label="Fecha">{{ notificationDto.date | dateUtc }}</mf-column-display>
  <mf-column-display label="Tipo">{{ notificationDto.type | translate : enumName.NotificationType }}</mf-column-display>
  <mf-column-display label="Leído">{{ notificationDto.isRead ? 'Sí' : 'No' }}</mf-column-display>
  <mf-row-display label="Título">{{ notificationDto.title }}</mf-row-display>
  <mf-row-display label="Texto">{{ notificationDto.text }}</mf-row-display>

  }
</mf-page>
