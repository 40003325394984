import { Component } from '@angular/core';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { BankBranchDto } from '../../../../api/dtos/BankBranchDto';
import { ApiAdminBankBranch } from '../../../../api/endpoints/ApiAdminBankBranch';
import { GUID_EMPTY } from '../../../../../../../libs/utils/ts-shared/src/lib/utils-guid';
import { ApiClientAdminBank } from '../../../../api/endpoints/ApiClientAdminBank';
import { ApiClientAdminBankBranch } from '../../../../api/endpoints/ApiClientAdminBankBranch';

@Component({
  standalone: false,
  selector: 'mf-bank-branch-create',
  templateUrl: './bank-branch-create.component.html',
  styleUrls: ['./bank-branch-create.component.scss'],
})
export class BankBranchCreateComponent {
  constructor(
    private _apiAdminBankBranch: ApiAdminBankBranch,
    private _apiClientAdminBank: ApiClientAdminBank,
    private _modalService: ModalService
  ) {
    this.load(this._modalService.openData?.bankId);
  }

  bankBranchDto: BankBranchDto;

  async load(bankId: string) {
    const bank = bankId ? await this._apiClientAdminBank.getById(bankId) : null;
    this.bankBranchDto = new BankBranchDto();
    if (bank) {
      this.bankBranchDto.bankId = bank.id;
      this.bankBranchDto.bank = bank;
    }
  }

  async save(bankBranchDto: BankBranchDto) {
    bankBranchDto.id = GUID_EMPTY;
    const branch = await this._apiAdminBankBranch.create(bankBranchDto);
    this._modalService.closeAsSuccess('La sucursal se creó con éxito', branch);
  }

  close() {
    this._modalService.closeAsSuccess('');
  }
}
