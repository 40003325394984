// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { PersonBankAccountDto } from "../dtos/PersonBankAccountDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { PersonBankAccountFiltersDto } from "../dtos/PersonBankAccountFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";

@Injectable({providedIn: 'root'})
export class ApiAdminPersonBankAccount {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  create(
    personBankAccountDto: PersonBankAccountDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonBankAccountDto> {
    return this._httpApiClient.post("/admin/person-bank-account", personBankAccountDto, httpApiRequestOptions);
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonBankAccountDto> {
    return this._httpApiClient.get("/admin/person-bank-account/" + id, httpApiRequestOptions);
  }

  getPaged(
    filters: PagedSearchDto<PersonBankAccountFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<PersonBankAccountDto>> {
    return this._httpApiClient.post("/admin/person-bank-account/paged", filters, httpApiRequestOptions);
  }

  reactivate(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonBankAccountDto> {
    return this._httpApiClient.put("/admin/person-bank-account/reactivate/" + id, null, httpApiRequestOptions);
  }

  delete(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PersonBankAccountDto> {
    return this._httpApiClient.delete("/admin/person-bank-account/" + id, httpApiRequestOptions);
  }

}
