import { Component, forwardRef } from '@angular/core';
import { IGridFilter } from '../../interface/grid-filter';
import { GridFilterBase } from '../grid-filter-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  standalone: false,
  selector: 'mf-grid-filter-select-multiple',
  templateUrl: './grid-filter-select-multiple.component.html',
  styleUrls: ['./grid-filter-select-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridFilterSelectMultipleComponent),
      multi: true,
    },
  ],
})
export class GridFilterSelectMultipleComponent extends GridFilterBase<IGridFilter, number[] | null> {}
