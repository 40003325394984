// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { AppUserDto } from "../dtos/AppUserDto";
import { AppUserCreateDto } from "../dtos/AppUserCreateDto";
import { PagedResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedResultDto";
import { AppUserFiltersDto } from "../dtos/AppUserFiltersDto";
import { PagedSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto";
import { AuthRoleDto } from "../dtos/AuthRoleDto";
import { AuthUserRolesUpdateDto } from "../dtos/AuthUserRolesUpdateDto";

@Injectable({providedIn: 'root'})
export class ApiAdminAppUser {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  getById(
    id: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AppUserDto> {
    return this._httpApiClient.get("/admin/app-user/" + id, httpApiRequestOptions);
  }

  create(
    appUserCreateDto: AppUserCreateDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AppUserDto> {
    return this._httpApiClient.post("/admin/app-user", appUserCreateDto, httpApiRequestOptions);
  }

  edit(
    applicationAppUserDto: AppUserDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AppUserDto> {
    return this._httpApiClient.put("/admin/app-user", applicationAppUserDto, httpApiRequestOptions);
  }

  getPaged(
    searchDto: PagedSearchDto<AppUserFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<PagedResultDto<AppUserDto>> {
    return this._httpApiClient.post("/admin/app-user/paged", searchDto, httpApiRequestOptions);
  }

  rolesAll(httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AuthRoleDto[]> {
    return this._httpApiClient.get("/admin/app-user/roles-all", httpApiRequestOptions);
  }

  rolesByName(
    roleName: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AuthRoleDto> {
    return this._httpApiClient.get("/admin/app-user/roles-by-name?" + 
      (roleName ? "&roleName=" + roleName : ""), httpApiRequestOptions);
  }

  rolesUser(
    authUserId: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<AuthRoleDto[]> {
    return this._httpApiClient.get("/admin/app-user/roles-user?" + 
      (authUserId ? "&authUserId=" + authUserId : ""), httpApiRequestOptions);
  }

  rolesAdd(
    authUserId: string,
    
    role: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/app-user/rol-add/" + authUserId + "/" + role, null, httpApiRequestOptions);
  }

  rolesRemove(
    authUserId: string,
    
    role: string,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/app-user/rol-remove/" + authUserId + "/" + role, null, httpApiRequestOptions);
  }

  rolesUpdate(
    rolesUpdateDto: AuthUserRolesUpdateDto,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<void> {
    return this._httpApiClient.put("/admin/app-user/rol-update", rolesUpdateDto, httpApiRequestOptions);
  }

}
