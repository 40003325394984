<mf-page title="Cuentas de pasarela">
  <mf-row>
    <mf-grid
      #grid
      [actionButtons]="actionButtons"
      [apiEndpoint]="apiEndpoint"
      [columns]="columns"
      [menuItems]="menuItems" />
  </mf-row>
</mf-page>
