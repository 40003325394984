import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/gird-column';
import { LogEventDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventDto';
import { LogEventFiltersDto } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/dtos/LogEventFiltersDto';
import { enumName_logging } from '../../../../../../../libs/utils/ng-shared-components/src/lib/logging/enums/_enumName.logging';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { moduleName } from '../../../../../../../libs/utils/ts-shared/src/lib/module-names';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { LoggingConfigComponent } from './logging-config.component';
import { LoggingDetailsComponent } from './logging-details.component';
import { IHttpApiRequestOptions } from '../../IHttpApiRequestOptions';
import { IApiAdminLoggingViewer } from '../api/IApiAdminLoggingViewer';

@Component({
  selector: 'mf-logging-grid',
  templateUrl: './logging-grid.component.html',
  styleUrls: ['./logging-grid.component.scss'],
})
export class LoggingGridComponent {
  constructor(
    @Inject('IApiAdminLoggingViewer') private _apiAdminLoggingViewer: IApiAdminLoggingViewer,
    private _modalService: ModalService
  ) {}

  testForm = new FormGroup({ ef: new FormControl(false) });

  columns: IGridColumn<LogEventDto>[] = [
    {
      header: 'Fecha',
      field: nameof<LogEventDto>((p) => p.date),
      cellOptions: { type: CellValueType.Date, dateIncludeMilliseconds: true },
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.date),
        type: FilterType.Date,
      },
      orderByPrevent: true,
      width: 15,
    },
    {
      header: 'Nivel',
      field: nameof<LogEventDto>((p) => p.level),
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_logging.LoggingLevel,
        enumNameModule: moduleName.logging,
      },
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.levelList),
        type: FilterType.EnumList,
        enumName: enumName_logging.LoggingLevel,
        enumNameModule: moduleName.logging,
      },
      orderByPrevent: true,
      width: 8,
    },
    {
      header: 'Categoría',
      field: nameof<LogEventDto>((p) => p.categoryFullName),
      filter: {
        propertyName: nameof<LogEventFiltersDto>((p) => p.categoryFullName),
        type: FilterType.Text,
      },
      width: 10,
      orderByPrevent: true,
      render: (item: LogEventDto) =>
        '<div title="' +
        item.categoryFullName +
        '">' +
        item.categoryFullName.substring(item.categoryFullName.lastIndexOf('.') + 1) +
        '</div>',
    },
    {
      header: 'Path',
      field: nameof<LogEventDto>((p) => p.requestPath),
      orderByPrevent: true,
      width: 10,
    },
    {
      header: 'Mensaje',
      field: nameof<LogEventDto>((p) => p.message),
      orderByPrevent: true,
      width: 60,
    },
  ];

  menuItems = [
    {
      label: 'Ver detalles',
      icon: Icons.search,
      onClick: (logEventDto: LogEventDto) => {
        this._modalService.openRightModal(LoggingDetailsComponent, {
          openData: logEventDto,
          onCloseSuccess: async () => {},
        });
      },
    },
  ];

  apiEndpoint = (search: PagedSearchDto<LogEventFiltersDto>, httpApiRequestOptions: IHttpApiRequestOptions) =>
    this._apiAdminLoggingViewer.getPaged(search, httpApiRequestOptions);

  navigateToConfig() {
    this._modalService.openRightModal(LoggingConfigComponent, {});
  }
}
