import { Component, HostBinding, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'mf-column-display',
  templateUrl: './column-display.component.html',
  styleUrls: ['./column-display.component.scss'],
})
export class LayoutColumnDisplayComponent {
  @Input() md: number = 4;
  @HostBinding('style.text-align')
  @Input()
  align: 'left' | 'center' | 'right' = 'left';
  @Input()
  label: string = '';

  @HostBinding('class')
  get class(): string {
    return `col-md-${this.md}`;
  }
}
