<mf-page title="Correo enviado">
  @if (emailSent){
  <mf-column-display
    label="Para"
    [md]="3">
    {{ emailSent.to }}
  </mf-column-display>
  <mf-column-display
    label="Desde"
    [md]="3">
    {{ emailSent.from }}
  </mf-column-display>
  <mf-column-display
    label="Fecha"
    [md]="2">
    {{ emailSent.createdDate | dateUtc }}
  </mf-column-display>
  <mf-column-display
    label="Estado"
    [md]="2">
    {{ emailSent.status | translate : enumName.EmailSentStatus : moduleName.emails }}
  </mf-column-display>
  <mf-column-display
    label="Asunto"
    [md]="12">
    {{ emailSent.subject }}
  </mf-column-display>
  <mf-column-display
    label="Cuerpo"
    [md]="12">
    <iframe
      class="container-html"
      [srcdoc]="safeBodyHtml"></iframe>
  </mf-column-display>
  }
</mf-page>
