import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormBuilder } from '../../../../../../libs/utils/ng-shared-components/src/lib/validation/form-builder/custom-form-builder';
import { ValueListDto, ValueListDtoFormGroup } from '../../../api/dtos/ValueListDto';
import { enumName_mi_pspc } from '../../../api/enums/_enumName.mi_pspc';
import { enumName_mi_shared } from '../../../../../../libs/utils/ts-shared/src/lib/api/enums/_enumName.mi_shared';
import { moduleName } from '../../../../../../libs/utils/ts-shared/src/lib/module-names';

@Component({
  standalone: false,
  selector: 'mf-admin-value-list',
  templateUrl: './value-list.component.html',
  styleUrls: ['./value-list.component.scss'],
})
export class ValueListComponent {
  constructor(private _cfb: CustomFormBuilder) {}

  @Input()
  valueListDto: ValueListDto;
  @Output()
  save = new EventEmitter<ValueListDto>();
  @Output()
  close = new EventEmitter();
  enumNameMiShared = enumName_mi_shared;
  enumNameModule = moduleName.shared;
  enumNameMiPspc = enumName_mi_pspc;

  valueListForm = this._cfb.group<ValueListDtoFormGroup>({
    valueListType: [null, Validators.required, 'Tipo de lista'],
    name: ['', Validators.required, 'Texto'],
    description: ['', Validators.required, 'Descripción'],
    color: ['', Validators.required, 'Color'],
    country: [null, Validators.required, 'País'],
  });

  ngOnInit(): void {
    this.valueListForm.patchValue(this.valueListDto);
  }
}
