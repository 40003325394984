// File generated by PostBuildApiClientController
import { HttpApiClient } from "./HttpApiClient";
import { HttpApiRequestOptions } from "./HttpApiRequestOptions";
import { Injectable } from "@angular/core";
import { AppUserSimpleDto } from "../dtos/AppUserSimpleDto";
import { SuggestResultDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestResultDto";
import { AppUserFiltersDto } from "../dtos/AppUserFiltersDto";
import { SuggestSearchDto } from "../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto";

@Injectable({providedIn: 'root'})
export class ApiClientAdminAppUser {
  constructor(private _httpApiClient: HttpApiClient) {
    // Empty constructor
  }

  suggest(
    search: SuggestSearchDto<AppUserFiltersDto>,
    httpApiRequestOptions?:HttpApiRequestOptions
  ): Promise<SuggestResultDto<AppUserSimpleDto>> {
    return this._httpApiClient.post("/client-admin/app-user/suggest", search, httpApiRequestOptions);
  }

}
