import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiAdminBankMovement } from '../../../../api/endpoints/ApiAdminBankMovement';
import { GridComponent, IGridActionButton } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid.component';
import { BankMovementFiltersDto } from '../../../../api/dtos/BankMovementFiltersDto';
import { CellValueType, IGridColumn } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/grid-column';
import { BankMovementDto } from '../../../../api/dtos/BankMovementDto';
import { nameof } from 'ts-simple-nameof';
import { enumName_mi_pspc } from '../../../../api/enums/_enumName.mi_pspc';
import { HttpApiRequestOptions } from '../../../../api/endpoints/HttpApiRequestOptions';
import { GridMenuItem } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/grid-menu/grid-menu-item';
import { Icons } from '../../../../../../../libs/utils/ng-shared-components/src/lib/icon/icons';
import { PagedSearchDto } from '../../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/PagedSearchDto';
import { TrackingPopupComponent } from '../../../components/track-grid/tracking-popup.component';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { FilterType } from '../../../../../../../libs/utils/ng-shared-components/src/lib/grid/interface/filter-type';
import { EntityType } from '../../../../api/enums/EntityType';
import { FilterEntityType } from '../../../view-models/FilterEntityType';

@Component({
  standalone: false,
  selector: 'mf-bank-movement-grid',
  templateUrl: './bank-movement-grid.component.html',
  styleUrls: ['./bank-movement-grid.component.scss'],
})
export class BankMovementGridComponent implements OnInit {
  constructor(public _apiAdminBankMovement: ApiAdminBankMovement, private _modalService: ModalService) {}

  @ViewChild('grid')
  grid: GridComponent;

  filters: BankMovementFiltersDto;

  ngOnInit(): void {
    this.filters = new BankMovementFiltersDto();
  }
  columns: IGridColumn<BankMovementDto>[] = [
    {
      header: 'Cuenta',
      field: nameof<BankMovementDto>((bm) => bm.gatewayBankAccount.accountNumber),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.gatewayBankAccount),
        type: FilterType.Entity,
        entityType: FilterEntityType.GatewayBankAccount,
      },
      width: 20,
    },
    {
      header: 'Fecha',
      field: nameof<BankMovementDto>((bm) => bm.createdDate),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.createdDate),
        type: FilterType.Date,
      },
      width: 20,
      cellOptions: { type: CellValueType.Date, dateIncludeTime: true },
    },
    {
      field: nameof<BankMovementDto>((bm) => bm.movementType),
      header: 'Tipo',
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.movementTypeList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.MovementType,
      },
      width: 5,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
    {
      header: 'DaMap Id',
      field: nameof<BankMovementDto>((bm) => bm.conciliationRowId),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.conciliationRowId),
        type: FilterType.Text,
      },
      width: 10,
    },
    {
      header: 'DaMap Conciliación',
      field: nameof<BankMovementDto>((bm) => bm.conciliationId),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.conciliationId),
        type: FilterType.Text,
      },
      width: 15,
    },
    {
      field: nameof<BankMovementDto>((bm) => bm.reference),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.reference),
        type: FilterType.Text,
      },
      header: 'Referencia',
      width: 50,
    },
    {
      header: 'Moneda',
      field: nameof<BankMovementDto>((bm) => bm.currency),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.currencyList),
        type: FilterType.EnumList,
        enumName: enumName_mi_pspc.Currency,
      },
      width: 10,
      cellOptions: {
        type: CellValueType.Enum,
        enumName: enumName_mi_pspc.Currency,
      },
    },
    {
      header: 'Monto',
      field: nameof<BankMovementDto>((bm) => bm.amount),
      filter: {
        propertyName: nameof<BankMovementFiltersDto>((f) => f.amount),
        type: FilterType.NumericRange,
      },
      width: 10,
      cellOptions: {
        type: CellValueType.Numeric,
        enumName: enumName_mi_pspc.MovementType,
      },
    },
  ];

  menuItems: GridMenuItem[] = [
    {
      label: 'Ver tracking',
      icon: Icons.list,
      onClick: (bankMovementDto: BankMovementDto) => {
        this._modalService.openLargeModal(TrackingPopupComponent, {
          openData: bankMovementDto.id,
          onCloseSuccess: async () => {},
        });
      },
    },
  ];

  gridActionButtons: IGridActionButton[] = [
    {
      text: 'Actualizar movimientos',
      onClick: () => this.getBankMovements(),
    },
  ];

  apiEndpoint = (search: PagedSearchDto<BankMovementFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiAdminBankMovement.getPaged(search, httpApiRequestOptions);

  async getBankMovements() {
    await this._apiAdminBankMovement.getFromDaMap();
    this.grid.reloadPage();
  }
}
