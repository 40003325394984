// File generated by MiFinanzas.Utils.Net.MiFinanzasPostBuildEvents
import { FiltersDto , FiltersDtoFormGroup } from '../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/FiltersDto';
import { PersonDto } from '../../../../../libs/utils/ts-shared/src/lib/api/dtos/PersonDto';
import { BankAccountDto } from './BankAccountDto';

export class PersonBankAccountFiltersDto extends FiltersDto {
  person: PersonDto;
  bankAccount: BankAccountDto;
}

export interface PersonBankAccountFiltersDtoFormGroup extends FiltersDtoFormGroup {
  person?: any;
  bankAccount?: any;
}
