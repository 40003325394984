<mf-page title="Nueva transferencia">
  <mf-column>
    <mf-autocomplete-person
      label="Persona"
      [(ngModel)]="person"
      (ngModelChange)="onPersonSelected($event)" />
  </mf-column>
  @if(isAdmin) {
  <mf-column>
    <mf-autocomplete-client
      label="Cliente"
      [(ngModel)]="client"
      (ngModelChange)="onClientSelected($event)" />
  </mf-column>
  }
  <mf-column>
    <mf-field-select
      label="Moneda"
      [(ngModel)]="currency"
      [enumName]="enumName.CurrencyName"
      [availableValues]="currencyList"
      (ngModelChange)="onCurrencySelected($event)" />
  </mf-column>

  @if(person != null && currency != null){
  <mf-collect-order-voucher
    [transferVoucherForCollectOrderDto]="transferVoucherForCollectOrderDto"
    [personId]="person.id"
    (save)="submit($event)"
    (close)="closeModal()" />
  }
</mf-page>
