<mf-page title="Detalles de incidencia">
  @if (issueDto) {
  <mf-column-display label="Id">{{ issueDto.id }}</mf-column-display>
  <mf-column-display label="Estado">{{ issueDto.status | translate : enumName.IssueStatus }}</mf-column-display>
  <mf-column-display label="Nivel">{{ issueDto.level | translate : enumName.IssueLevel }}</mf-column-display>
  <mf-column-display label="Tipo">{{ issueDto.type | translate : enumName.IssueType }}</mf-column-display>
  <mf-column-display label="Entidad relacionada">{{ issueDto.entityType! | translate : enumName.EntityType }}</mf-column-display>
  <mf-column-display label="Fecha de resolución">{{ issueDto.resolutionDate || '-' }}</mf-column-display>

  <mf-column-display
    [md]="12"
    label="Detalle"
    >{{ issueDto.displayText }}</mf-column-display
  >
  <mf-track-grid [id]="issueDto.id" />
  }
</mf-page>
