<mf-form
  [formGroup]="userForm"
  [entity]="appUserCreateDto"
  [closeButtonShow]="true"
  (closeButtonClick)="close.emit()"
  (save)="save.emit($event)">
  <mf-column>
    <mf-autocomplete-person
      [personType]="personType.NaturalPerson"
      formControlName="naturalPerson"
      (itemIdChanged)="userForm.patchValue({ naturalPersonId: $event })" />
  </mf-column>
  <mf-column>
    <mf-field-password formControlName="password" />
  </mf-column>
  <mf-column>
    <mf-field-password formControlName="passwordConfirm" />
  </mf-column>
</mf-form>
