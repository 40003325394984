import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { IRoleProvider } from '../../../../ng-shared-components/src/lib/left-menu/IRoleProvider';
import { MFAuthService } from './mf-auth.service';
import { LoginResponse } from '../dto/LoginResponse';
import { LoginRequest } from '../dto/LoginRequest';
import { IApiUrlService } from '../../../../ng-shared-components/src/lib/services/api-url.service';

@Injectable()
export class LoginService implements IRoleProvider {
  constructor(private _mfAuthService: MFAuthService, @Inject('IApiUrlService') apiUrlService: IApiUrlService) {
    this._jwtToken = localStorage.getItem('mf_auth_jwt_token') || '';
    if (this._jwtToken) {
      this.loadLoggedUser();
    }
    this._mfAuthService.init({ baseApiUrl: apiUrlService.apiUrl });
  }

  private loadLoggedUser() {
    let decodedToken = jwt_decode(this._jwtToken) as any;
    this.loggedUsername = decodedToken.email;
    this.loggedUserId = decodedToken.jti;
    this.loggedUserRoles = decodedToken.role;
    this.loggedClientName = decodedToken.mi_client_name;
    this.loggedClientId = decodedToken.mi_client_id;
    this.autoLogout();
  }

  private isTokenExpired(token: string): boolean {
    let decodedToken = jwt_decode(token) as any;
    let expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    return expirationDate.valueOf() < new Date().valueOf();
  }

  private _jwtToken: string;
  errors = new ReplaySubject<LoginResponse>();

  loggedUserId: string;
  loggedUsername: string;
  loggedUserRoles: string[] = [];
  loggedClientName: string;
  loggedClientId: string;

  get jwtToken(): string {
    return this._jwtToken;
  }

  login(username: string, password: string, onSuccess: () => void, onError: (errorMessage: string) => void) {
    this._mfAuthService.login({ username: username, password: password } as LoginRequest).subscribe({
      next: (loginResponse: LoginResponse) => {
        this._jwtToken = loginResponse.token;
        localStorage.setItem('mf_auth_jwt_token', loginResponse.token);
        this.loadLoggedUser();
        onSuccess();
      },
      error: (error) => {
        onError(error.error ?? error.message);
      },
    });
  }

  autoLogout() {
    if (this.isTokenExpired(this._jwtToken)) {
      this.logout();
    }
  }

  logout() {
    localStorage.removeItem('mf_auth_jwt_token');
    window.location.href = '/';
  }

  userIsInRole(roles: string[]): boolean {
    if (!roles || roles.length === 0 || roles === undefined) {
      return true;
    }

    if (!this.loggedUserRoles || this.loggedUserRoles.length === 0 || this.loggedUserRoles === undefined) {
      return false;
    }

    return roles.some((role) => this.loggedUserRoles.includes(role));
  }

  loginGoogle(returnUrl?: string) {
    this._mfAuthService.loginGoogle(returnUrl);
  }

  loginWithToken(token: string) {
    if (!token) {
      return;
    }
    this._jwtToken = token;
    localStorage.setItem('mf_auth_jwt_token', token);
    this.loadLoggedUser();
  }
}
