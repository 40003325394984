import { Injectable } from '@angular/core';
import { SelectOption } from '../fields/field-select/field-select.component';

class moduleEnumTranslation {
  [key: string]: { [key: string]: any };
}

@Injectable()
export class EnumTranslateProviderService {
  private _moduleEnumTranslations: { [key: string]: moduleEnumTranslation } = {};
  private _defaultModule: string;

  initModule(moduleName: string, enumTranslations: { [key: string]: any }, isDefaultModule?: true) {
    this._moduleEnumTranslations[moduleName] = enumTranslations;

    if (isDefaultModule) {
      this._defaultModule = moduleName;
    }
  }

  private getModuleName(module?: string): string {
    if (!this._moduleEnumTranslations) {
      throw new Error('EnumTranslateProviderService not initialized');
    }

    if (!module && !this._defaultModule) {
      throw new Error('Default module not defined');
    }

    if (!module) {
      module = this._defaultModule;
    }

    return module;
  }

  translateEnum(enumName: string, enumValue: number, moduleName?: string): string {
    if (!this._defaultModule) {
      return 'TestTranslate';
    }

    moduleName = this.getModuleName(moduleName);

    if (this._moduleEnumTranslations[moduleName] === undefined) {
      throw new Error(`Module ${moduleName} not loaded`);
    }

    if (this._moduleEnumTranslations[moduleName][enumName] === undefined) {
      throw Error(`Enum ${enumName} not found in module ${moduleName}`);
    }

    return this._moduleEnumTranslations[moduleName][enumName][enumValue];
  }

  getEnumOptions(enumName: string, moduleName?: string): SelectOption[] {
    if (!this._defaultModule) {
      return [];
    }

    moduleName = this.getModuleName(moduleName);
    const options: SelectOption[] = [];
    if (this._moduleEnumTranslations[moduleName][enumName] === undefined) {
      throw new Error(`Enum translations not found for type:  ${enumName} in module ${moduleName}`);
    }
    var keys = Object.keys(this._moduleEnumTranslations[moduleName][enumName]);
    keys.forEach((key) => {
      options.push({
        value: parseInt(key),
        description: this._moduleEnumTranslations[moduleName ?? ''][enumName][key],
      });
    });
    return options;
  }
}
