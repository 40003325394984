<mf-page title="Operaciones">
  <mf-row>
    <mf-grid
      #grid
      [apiEndpoint]="apiEndpoint"
      [actionButtons]="gridActionButtons"
      [columns]="columns"
      [menuItems]="menuItems"
      [initialFilters]="filters" />
  </mf-row>
</mf-page>
