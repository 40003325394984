import { Component } from '@angular/core';
import { AccreditationDto } from '../../../api/dtos/AccreditationDto';
import { ApiAdminAccreditation } from '../../../api/endpoints/ApiAdminAccreditation';
import { ModalService } from '../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';

@Component({
  standalone: false,
  selector: 'mf-accreditation-details',
  templateUrl: './accreditation-details.component.html',
  styleUrls: ['./accreditation-details.component.scss'],
})
export class AccreditationDetailsComponent {
  constructor(private _apiAdminAccreditation: ApiAdminAccreditation, modalService: ModalService) {
    this.load(modalService.openData);
  }

  accreditation: AccreditationDto;

  async load(id: string) {
    this.accreditation = await this._apiAdminAccreditation.getById(id);
  }
}
