import { Component, ElementRef, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FieldMatBaseComponent } from '../base/field-mat-base.component';

@Component({
  standalone: false,
  selector: 'mf-field-chip',
  templateUrl: './field-chip.component.html',
  styleUrls: ['./field-chip.component.scss'],
})
export class FieldChipComponent extends FieldMatBaseComponent<string[]> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef) {
    super(ngControl, elRef);
  }
  @Input()
  elementList: string[] = [];
  @Output()
  selectedItemChange = new EventEmitter<string[] | null>();

  override get controlType(): string {
    return 'field-chip';
  }

  onSelectedItemChange() {
    this.selectedItemChange.emit(this.value);
  }
}
