import { Component, Input } from '@angular/core';
import { GridFilterService } from '../service/grid-filter.service';

@Component({
  standalone: false,
  selector: 'mf-grid-filter-tags',
  templateUrl: './grid-filter-tags.component.html',
  styleUrls: ['./grid-filter-tags.component.scss'],
})
export class GridFilterTagsComponent {
  constructor() {}

  @Input()
  gridFilterService: GridFilterService;
}
