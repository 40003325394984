import { Component, ElementRef, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { nameof } from 'ts-simple-nameof';
import { AutoCompleteBaseEntityComponent } from '../../../../../../libs/utils/ng-shared-components/src/lib/fields/auto-complete/auto-complete-base-entity.component';
import { SuggestSearchDto } from '../../../../../../libs/utils/ts-shared/src/lib/api/searching/dtos/SuggestSearchDto';
import { AppUserDto } from '../../../api/dtos/AppUserDto';
import { AppUserFiltersDto } from '../../../api/dtos/AppUserFiltersDto';
import { AppUserSimpleDto } from '../../../api/dtos/AppUserSimpleDto';
import { ApiClientAdminAppUser } from '../../../api/endpoints/ApiClientAdminAppUser';
import { HttpApiRequestOptions } from '../../../api/endpoints/HttpApiRequestOptions';

@Component({
  selector: 'mf-autocomplete-user',
  templateUrl: './autocomplete-user.component.html',
  styleUrls: ['./autocomplete-user.component.scss'],
})
export class AutocompleteUserComponent extends AutoCompleteBaseEntityComponent<AppUserSimpleDto | null, AppUserFiltersDto> {
  constructor(@Optional() @Self() ngControl: NgControl, elRef: ElementRef, private _apiClientAdminAppUser: ApiClientAdminAppUser) {
    super(ngControl, elRef);
  }

  override defaultOrderBy = nameof<AppUserDto>((p) => p.naturalPerson.email);

  override endpointSuggest = (search: SuggestSearchDto<AppUserFiltersDto>, httpApiRequestOptions: HttpApiRequestOptions) =>
    this._apiClientAdminAppUser.suggest(search, httpApiRequestOptions);
}
