import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { INotificationTrayService } from '../../../../../libs/utils/ng-shared-components/src/lib/notification-tray/INotificationTray';
import { NavigationService } from '../../../../../libs/utils/ng-shared-components/src/lib/services/navigation.service';
import { PersonType } from '../../../../../libs/utils/ts-shared/src/lib/api/enums/PersonType';
import { NotificationTrayDto } from '../../api/dtos/NotificationTrayDto';
import { ApiClientAdminNotification } from '../../api/endpoints/ApiClientAdminNotification';
import { ApiClientAdminPerson } from '../../api/endpoints/ApiClientAdminPerson';
import { IssueType } from '../../api/enums/IssueType';
import { AppRoute } from '../app.routes.path';
import { EntityType } from '../../api/enums/EntityType';

@Injectable()
export class NotificationTrayService implements INotificationTrayService {
  constructor(
    private _apiClientAdminNotification: ApiClientAdminNotification,
    private _apiClientAdminPerson: ApiClientAdminPerson,
    private _navigationService: NavigationService
  ) {
    this.loadNotifications();
    setInterval(() => {
      this.loadNotifications();
    }, 300000);
  }

  notificationTray = new ReplaySubject<NotificationTrayDto>();

  async loadNotifications() {
    this.notificationTray.next(await this._apiClientAdminNotification.getCurrent(true, { preventSpinner: true }));
  }

  navigateToNotificationPage(): void {
    this._navigationService.navigateNewTab(AppRoute.notifications);
  }

  async setAllAsRead() {
    await this._apiClientAdminNotification.setAllAsRead({ preventSpinner: true });
    this.loadNotifications();
  }

  async setAsRead(notificationId: string) {
    await this._apiClientAdminNotification.setAsRead(notificationId, { preventSpinner: true });
    this.loadNotifications();
  }

  async inactivate(notificationId: string) {
    await this._apiClientAdminNotification.inactivate(notificationId, { preventSpinner: true });
    this.loadNotifications();
  }

  async downloadFile(notificationId: string) {
    let fileUrl = await this._apiClientAdminNotification.downloadNotificationFile(notificationId);
    this._navigationService.navigateNewTab(fileUrl);
    this.loadNotifications();
  }

  async navigateToRelatedEntityPage(notificationId: string) {
    let relatedIssue = await this._apiClientAdminNotification.getRelatedIssue(notificationId);

    if (relatedIssue?.entityId == null) {
      return;
    }

    switch (relatedIssue.type) {
      case IssueType.InvalidBankAccount:
        this._navigationService.navigateNewTab(AppRoute.bankAccounts, ['id=' + relatedIssue.entityId]);
        break;
      case IssueType.InconsistentPerson:
        const person = await this._apiClientAdminPerson.getById(relatedIssue.entityId);
        this._navigationService.navigateNewTab(
          person.type === PersonType.NaturalPerson ? AppRoute.persons.naturalPersons.list : AppRoute.persons.companies.list,
          ['id=' + relatedIssue.entityId]
        );
        break;
      default:
        break;
    }

    this.setAsRead(notificationId);
  }

  navigateToAccreditations(accreditationId: string): void {
    const queryParams = { openFileUpload: accreditationId };
    this._navigationService.navigate(AppRoute.accreditations, undefined, queryParams);
  }
}
