<mf-page title="Detalles del cliente">
  @if (clientDto) {
  <mf-column-display label="Nombre">{{ clientDto.name }}</mf-column-display>
  <mf-column-display label="Empresa">{{ clientDto.company.name }}</mf-column-display>
  <mf-column-display label="RUT">{{ clientDto.company.idDocument.documentNumber }}</mf-column-display>
  <mf-column-display label="Email para notificaciones">{{ clientDto.notificationEmail }}</mf-column-display>
  <mf-column-display label="WebHook">{{ clientDto.endpointUrl }}</mf-column-display>
  <mf-column-display label="ClientId">{{ clientDto.id }}</mf-column-display>
  <mf-column-display label="ApiKey">{{ clientDto.apiKey }}</mf-column-display>
  }
</mf-page>
