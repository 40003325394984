import { Component } from '@angular/core';
import { BankDto } from '../../../../api/dtos/BankDto';
import { ApiAdminBank } from '../../../../api/endpoints/ApiAdminBank';
import { ModalService } from '../../../../../../../libs/utils/ng-shared-components/src/lib/services/modal.service';
import { ApiClientAdminBank } from '../../../../api/endpoints/ApiClientAdminBank';

@Component({
  standalone: false,
  selector: 'mf-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.scss'],
})
export class BankEditComponent {
  constructor(private _apiAdminBank: ApiAdminBank, private _apiClientAdminBank: ApiClientAdminBank, private _modalService: ModalService) {
    this.load();
  }

  bankDto: BankDto;

  async load() {
    this.bankDto = await this._apiClientAdminBank.getById(this._modalService.openData);
  }

  async save(bankDto: BankDto) {
    await this._apiAdminBank.edit(bankDto);
    this._modalService.closeAsSuccess('Banco editado correctamente');
  }
}
