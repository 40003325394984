import { Component, Inject } from '@angular/core';
import { IApiTemplatePdf } from '../../api/IApiTemplatePdf';
import { nameof } from 'ts-simple-nameof';
import { TemplatePdfDto } from '../../dtos/TemplatePdfDto';
import { GUID_EMPTY } from '../../../../../../ts-shared/src/lib/utils-guid';
import { TemplateGroup } from '../../enums/TemplateGroup';
import { PaperSize } from '../../enums/PaperSize';
import { PaperOrientation } from '../../enums/PaperOrientation';
import { ModalService } from '../../../services/modal.service';
import { TemplateDto } from '../../dtos/TemplateDto';

@Component({
  standalone: false,
  selector: 'mf-template-pdf-create',
  templateUrl: './template-pdf-create.component.html',
  styleUrls: ['./template-pdf-create.component.scss'],
})
export class TemplatePdfCreateComponent {
  constructor(@Inject('IApiTemplatePdf') private _apiPdfTemplate: IApiTemplatePdf, private _modalService: ModalService) {
    this.load();
  }

  templatePdfDto: TemplatePdfDto;

  async load() {
    this.templatePdfDto = new TemplatePdfDto();
    this.templatePdfDto.id = GUID_EMPTY;
    this.templatePdfDto.group = TemplateGroup.Pdf;
    this.templatePdfDto.paperSize = PaperSize.A4;
    this.templatePdfDto.orientation = PaperOrientation.Portrait;
  }

  async save(templateDto: TemplateDto) {
    await this._apiPdfTemplate.create(templateDto as TemplatePdfDto);
    this._modalService.closeAsSuccess('La plantilla de PDF se creó con éxito');
  }

  close() {
    this._modalService.closeAsCancel();
  }
}
